import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress,
  Alert,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import moment from 'moment';
import FilterListIcon from '@mui/icons-material/FilterList';
import baseUrl from '../config';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.02)',
  },
}));

const StudentHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('past'); // default filter

  useEffect(() => {
    const fetchClassHistory = async () => {
      try {
        const response = await axios.get(baseUrl+'api/students/class-history', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setHistoryData(response.data);
      } catch (err) {
        setError(err?.response?.data?.message || 'Error fetching class history');
      } finally {
        setLoading(false);
      }
    };

    fetchClassHistory();
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const filteredData = historyData.filter((item) => {
    if (filter === 'upcoming') {
      return new Date(item.date) > new Date();
    }
    return new Date(item.date) <= new Date();
  });

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
    <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      backgroundColor: '#bac2ff',
    }}
  >   
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <IconButton aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        <Typography variant="h6" fontWeight="bold"sx={{ flexGrow: 1 }}>
          Class History
        </Typography>
        <IconButton onClick={() => handleFilterChange(filter === 'upcoming' ? 'past' : 'upcoming')}>
          <FilterListIcon />
        </IconButton>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {filter === 'upcoming' ? 'Upcoming Classes' : 'Past Classes'}
        </Typography>
      </Box>
      <Typography variant="subtitle1" color="textSecondary" mb={3}>
Check your previous classes        </Typography>
      <Grid container spacing={3}>
        {filteredData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardHeader
                title={item.courseTitle}
                subheader={`Date: ${moment(item.date).format('MMMM D, YYYY')} | Time: ${item.time}`}
              />
              <Divider />
              <CardContent>
                <Typography variant="body2">{item.courseDescription}</Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Teacher: {item.teacherName || 'Unknown Teacher'}
                </Typography>
                {item.joinUrl && (
                  <Box sx={{ mt: 2 }}>
                    <a href={item.joinUrl} target="_blank" rel="noopener noreferrer">
                      Join Class
                    </a>
                  </Box>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Box>
  );
};

export default StudentHistory;
