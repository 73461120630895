import React from "react";
import { Box,} from '@mui/material';
import SidebarRight from "../Components/SideScreen";
import Assessments from "../Components/Assessments";
// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
const AssessmentsPage = () => {

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >

{/* Middle Content */}

  {/* Grey Strip for CountryTimeSelector */}

<Assessments/>   


  {/* Sidebar for larger screens (3rd screen) */}
  <SidebarRight/>
  </Box>

  );
};

export default AssessmentsPage;

