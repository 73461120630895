import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, Menu, MenuItem, Button, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import baseUrl from '../config';
import axios from 'axios';

const UserProfileCard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [grade, setGrade] = useState('');
  const [coursesData, setCoursesData] = useState([]);
  const [student, setStudent] = useState({ name: '', id: '', avatar: '', grade: '' });

  // Fetch functions remain the same
  const fetchUpcomingCourses = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setCoursesData(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.name || 'User');
      setGrade(user.grade);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(baseUrl + 'api/students/profile', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        setStudent({
          name: data.name,
          id: data.uniqueId,
          avatar: data.avatar,
          grade: data.grade
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    fetchUpcomingCourses();
    fetchProfile();
    fetchUserDetails();
  }, []);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleClose();
    navigate("/login");
  };
  const handleEditProfile = () => {
    navigate("/profile");
    handleClose();
  };
  const navigateToTeacherProfile = (teacherId) => {
    navigate(`/teacher/profile/${teacherId}`);
  };

  return (
    <Card sx={{
      width: '100%',
      mx: 'auto',
      borderRadius: 3,
      boxShadow: { xs: 3, md: 6 },
      backgroundColor: "#1e293b",
      overflow: 'hidden',
      my: 2,



      maxWidth: 500,
      mx: 'auto',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': { transform: 'scale(1.05)' },
    }}>
    
      <CardContent sx={{
        color: "#fff",
        p: { xs: 2,  },
        background: 'linear-gradient(135deg, #1e293b 0%, #2d3e5b 100%)',
      }}>
        
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'center', md: 'flex-start' },
          gap: 2,
        }}>
          <Avatar 
            src={`${baseUrl}${student.avatar}`} 
            sx={{ 
              width: { xs: 70, md: 80 }, 
              height: { xs: 70, md: 80 }, 
              border: '3px solid #fff',
              mb: { xs: 1, md: 0 }
            }} 
          />
          <Box sx={{ 
            flex: 1, 
            textAlign: { xs: 'center', md: 'left' },
            width: '100%'
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 'bold', 
                fontSize: { xs: '1.1rem', md: '1.3rem' },
                letterSpacing: 1,
                textTransform: 'uppercase',
                mb: 0.5
              }}
            >
              {student.name}
            </Typography>
           
            <Typography 
              variant="body2" 
              sx={{ 
                opacity: 0.9, 
                fontSize: '0.95rem', 
                color: '#B0BEC5' 
              }}
            >
              Grade: {grade} | ID: {student.id}
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 1,
            alignItems: { xs: 'center', md: 'flex-end' }
          }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Message">
                <IconButton color="inherit">
                  <MessageIcon sx={{ color: '#F8F8F8', fontSize: { xs: 20, md: 22 } }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rate your teacher">
                <IconButton 
                  color="inherit" 
                  onClick={() => navigateToTeacherProfile(coursesData[0]?.enrollmentTeacherId)}
                >
                  <StarIcon sx={{ color: '#F8F8F8', fontSize: { xs: 20, md: 22 } }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Settings">
                <IconButton color="inherit" onClick={handleClick}>
                  <SettingsIcon sx={{ color: '#F8F8F8', fontSize: { xs: 20, md: 22 } }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Button 
              onClick={() => navigateToTeacherProfile(coursesData[0]?.enrollmentTeacherId)}
              sx={{
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: { xs: '0.8rem', md: '0.9rem' },
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': { 
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  transform: 'scale(1.03)'
                },
                px: 2,
                py: 0.5,
                borderRadius: 1,
                transition: 'all 0.3s'
              }}
            >
              Teacher: {coursesData[0]?.teacherName}
            </Button>
          </Box>
          
        </Box>
      
      </CardContent>
      <Typography 
              // variant="h3" 
              sx={{ 
                opacity: 0.9, 
                fontSize: { xs: '0.9rem', md: '1rem' },
                color: '#ffff',
                // mb: 0.5,
                mt: 1,
                ml: 2,
                fontWeight: 'bold',
                fontFamily: 'Poppins',
                // letterSpacing: 1,
                  textTransform: 'uppercase',
                // background: 'linear-gradient(135deg, #1e293b 0%, #2d3e5b 100%)',
                
                
              }}
            >
              {coursesData[0]?.course?.title}
            </Typography>
      <CardContent sx={{ 
        backgroundColor: "#F8F8F8", 
        p: { xs: 2, md: 3 },
      }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr 1fr', md: '1fr 1fr 1fr' },
          gap: 1,
          mb: 2,
          // background: '#fff',
          borderRadius: 2,
          // p: 2,
          // boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
          transition: 'transform 0.3s',
          '&:hover': { transform: 'scale(1.02)' }
        }}>
          {[
            { label: 'Total Classes', value: coursesData[0]?.bookedClasses || 0, color: '#1976d2' },
            { label: 'Completed', value: coursesData[0]?.completedClasses || 0, color: coursesData[0]?.completedClasses > 0 ? '#388e3c' : '#d32f2f' },
            { label: 'Lost Classes', value: 0, color: '#050a30' }
          ].map((item, index) => (
            <Box key={index} sx={{ textAlign: 'center' }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: item.color, 
                  fontWeight: 'bold',
                  fontSize: { xs: '1.2rem', md: '1.5rem' }
                }}
              >
                {item.value}
              </Typography>
              <Typography 
                variant="caption" 
                color="textSecondary"
                sx={{ fontSize: { xs: '0.75rem', md: '0.85rem' } }}
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={handleEditProfile}
          sx={{
            borderRadius: 2,
            background: 'linear-gradient(135deg, #7776D2 30%, #3365C0 90%)',
            color: '#fff',
            fontWeight: 'bold',
            textTransform: 'none',
            py: 1,
            fontSize: { xs: '0.9rem', md: '1rem' },
            '&:hover': {
              background: 'linear-gradient(135deg, #6665C0 30%, #2255B0 90%)',
              boxShadow: 6,
              transform: 'translateY(-2px)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          Edit Profile
        </Button>
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxShadow: 5,
            borderRadius: 3,
            minWidth: 120,
          },
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Card>
  );
};

export default UserProfileCard;