import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  Typography,
  Box,
  IconButton,
  Snackbar,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { PDFDocument, rgb } from 'pdf-lib'; // Import pdf-lib for PDF generation
import baseUrl from '../config';

const ViewNotes = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/students/getnotes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNotes(response.data);
      } catch (error) {
        setError('Failed to fetch notes. Please try again.');
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, []);

  const downloadPDF = async (note) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    const { title, content } = note;

    page.drawText(title, {
      x: 50,
      y: 350,
      size: 24,
      color: rgb(0, 0, 0),
    });
    page.drawText(content, {
      x: 50,
      y: 320,
      size: 12,
      color: rgb(0, 0, 0),
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title}.pdf`); // Use note title for the filename
    document.body.appendChild(link);
    link.click();
    link.remove();
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px', width: '100%', textAlign: 'center' }}>
        My Notes
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {notes.map((note, index) => (
        <Card 
          key={index} 
          style={{ 
            width: '280px',
            padding: '20px', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '10px', 
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            transition: 'transform 0.2s',
            '&:hover': { transform: 'scale(1.02)' },
          }}
        >
          <Box>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
              {note.title}
            </Typography>
            <Typography variant="body2" style={{
              color: '#555',
              marginTop: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}>
              {note.content}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="10px">
            <IconButton 
              color="primary" 
              onClick={() => downloadPDF(note)} // Pass the note object
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        </Card>
      ))}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="PDF downloaded!"
      />
    </Container>
  );
};

export default ViewNotes;
