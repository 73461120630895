import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar ,Dialog, DialogActions, DialogContent, DialogTitle, Button} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios'; // Make sure to install axios
import baseUrl from '../config';


const CertificateCriteria = ({ open, onClose, certificate }) => {
  if (!certificate) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Achievements Details</DialogTitle>
      <DialogContent>
        <Typography variant="h6">{certificate.title}</Typography>
        <Typography variant="body1">Criteria: {certificate.criteria}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          color="primary"
          onClick={() => window.location.href = `/api/students/certificate/${encodeURIComponent(certificate.certificateUrl)}`}
        >
          Download Certificate
        </Button>
      </DialogActions>
    </Dialog>
  );
};



const MyCertificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await axios.get(baseUrl+'/api/students/upcoming-certificates');
        setCertificates(response.data);
      } catch (error) {
        console.error('Error fetching certificates:', error);
      }
    };

    fetchCertificates();
  }, []);

  const handleClickOpen = (certificate) => {
    setSelectedCertificate(certificate);
    setDrawerOpen(true);
  };

  const handleClickClose = () => setDrawerOpen(false);

  // const handleDownload = (certificateUrl) => {
  //   window.location.href = `/api/students/certificate/${encodeURIComponent(certificateUrl)}`;
  // };

  return (
    <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          overflowY: 'auto',
          position: 'relative',
          backgroundColor: '#bac2ff',
          // Ensure positioning context for sidebar
        }}
      >      <Typography variant="h6" sx={{ color: '#6d6d81', fontWeight: 'bold', marginBottom: '20px' }}>
         Achievements
      </Typography>

      <Grid container spacing={2}>
        {/* {certificates.map((cert, index) => ( */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Oratory Achievers
                  </Typography>
              </CardContent>
            </Card>
            
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Confidence Champions

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Magnetic Speakers

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                cursor: 'pointer',
                paddingTop:"100px",
                paddingBottom:'100px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Star Personality

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Voice of Success

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Charismatic Charmer

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Personality Powerhouse

                </Typography>
              </CardContent>
            </Card>
            
          </Grid>  <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
                  Elite Speechmakers
                </Typography>
              </CardContent>
            </Card>
            
          </Grid>
        {/* ))} */}
      </Grid>
      <CertificateCriteria open={drawerOpen} onClose={handleClickClose} certificate={selectedCertificate} />
    </Box>
  );
};

export default MyCertificates;
