import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

// Define the theme
const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark' based on your darkMode state
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <Suspense fallback={<div />}>
  <ThemeProvider theme={theme}>
  <CssBaseline />

       <App />
</ThemeProvider>
    </Suspense>
  </React.StrictMode>,
);
reportWebVitals();

 



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
