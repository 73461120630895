import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, TextField, IconButton, CardActions, Collapse } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BorderColor from '@mui/icons-material/BorderColor';
import { styled } from '@mui/material/styles';
import ChallengeCard from './ChallangesCard';
import LearningProgress from './LearningProgress';
import UserProfileCard from "./UserProfileCard";
import { faBars, faTimes, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Divider } from '@mui/material';

import Notifications from '../Components/Notification'; 
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SideScreen = () => {
  const [expanded, setExpanded] = useState(false);
  const [email, setEmail] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setEmail(user.email || 'email');
    }
  };

  useEffect(() => {
    fetchUserDetails(); // Fetch user details from local storage
  }, []);
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };


  return (
   
    <Box 
      sx={{
        width: { xs: '100%', md: '400px' }, // Adjust width for mobile and larger screens
        height: '100%', // Full height for sidebar
        overflowY: 'auto', // Enable scrolling in sidebar
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar for webkit browsers
        },
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
      
        backgroundColor: '#fff',
        padding: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
          <Box sx={{ maxWidth: 600, margin: 'auto',   }}>

      <Card sx={{ borderRadius: 4, boxShadow: 3, marginBottom: 2 }}>
      <Box sx={{margin:1}} display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Notification</Typography>
          <IconButton
          sx={{paddingRight:2}}
            edge="end"
            color="inherit"
            aria-label="notifications"
            onClick={handleNotificationClick}
          >
            <FontAwesomeIcon icon={faBell} />
          </IconButton>
        </Box>
      </Card>
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
      >
        <Notifications />
        <Divider />
        <MenuItem onClick={handleNotificationClose}>Close</MenuItem>
      </Menu>
      <Box sx={{ maxWidth: 600, margin: 'auto',  }}>

      <Card sx={{ borderRadius: 4, boxShadow: 3, marginBottom: 2 }}>
        <UserProfileCard />
      </Card>
      </Box>
      <Box sx={{ maxWidth: 600, margin: 'auto', }}>

      <Card sx={{  borderRadius: 4, boxShadow: 3, marginBottom: 2 }}>
        <CardActions disableSpacing>
          <IconButton aria-label="edit contact">
            <BorderColor />
          </IconButton>
          <Typography>
            <Box component="span" fontWeight='fontWeightMedium'>Edit Contact Information</Box>
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Primary Email Id"
                defaultValue={email}
                sx={{ mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <IconButton edge="end" sx={{ color: 'black' }}>
                      <EditIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      </Box>
      <Box sx={{ marginTop: "2%" }}>
        <LearningProgress />
      </Box>
      <Box sx={{ marginTop: "2%" }}>
        <ChallengeCard />
      </Box>
    </Box>
  );
};

export default SideScreen;