import React from 'react';
import { Card, CardContent, Box, Typography, LinearProgress, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';

const ChallengeCard = () => {
  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', }}>
 <Typography variant="h5" fontWeight="bold" mb={3} color="#050a30" align="center">
 Achievements</Typography>
    <Card sx={{ maxWidth: 400, borderRadius: 8, boxShadow: 3, padding: 2 }}>
    <CardContent>
     

      <Box textAlign="center" mb={3}>
        <LockIcon fontSize="large" color="primary" />
        <Typography variant="h5" fontWeight="bold" color="#050a30">Oratory Achievers</Typography>
        <Typography variant="body2" color="textSecondary">
          Earn this badge for unyielding consistency, the mark of a true champion.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Box key={index} sx={{
            width: 50, height: 50, backgroundColor: 'grey.300', borderRadius: '50%',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            color: 'white', fontWeight: 'bold'
          }}>
            <LockIcon fontSize="small" />
          </Box>
        ))}
      </Box>

      <Box mb={2}>
        <LinearProgress 
          variant="determinate" 
          value={40} // Replace with your progress value
          sx={{
            height: '10px', // Increased height for better visibility
            borderRadius: '5px',
            backgroundColor: '#dbd9e7',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#050a30'
            }
          }} 
        />
      </Box>

      <Typography variant="body2" color="#050a30" mb={2}>
        Complete 5 more classes to earn a Level 1 badge
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="body2" color="#757575">24 days left</Typography>
            <Button variant="text" color="primary">Explore badges & eligibility</Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {Array.from({ length: 5 }).map((_, index) => (
            <Box key={index} display="flex" alignItems="center" mb={1}>
              <Box sx={{
                border: '2px dashed', borderColor: 'primary.main',
                borderRadius: 2, width: '100%', padding: 1, textAlign: 'center'
              }}>
                <Typography variant="body2" color="primary">Level {index + 1}</Typography>
                <Typography variant="caption" display="block">
                  Complete {5 * (index + 1)} classes
                </Typography>
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </CardContent>
  </Card>
  </Box>
  );
};

export default ChallengeCard;
