import React, { useState } from 'react';
import axios from 'axios';

function TestScheduleMeeting() {
  const [topic, setTopic] = useState('');
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/schedule-meeting', {
        topic,
        startTime,
        duration,
        userEmail,
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>Schedule Meeting</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Topic:
          <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} />
        </label>
        <br />
        <label>
          Start Time:
          <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
        </label>
        <br />
        <label>
          Duration:
          <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} />
        </label>
        <br />
        <label>
          User Email:
          <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
        </label>
        <br />
        <button type="submit">Schedule Meeting</button>
      </form>
    </div>
  );
}

export default TestScheduleMeeting;