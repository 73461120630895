import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Grid,
  Divider,
  Chip,
  IconButton,Checkbox,CircularProgress,LinearProgress,Modal, TextField, Stack, Snackbar, Alert,Collapse } from '@mui/material';
import {
  CalendarToday,
  EventNote,
  Grade,
  Message,
  PendingActions
} from "@mui/icons-material";
import {  ExpandMore, ExpandLess, } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import the specific icon

import { CheckCircle, AccessTime, Assignment ,} from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import UserProfileCard from "./UserProfileCard";
import baseUrl from "../config";
import axios from "axios";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import LockIcon from '@mui/icons-material/Lock'; // Import Lock Icon from Material UI
import ScheduleIcon from '@mui/icons-material/Schedule'; // Icon for status

ChartJS.register(ArcElement, Tooltip, Legend);


const TestDashboard = (studentId) => {

  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [classSchedules, setClassSchedules] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [newDate, setNewDate] = useState(new Date());
  const [expandedScheduleId, setExpandedScheduleId] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceStats, setAttendanceStats] = useState({ present: 0, absent: 0, rescheduled: 0 });
  const [error, setError] = useState(null);  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  // const navigate = useNavigate(); // Initialize navigate
  const [unlockedBatches, setUnlockedBatches] = useState([]);
  const [lockedBatches, setLockedBatches] = useState([]);
  const [celebrationVisible, setCelebrationVisible] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate(); 

  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);

  console.log(studentData,"studentData");
  

  useEffect(() => {
    const fetchStudentAchievements = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/achievment/${parsedUser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          setStudentData(response.data);
          const unlocked = response.data.unlockedBatches || [];
          const locked = response.data.lockedBatches || [];
          setUnlockedBatches(unlocked);
          setLockedBatches(locked);
        }
      } catch (error) {
        console.error('Error fetching student achievements:', error);
      }
    };
  
    fetchStudentAchievements();
  }, [studentId]);
  
  const getBatchImage = (batchId) => {
    try {
      return require(`../Assets/batch/${batchId}.png`);
    } catch (error) {
      return '';
    }
  };

  const handleBatchClick = () => {
    setCelebrationVisible(true);
    setTimeout(() => setCelebrationVisible(false), 5000);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/attendance`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        setAttendanceData(response.data.last7DaysData);
        setAttendanceStats(response.data.stats);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch attendance data.');
        setLoading(false);
      }
    };

    fetchAttendance();
  }, []);

  const { present, absent, rescheduled } = attendanceStats;

  // Doughnut chart data
  const doughnutData = {
    labels: ['Present', 'Absent', 'Rescheduled'],
    datasets: [{
      data: [present, absent, rescheduled],
      backgroundColor: ['#4caf50', '#f44336', '#ff9800'],
      borderWidth: 3,
      borderColor: '#ffffff',
    }],
  };


  const handleAddScheduleClick = () => {
    // Navigate to the /classsummary route when the button is clicked
    navigate('/classsummary');
  };
console.log(classSchedules,"classSchedules");
const toggleExpand = (scheduleId) => {
  setExpandedScheduleId(expandedScheduleId === scheduleId ? null : scheduleId);
};


      const notices = [
        { title: "Coming Soon", date: "soon", icon: <Assignment color="primary" /> },
        // { title: "World Environment Day Program", date: "21 Apr 2024", icon: <Assignment color="success" /> },
        // { title: "Exam Preparation Notification!", date: "13 Mar 2024", icon: <Assignment color="error" /> },
        // { title: "Online Classes Preparation", date: "24 May 2024", icon: <Assignment color="info" /> },
        // { title: "Exam Time Table Release", date: "24 May 2024", icon: <Assignment color="warning" /> },
      ];
    
      const syllabusProgress = [
        { subject: "Coming soon", progress: 100 },
     ]
    
      const activities = [
        // { task: "Send Reminder to Students", time: "01:00 PM", status: "Completed" },
        // { task: "Create Routine to new staff", time: "04:50 PM", status: "Inprogress" },
        { task: "Extra Class Info to Students", time: "04:55 PM", status: "Yet To Start" },
        // { task: "Fees for Upcoming Academics", time: "04:55 PM", status: "Yet To Start" },
        // { task: "English - Essay on Visit", time: "05:55 PM", status: "Yet To Start" },
      ];

      useEffect(() => {
        const fetchClassSchedules = async () => {
          try {
            const response = await axios.get(`${baseUrl}api/students/class-schedules`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
            });
            setClassSchedules(response.data);
          } catch (error) {
            console.error('Error fetching class schedules:', error);
          }
        };
      
        fetchClassSchedules();
      }, []);

      useEffect(() => {
        const fetchUpcomingClasses = async () => {
          try {
            // Assuming baseUrl is defined somewhere in your config
            const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
            });
    
            const classesData = response.data[0].course.schedules || [];
            
            // Filter out upcoming classes
            const upcoming = classesData.filter(classData => new Date(classData.startDate) > new Date()).slice(0, 3);
            
            // Set the upcoming classes data
            setUpcomingClasses(upcoming);
          } catch (error) {
            console.error('Error fetching upcoming classes:', error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchUpcomingClasses();
      }, []);


    
      const handleRescheduleClick = (schedule) => {
        setSelectedSchedule(schedule);  // Set the schedule to reschedule
        setOpenModal(true);  // Open the modal for rescheduling
      };
    
      const handleRescheduleSubmit = async () => {
        if (!selectedSchedule) return;
    
        try {
          const { enrollmentId, _id: classScheduleId } = selectedSchedule;

          console.log(enrollmentId,"enrollmentId");
          
          const response = await axios.post(
            `${baseUrl}api/students/reschedule-request`,
            {
              enrollmentId,
              classScheduleId,
              newDate,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log('Reschedule successful:', response.data);
          setOpenSnackbar(true); // Show Snackbar on successful submission
          setOpenModal(false);  // Close the modal after successful submit
        } catch (error) {
          console.error('Error submitting reschedule request:', error);
          setOpenSnackbar(true); // Show Snackbar for error message
        }
      };
    
      // Snackbar close handler
      const handleSnackbarClose = () => {
        setOpenSnackbar(false);
      };
    
    
      const classesToShow = upcomingClasses.length > 0 ? upcomingClasses : new Array(3).fill({
        subject: 'Loading...',
        time: 'Loading...',
        status: 'upcoming',
        avatar: 'https://via.placeholder.com/100',
      });
      // const getBatchImage = (batchId) => {
      //   try {
      //     return require(`../Assets/batch/batch_${batchId}.png`);
      //   } catch (error) {
      //     return '';
      //   }
      // };


      
  return (
    
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}> {/* Flex container with wrap */}
    <Grid container spacing={2}>
     <Grid item xs={12} md={6} lg={4}>

     <Card sx={{ borderRadius: 4, boxShadow: 3, marginBottom: 2 }}>
        <UserProfileCard />
      </Card>

  {/* Upcoming Classes */}
  <Card sx={{ 
  borderRadius: 3, 
  boxShadow: 6, 
  mt: 2, 
  backgroundColor: '#ffffff', 
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease', 
  '&:hover': { 
    transform: 'scale(1.05)', 
    boxShadow: 10 
  },
}}>
  <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Typography variant="h6" sx={{ fontWeight: 600, color: '#1e293b', mb: { xs: 1, sm: 0 } }}>
      Upcoming Classes

      </Typography>
      
      <Button
        size="small"
        sx={{
          textTransform: 'none',
          color: '#1e293b',
          border: '2px solid #1e293b',
          '&:hover': { backgroundColor: '#e6f4f1', borderColor: '#1e293b' },
          borderRadius: 2
        }}
        onClick={handleAddScheduleClick}
      >
        All Class
      </Button>
    </Box>
    <Divider sx={{ mt: 1, mb: 1, backgroundColor: '#e0e0e0' }} />
    {classesToShow.map((classInfo, index) => {
            const chapterTitle = classInfo.chapters && classInfo.chapters.length > 0 ? classInfo.chapters[0].title : 'Class Title';

      return (
        <Box 
          key={index} 
          display="flex" 
          alignItems="center" 
          justifyContent="space-between" 
          sx={{ mb: 1.5, padding: 1.6, borderRadius: 4, backgroundColor: '#f0f4f8', boxShadow: 3, flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar 
              src={classInfo.teacherAvatar || "https://via.placeholder.com/100"} 
              sx={{ 
                border: '3px solid #2196f3', 
                width: 50, 
                height: 50, 
                boxShadow: 5 
              }} 
            />
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 700, color: '#2c3e50', fontSize: { xs: '1rem', sm: '1.1rem' } }}>
                {chapterTitle || 'Class Title'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#7f8c8d', fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                <AccessTimeIcon sx={{ fontSize: '0.9rem', marginRight: 0.5, color: '#2980b9' }} /> 
                {classInfo.time || 'N/A'} 
                <span style={{ margin: '0 4px' }}>•</span>
                {new Date(classInfo.endDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={1} flexDirection={{ xs: 'column', sm: 'row' }}>
            <Typography 
              variant="body2" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                border: '2px solid #004d40',  
                borderRadius: '30px',  
                padding: '0px 16px',  
                transition: 'background-color 0.3s ease-in-out',
                backgroundColor: '#ffffff',
                '&:hover': {
                  backgroundColor: '#e0f7fa', 
                }
              }}
            >
              <a href={classInfo.joinUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#004d40' }}>
                <strong style={{ marginRight: 8 }}>Join Class</strong>
                <IconButton 
                  sx={{ 
                    color: '#004d40', 
                    fontSize: '1.5rem', 
                  }}
                >
                  <LinkIcon />
                </IconButton>
              </a>
            </Typography>
          </Box>
        </Box>
      )
    })}
  </CardContent>
</Card>

</Grid>
  
      {/* Attendance Card */}
      <Grid item xs={12} md={6} lg={4}>
      <Card sx={{
        borderRadius: 3,
        boxShadow: 5,
        background: 'linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%)',
        maxWidth: 500,
        mx: 'auto',
        p: 2,
        pb: 5,
        transition: 'transform 0.3s ease-in-out',
        '&:hover': { transform: 'scale(1.05)' }
      }}>
        <CardContent sx={{ p: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontWeight: 700, color: '#1e293b' }}>
              Attendance Overview
            </Typography>
            <Button
              size="small"
              sx={{
                textTransform: 'none',
                color: '#1e293b',
                border: '2px solid #1e293b',
                '&:hover': { backgroundColor: '#e6f4f1', borderColor: '#1e293b' },
                borderRadius: 2
              }}
            >
              This Week
            </Button>
          </Box>

          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="space-between" sx={{ mt: 3, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 2 }}>
            <Box textAlign="center">
              <Typography variant="body2" sx={{ fontWeight: 500, color: '#4caf50' }}>
                Present
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 700, color: '#4caf50' }}>
                {present}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" sx={{ fontWeight: 500, color: '#f44336' }}>
                Absent
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 700, color: '#f44336' }}>
                {absent}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2" sx={{ fontWeight: 500, color: '#ff9800' }}>
                Rescheduled
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 700, color: '#ff9800' }}>
                {rescheduled}
              </Typography>
            </Box>
          </Box>

          <Box mt={3} display="flex" justifyContent="center">
            <Box sx={{ width: 240, height: 240 }}>
              <Doughnut
                data={doughnutData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                      labels: {
                        font: {
                          weight: 'bold',
                          size: 14,
                        },
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          let label = context.label || '';
                          if (context.parsed !== null) {
                            label += `: ${context.parsed}`;
                          }
                          return label;
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 4, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#1e293b' }}>
              Last 7 Days Report
            </Typography>

            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
              {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
                <Box key={index} textAlign="center">
                  <Box
                    sx={{
                      display: 'inline-block',
                      width: 28,
                      height: 28,
                      backgroundColor: attendanceData[index]?.status === 'completed' ? '#4caf50' : '#f44336',
                      color: '#fff',
                      borderRadius: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 600,
                      marginBottom: 1,
                    }}
                  >
                    {day}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

        </CardContent>
      </Card>
    </Grid>
      {/* Calendar and Schedules */}
      <Grid item xs={12} md={6} lg={4}>
  <Card
    sx={{
      borderRadius: 2,
      boxShadow: 3,
      overflow: 'hidden',
      backgroundColor: '#fff',
      transition: 'transform 0.3s ease',
      '&:hover': { transform: 'scale(1.05)' },
    }}
  >
    <CardContent sx={{ p: { xs: 2, md: 3 } }}>
      {/* Header Section */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        gap={2}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: '#1e293b',
            fontSize: { xs: '1.1rem', sm: '1.25rem' },
          }}
        >
          Class Schedules
        </Typography>
        <Button
          size="small"
          sx={{
            textTransform: 'none',
            color: '#1e293b',
            border: '2px solid #1e293b',
            '&:hover': { backgroundColor: '#e6f4f1', borderColor: '#1e293b' },
            borderRadius: 2,
            fontSize: { xs: '0.875rem', sm: '1rem' },
          }}
          onClick={handleAddScheduleClick}
        >
          All Schedule Classes
        </Button>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Schedule List */}
      <Box>
        {classSchedules
          .filter((schedule) => new Date(schedule.startDate) > new Date())
          .slice(0, 5)
          .map((schedule, index) => (
            <>
            <Box
              key={index}
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              justifyContent="space-between"
              sx={{
                mb: 1.5,
                p: { xs: 2, sm: 3 },
                borderRadius: 2,
                backgroundColor: schedule.isRescheduleRequested ? '#f9e0b2' : '#ffffff',
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 6,
                  backgroundColor: schedule.isRescheduleRequested ? '#fce1a1' : '#f4f4f4',
                },
                transition: 'all 0.3s ease',
              }}
            >
              {/* Schedule Details */}
              <Box display="flex" alignItems="center" gap={2}>
                <AccessTime sx={{ mr: 1, fontSize: '2.0rem', color: '#1976d2' }} />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: '#444',
                      fontSize: { xs: '1rem', sm: '1.1rem' },
                    }}
                  >
                    {schedule.subject} {new Date(schedule.startDate).toLocaleDateString()}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#888',
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      marginTop: 0,
                    }}
                  >
                    {schedule.time}
                  </Typography>
                </Box>
              </Box>

              {/* Reschedule Status */}
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                mt={{ xs: 2, sm: 0 }}
              >
                {schedule.isRescheduleRequested && (
                  <Typography
                    variant="body2"
                    sx={{ color: '#d32f2f', fontWeight: 600 }}
                  >
                    Reschedule Requested
                  </Typography>
                )}
              </Box>

              {/* Action Button */}
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                mt={{ xs: 2, sm: 0 }}
              >
                {schedule.isRescheduleRequested ? (
                  <IconButton
                    onClick={() => toggleExpand(schedule._id)}
                    sx={{ color: '#1976d2' }}
                  >
                    {expandedScheduleId === schedule._id ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '2px solid #004d40',
                      borderRadius: '50px',
                      padding: '0px 16px',
                      fontSize: '0.875rem',
                      '&:hover': { backgroundColor: '#e0f2f1' },
                      transition: 'all 0.3s ease',
                    }}
                    onClick={() => handleRescheduleClick(schedule)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <strong>Reschedule Class</strong>
                      <IconButton
                        sx={{
                          color: '#004d40',
                          fontSize: '1.25rem',
                          marginLeft: '8px',
                        }}
                      >
                        <CalendarTodayIcon />
                      </IconButton>
                    </div>
                  </Typography>
                )}
              </Box>
            </Box>
            <Collapse in={expandedScheduleId === schedule._id}>
    {schedule.isRescheduleRequested && (

 
<Box 
  sx={{ 
    mb: 3, 
    backgroundColor: '#ffffff', 
    p: 3, 
    borderRadius: 2, 
    boxShadow: 3, // Adding depth with a smooth shadow
    borderLeft: '5px solid #d32f2f', // Status color for visual emphasis
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: 6, // Darker shadow on hover to indicate interactivity
    },
  }}
>
  {/* Header: Reschedule Requested */}
  <Typography 
    variant="h6" 
    sx={{ 
      color: '#d32f2f', 
      fontWeight: 700, 
      fontSize: '1.125rem', 
      letterSpacing: '0.5px', // Clean letter spacing
      mb: 2,
    }}
  >
    Reschedule Requested
  </Typography>

  {/* Original Date Section */}
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <CalendarTodayIcon sx={{ color: '#d32f2f', fontSize: 22, marginRight: 2 }} />
    <Typography 
      variant="body2" 
      sx={{ 
        color: '#333', 
        fontSize: '1rem', 
        fontWeight: 500, 
        opacity: 0.9 
      }}
    >
      <strong>Original Date:</strong> {new Date(schedule.startDate).toLocaleString()}
    </Typography>
  </Box>

  {/* Rescheduled Date Section */}
  {schedule.rescheduledDate && (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <CalendarTodayIcon sx={{ color: '#004d40', fontSize: 22, marginRight: 2 }} />
      <Typography 
        variant="body2" 
        sx={{ 
          color: '#333', 
          fontSize: '1rem', 
          fontWeight: 500, 
          opacity: 0.9 
        }}
      >
        <strong>Rescheduled Date:</strong> {new Date(schedule.rescheduledDate).toLocaleString()}
      </Typography>
    </Box>
  )}

  {/* Status Section */}
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <ScheduleIcon sx={{ color: '#004d40', fontSize: 22, marginRight: 2 }} />
    <Typography 
      variant="body2" 
      sx={{ 
        color: '#333', 
        fontSize: '1rem', 
        fontWeight: 500, 
        opacity: 0.9
      }}
    >
      <strong>Status:</strong> {schedule.rescheduleApprovalStatus}
    </Typography>
  </Box>
</Box>
  
    )}
  </Collapse>
            </>
          ))}
      </Box>

      {/* Modal for Rescheduling */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 450 },
            bgcolor: '#f5f5f5',
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 3, fontWeight: 600, color: '#1976d2' }}
          >
            Reschedule Class
          </Typography>
          <TextField
            label="New Date"
            type="datetime-local"
            fullWidth
            value={newDate.toISOString().slice(0, 16)}
            onChange={(e) => setNewDate(new Date(e.target.value))}
            InputLabelProps={{ shrink: true }}
            sx={{
              mb: 3,
              '& .MuiInputBase-root': {
                backgroundColor: '#fff',
                borderRadius: 1,
                borderColor: '#1976d2',
                '&:hover': { borderColor: '#115293' },
              },
              '& .MuiInputLabel-root': { color: '#1976d2' },
            }}
            InputProps={{
              sx: { padding: '10px', fontWeight: 500, color: '#1976d2' },
            }}
            helperText="Pick a date and time for rescheduling."
          />
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleRescheduleSubmit}
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                '&:hover': { backgroundColor: '#115293' },
              }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenModal(false)}
              sx={{
                borderColor: '#1976d2',
                color: '#1976d2',
                '&:hover': { backgroundColor: '#f5f5f5' },
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </CardContent>
  </Card>
</Grid>
    </Grid>
  
    {/* Upcoming Classes */}

  
    {/* Badges Section */}
    <Grid container justifyContent="center" sx={{ mt: 4 }}>
  <Grid item xs={12}>
    <Card
      sx={{
        borderRadius: 6,
        boxShadow: 4,
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        backgroundColor: '#f9fafb',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: '#2c3e50',
            textTransform: 'uppercase',
            letterSpacing: 1.2,
            mb: 3,
            textAlign: 'center',
          }}
        >
          Achievement Badges
        </Typography>

        {/* Badges Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            overflowX: 'auto',
            pb: 2,
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {/* Display Unlocked Batches */}
          {unlockedBatches.map((batch, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: { xs: 100, sm: 150, md: 170, lg: 220 },
                height: { xs: 100, sm: 150, md: 170, lg: 220 },
                flex: '0 0 auto',
                mr: 2,
              }}
            >
              <img
                src={getBatchImage(batch.batchId)}
                alt={batch.title}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
                onClick={handleBatchClick}
              />
            </Box>
          ))}

          {/* Display Locked Batches */}
          {lockedBatches.map((batch, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: { xs: 100, sm: 150, md: 170, lg: 220 },
                height: { xs: 100, sm: 150, md: 170, lg: 220 },
                flex: '0 0 auto',
                mr: 2,
              }}
            >
              <img
                src={getBatchImage(batch.batchId)}
                alt={batch.title}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
                onClick={handleBatchClick}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '70%',
                  height: '70%',
                }}
              >
                <LockIcon sx={{ color: '#fff', fontSize: { xs: 30, md: 60 } }} />
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  </Grid>
</Grid>


  
<Grid container spacing={4} sx={{ mt: 4 }}>
  {/* Notice Board */}
  <Grid item xs={12} md={6} lg={4}>
    <Card
      sx={{
        borderRadius: 8,
        boxShadow: 4,
        backgroundColor: '#ffffff',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: '#34495e',
            letterSpacing: 1.2,
            textTransform: 'uppercase',
            mb: 3,
            textAlign: 'center',
          }}
        >
          Notice Board
        </Typography>
        {notices.map((notice, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Box
              sx={{
                backgroundColor: '#ecf0f1',
                p: 1.5,
                borderRadius: '50%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {notice.icon}
            </Box>
            <Box sx={{ ml: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: '#2c3e50',
                  letterSpacing: 0.5,
                }}
              >
                {notice.title}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Added on: {notice.date}
              </Typography>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  </Grid>

  {/* Syllabus */}
  <Grid item xs={12} md={6} lg={4}>
  <Card
    sx={{
      borderRadius: 8,
      boxShadow: 4,
      backgroundColor: '#ffffff',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
      },
    }}
  >
    <CardContent sx={{ p: 4 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 700,
          color: '#34495e',
          letterSpacing: 1.2,
          textTransform: 'uppercase',
          mb: 3,
          textAlign: 'center',
        }}
      >
        Syllabus
      </Typography>
      <Typography variant="body2" color="success.main" sx={{ mb: 3, textAlign: 'center' }}>
        Results derived from syllabus completion in the respective classes.
      </Typography>
      {syllabusProgress.map((syllabus, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              color: '#34495e',
              mb: 1,
              textTransform: 'capitalize',
            }}
          >
            {syllabus.subject}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={syllabus.progress}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: '#ecf0f1',
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                background: `linear-gradient(90deg, #ff7e5f ${syllabus.progress}%, #feb47b ${syllabus.progress}%)`, // gradient effect
              },
            }}
          />
        </Box>
      ))}
    </CardContent>
  </Card>
</Grid>


  {/* Activity Classes */}
  <Grid item xs={12} md={6} lg={4}>
    <Card
      sx={{
        borderRadius: 8,
        boxShadow: 4,
        backgroundColor: '#ffffff',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: '#34495e',
            letterSpacing: 1.2,
            textTransform: 'uppercase',
            mb: 3,
            textAlign: 'center',
          }}
        >
          Activity Classes
        </Typography>
        {activities.map((activity, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Checkbox
              checked={activity.status === 'Completed'}
              sx={{
                color: activity.status === 'Completed' ? '#27ae60' : '#f39c12',
                '&.Mui-checked': {
                  color: activity.status === 'Completed' ? '#27ae60' : '#f39c12',
                },
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: '#34495e',
                  letterSpacing: 0.5,
                }}
              >
                {activity.task}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {activity.time}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color={
                activity.status === 'Completed'
                  ? 'success'
                  : activity.status === 'Inprogress'
                  ? 'info'
                  : 'warning'
              }
              size="small"
              sx={{
                textTransform: 'none',
                ml: 2,
                borderRadius: 2,
                fontWeight: 600,
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              {activity.status}
            </Button>
          </Box>
        ))}
      </CardContent>
    </Card>
  </Grid>
</Grid>
<Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Reschedule request submitted successfully!
        </Alert>
      </Snackbar>
  </Box>
  
  );
};

export default TestDashboard;




