import React from "react";
import EnrichmentClasses from "../Components/EnrichmentClass";
import { Box,} from '@mui/material';
import SideScreen from "../Components/SideScreen";

const EnrichmentClassesPage = () => {
  
  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    // flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
   
   <EnrichmentClasses/>   
   {/* <SideScreen/> */}

    {/* <Box
      component={SideScreen}
      open={sidebarOpen}
      onClose={handleSidebarToggle}
     
    /> */}
  </Box>
  );
};

export default EnrichmentClassesPage;
