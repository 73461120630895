import React from "react";
import { Box,} from '@mui/material';
import SidebarRight from "../Components/SideScreen";
import Assessments from "../Components/Assessments";

const AssessmentsPage = () => {

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    // flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >

<Assessments/>   


  {/* <SidebarRight/> */}
  </Box>

  );
};

export default AssessmentsPage;

