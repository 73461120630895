// import React from 'react';
// import { Box, Card, CardContent, Typography, Grid, Avatar, Button, IconButton, TextField } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import ShareIcon from '@mui/icons-material/Share';
// import LinkIcon from '@mui/icons-material/Link';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { trophy, trophyone } from '../Assets';

// const rewards = [
//   { title: '5 Free Classes', gems: 5, image: '/path-to-image1', referrals: 0 },
//   { title: '5 Maths Classes', gems: 10, image: '/path-to-image2', referrals: 0 },
//   { title: '8 Free Classes', gems: 10, image: '/path-to-image1', referrals: 0 },
//   { title: '20 % off on Next Course', gems: 20, image: '/path-to-image3', referrals: 1 },
//   { title: 'Amazon Voucher worth $200', gems: 30, image: '/path-to-image4', referrals: 2 },
//   { title: 'AirPods Pro', gems: 40, image: '/path-to-image5', referrals: 3 },
//   { title: 'PlayStation 5', gems: 50, image: '/path-to-image5', referrals: 3 },

// ];

// const Reward = () => {
//   return (
//     <>
//     <Box
//         sx={{
//           flex: 1,
//           display: 'flex',
//           flexDirection: 'column',
//           padding: 2,
//           overflowY: 'auto',
//           position: 'relative',
//           backgroundColor: '#bac2ff',
//           // backgroundImage:`url(${trophyone})`,
//           // backgroundSize:'cover',          // Ensure positioning context for sidebar
//         }}
//       >    {/* Header Section */}
//       <Typography variant="h6" sx={{ color: '#6d6d81', fontWeight: 'bold', marginBottom: '20px' }}>
//         My Rewards
//       </Typography>
//       <Typography variant="body2" sx={{ color: '#6d6d81', marginBottom: '20px' }}>
//         Refer to earn gems and redeem your rewards
//       </Typography>

//       {/* Referral Section */}
//       <Card sx={{ padding: '20px', marginBottom: '40px', borderRadius: '16px',    width: {
//       xs: '100%', // 100% width on extra-small (xs) screens
//       sm: '100%', // 100% width on small (sm) screens
//       md: '70%',  // 60% width on medium (md) screens
//     }}}>
//         <Typography variant="h6" sx={{ marginBottom: '10px', color: '#6d6d81' }}>
//           You have <span style={{ color: '#050a30' }}>0 successful referral</span>. Refer more to win your dream rewards
//         </Typography>
//         <Typography variant="body2" sx={{ marginBottom: '20px', color: '#6d6d81' }}>
//           You have referred 1 friends. Refer again to earn 10 more gems.
//         </Typography>

//         <Grid container spacing={2} sx={{ alignItems: 'center' }}>
//           <Grid item xs={12} md={8}>
//             <TextField
//               fullWidth
//               label="Copy and share your referral link with friends"
//               value="https://orattrics...Copy Link"
//               InputProps={{
//                 endAdornment: (
//                   <IconButton>
//                     <LinkIcon />
//                   </IconButton>
//                 ),
//               }}
//               sx={{ marginBottom: '10px' }}
//             />
//           </Grid>
        
//         </Grid>
//         <Typography variant="body2" sx={{ marginTop: '20px', color: '#6d6d81' }}>
//           rohan from India has won a PlayStation 5 by referring 7 friends.
         
//         </Typography>
//         <Typography variant="body2" sx={{ marginTop: '20px', color: '#6d6d81' }}>
//           <Button variant="outlined" sx={{ marginLeft: '10px', color: '#050a30', borderColor: '#050a30' }}>
//             Refer Now
//           </Button>
//           <Button variant="outlined" sx={{ marginLeft: '10px', color: '#050a30', borderColor: '#050a30' }} startIcon={<ShareIcon />}>
//               Share
//             </Button>
//         </Typography>
     
//       </Card>

//       {/* Rewards Section */}
//       <Typography variant="h6" sx={{ marginBottom: '20px', color: '#6d6d81', fontWeight: 'bold' }}>
//         Redeem Your Gems
//       </Typography>

//       <Grid container spacing={5}>
//         {rewards.map((reward, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card sx={{ padding: '20px', textAlign: 'center', borderRadius: '16px' }}>
//               <CardContent>
//                 <Avatar
//                   sx={{
//                     margin: 'auto',
//                     width: 56,
//                     height: 56,
//                     backgroundImage: `url(${reward.image})`,
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center',
//                   }}
//                 />
//                 <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#6d6d81' }}>
//                   {reward.title}
//                 </Typography>
//                 <Button
//                   fullWidth
//                   variant="outlined"
//                   sx={{
//                     marginTop: '10px',
//                     borderColor: '#050a30',
//                     color: '#050a30',
//                   }}
//                 >
//                   Redeem for {reward.gems} <LockIcon sx={{ fontSize: 16, marginLeft: '5px' }} />
//                 </Button>
//                 {/* {reward.referrals > 0 && (
//                   <Typography variant="body2" sx={{ marginTop: '10px', color: '#6d6d81' }}>
//                     {reward.referrals} more referrals required to earn {reward.gems} gems for redeeming this.
//                   </Typography>
//                 )} */}
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//     </>
//   );
// };

// export default Reward;



import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    styled,
    Avatar,
    LinearProgress,
    useTheme,
    Stack,
} from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RedeemIcon from '@mui/icons-material/Redeem';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { online } from '../Assets/index';

// Re-organized and more reusable color definitions
const colors = {
    primary: '#42a5f5',
    secondary: '#ffc107',
    success: '#4caf50',
    greyLight: '#f5f5f5',
    greyMedium: '#e0e0e0',
    textPrimary: '#333',
    textSecondary: '#555',
    white: '#fff',
};

// Enhanced and more consistent styling using `styled`
const StyledRewardCard = styled(Card)(({ theme, status }) => ({ // Receive theme as prop
    display: 'flex',
    flexDirection: 'column',
    height: '80%', // Ensure card takes full height of its grid item
    padding: theme.spacing(2), // Increased padding for better spacing
    backgroundColor: colors.white,
    border: `1px solid ${colors.greyMedium}`,
    borderRadius: 12,
    boxShadow: theme.shadows[2],
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.03)',
    },
    ...(status === 'locked' && {
        opacity: 0.6, // Slightly less opaque for locked items
        pointerEvents: 'none', // Prevent hover effect and interaction for locked cards
        '&:hover': {
            transform: 'none',
        },
    }),
}));

const Badge = styled(Avatar)(({ theme, badgeColor }) => ({
    backgroundColor: badgeColor || colors.primary,
    width: 50, // Slightly smaller badge size
    height: 50,
    fontSize: '1rem',
    fontWeight: 'bold',
    color: colors.white,
}));

const PointsBadge = styled(Badge)({
    marginRight: ({ theme }) => theme.spacing(1.5),
});

const EarnBadge = styled(Badge)({
    width: 30, // Smaller earn badge
    height: 30,
    fontSize: '0.8rem',
    marginRight: ({ theme }) => theme.spacing(1),
});

const StyledRedeemButton = styled(Button)(({ theme, rewardStatus }) => ({ // Receive theme as prop
    fontWeight: 'bold',
    padding: theme.spacing(0.7, 2),
    borderRadius: 8,
    fontSize: '0.8rem',
    textTransform: 'none', // Prevent uppercase for better readability
    ...(rewardStatus !== 'redeemed' && {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.primary,
            opacity: 0.9,
        },
    }),
    ...(rewardStatus === 'redeemed' && {
        color: colors.white,
        backgroundColor: colors.success,
        '&:hover': {
            backgroundColor: colors.success,
            opacity: 0.9,
        },
    }),
    ...(rewardStatus === 'locked' && {
        backgroundColor: theme.palette.grey[300], // Greyed out for locked status
        color: theme.palette.grey[600],
        pointerEvents: 'none', // Disable interaction for locked button
    }),
}));


const Reward = ({
    userName = "Adarsh", // Default user name, can be passed as prop
    currentPoints = 450, // Current points, can be passed as prop
    rewardsData = [ // Rewards data as prop for reusability and testability
        { title: 'Shoutout on Oratrics Page', points: 150, status: 'redeemed', image: '/shoutout.png', rewardName: 'Shoutout on Oratrics Page' },
        { title: 'Custom Oratrics Badge', points: 200, status: 'locked', image: '/badge.png', rewardName: 'Custom Oratrics Badge' },
        { title: 'Get 5 extra classes', points: 250, status: 'locked', image: '/classes.png', rewardName: 'Get 5 Extra Classes' },
        { title: 'Get 5 Maths classes', points: 350, status: 'locked', image: '/math_classes.png', rewardName: 'Get 5 Maths Classes' },
        { title: 'Amazon gift card ($50)', points: 500, status: 'earned', image: '/amazon_giftcard.png', rewardName: 'Amazon Gift Card ($50)' },
        { title: 'Oratrics Goodie Bag', points: 600, status: 'locked', image: '/goodie_bag.png', rewardName: 'Oratrics Goodie Bag' },
        { title: 'RGB Desk Lamp', points: 750, status: 'locked', image: '/desk_lamp.png', rewardName: 'RGB Desk Lamp' },
        { title: 'Amazon Echo Dot', points: 1000, status: 'locked', image: '/echo_dot.png', rewardName: 'Amazon Echo Dot' },
        { title: 'Amazon Kindle', points: 1500, status: 'locked', image: '/amazon_kindle.png', rewardName: 'Amazon Kindle' },
        { title: 'Apple AirPods Pro', points: 3000, status: 'locked', image: '/airpods_pro.png', rewardName: 'Apple AirPods Pro' },
        { title: 'Sony PS5', points: 4500, status: 'locked', image: '/sony_ps5.png', rewardName: 'Sony PS5' },
        { title: 'Apple MacBook Air', points: 10000, status: 'locked', image: '/macbook_air.png', rewardName: 'Apple MacBook Air' },
    ],
    earnActions = [ // Earn points actions as prop
        { text: 'Complete a course module', points: 10 },
        { text: 'Win a weekly speech challenge', points: 20 },
        { text: 'Share a speech on Social Media with #oratrics', points: 50 },
        { text: 'Get a friend to attend a free trial class', points: 100 },
    ]
}) => {
    const theme = useTheme();
    //const online = '/path/to/your/online_image.png'; //  <-  Correct way to use local images, ensure path is correct relative to component

    return (
        <Box sx={{
            padding: 3, // Increased padding for the whole container
            backgroundColor: colors.greyLight,
        }}>
            <Typography variant="h4" fontWeight="bold" color={colors.textPrimary} gutterBottom>
                Hey {userName},
            </Typography>
            <Typography variant="subtitle1" color={colors.textSecondary} sx={{ mb: 3, fontWeight: '500' }}>
                Welcome back! Explore new opportunities to earn and redeem rewards.
            </Typography>

            {/* Rewards Hub Section Header */}
            <Box sx={{
                backgroundColor: colors.secondary,
                padding: 2,
                marginBottom: 3,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                display: 'flex',
                alignItems: 'center',
                color: colors.textPrimary,
            }}>
                <Typography variant="h6" fontWeight="bold" >Your Rewards Hub</Typography> {/* Reduced to h6 for better visual hierarchy*/}
                <EmojiEventsOutlinedIcon sx={{ marginLeft: 1.5, fontSize: '1.5rem' }} /> {/* Reduced icon size */}
            </Box>

            <Grid container spacing={3}> {/* Increased spacing for better layout */}
                {/* Current Points Card */}
                <Grid item xs={12} md={3}>
                
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                        <Typography variant="h6" fontWeight="bold" color={colors.textPrimary}>
                        Current Points
                        </Typography>
                    </Box>
                    <StyledRewardCard sx={{ padding: 3, textAlign: 'center', alignItems: 'center' }}> {/* Centralized content */}
                        <Box sx={{ mb: 2 }}>
                            <StarBorderIcon sx={{ fontSize: '3rem', color: colors.secondary }} /> {/* Slightly smaller icon */}
                        </Box>
                        <Typography color={colors.secondary} sx={{ fontWeight: 'bold', fontSize: '3rem', mb: 1 }}> {/* Slightly smaller number */}
                            {currentPoints}
                        </Typography>
                        <Typography variant="subtitle1" color={colors.textPrimary} sx={{ fontWeight: 'bold', mb: 2 }}> {/* Subtitle1 for balance text */}
                            Your Balance
                        </Typography>
                        <Typography variant="body2" color={colors.textSecondary} sx={{ lineHeight: 1.4, fontSize: '0.85rem', px: 2 }}> {/* Reduced font size for body text */}
                            Keep earning points to unlock more exciting rewards!
                        </Typography>
                    </StyledRewardCard>
                </Grid>

                {/* Earn Points Actions Card */}
                <Grid item xs={12} md={9}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
                        <TrendingUpIcon color="primary" sx={{ mr: 1.5, fontSize: '1.8rem' }} /> {/* Reduced icon size */}
                        <Typography variant="h6" fontWeight="bold" color={colors.textPrimary}>
                            Earn Points
                        </Typography>
                    </Box>
                    <StyledRewardCard >
                        <CardContent> {/* Added CardContent for better padding management */}
                            <Stack spacing={2}> {/* Using Stack for vertical spacing */}
                                {earnActions.map((item, index) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingX: 2, // Added horizontal padding
                                        borderRadius: 10,
                                        backgroundColor: index % 2 !== 0 ? colors.greyLight : 'transparent',
                                        '&:hover': { backgroundColor: colors.greyMedium }
                                    }}>
                                        <EarnBadge badgeColor={colors.secondary}>{item.points}</EarnBadge>
                                        <Typography variant="body1" color={colors.textPrimary} sx={{ flexGrow: 1, fontSize: '0.9rem', marginLeft: 1 }}> {/* Reduced font size and added margin */}
                                            {item.text}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                color: colors.textPrimary,
                                                fontSize: '0.8rem',
                                                padding: '4px 14px', // Reduced button padding
                                                borderRadius: 8,
                                                fontWeight: 'bold',
                                                textTransform: 'none'
                                            }}
                                            startIcon={<TrendingUpIcon style={{ fontSize: '1rem' }} />} // Reduced icon size
                                        >
                                            Earn
                                        </Button>
                                    </Box>
                                ))}
                            </Stack>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    marginTop: 2,
                                    borderRadius: 8,
                                    color: colors.textPrimary,
                                    fontWeight: 'bold',
                                    fontSize: '0.8rem',
                                    textTransform: 'none'
                                }}
                            >
                                View More Actions
                            </Button>
                        </CardContent>
                    </StyledRewardCard>
                </Grid>
            </Grid>

            {/* Available Rewards Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginY: 3 }}>
                <RedeemIcon color="primary" sx={{ mr: 1.5, fontSize: '2rem' }} /> {/* Reduced icon size */}
                <Typography variant="h5" fontWeight="bold" color={colors.textPrimary} gutterBottom>
                    Available Rewards
                </Typography>
            </Box>
            <Grid container spacing={3}>
                {rewardsData.map((reward, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <StyledRewardCard status={reward.status} sx={{ textAlign: 'left',    height: '100%',padding:0,width:"80%" }}> {/* Changed to textAlign left for content flow */}
                            {reward.image && (
                                <Box
                                    component="img"
                                    src={online}
                                    alt={reward.rewardName}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        height: 180, // Fixed image height for consistency
                                        objectFit: 'cover',
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        marginBottom: 2, // Added margin below image
                                    }}
                                />
                            )}


                            <CardContent sx={{ padding: theme.spacing(1) }}> {/* Reduced card content padding */}
                            <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        border: '2px solid #111',
                                        borderRadius: '20px',
                                        padding: '5px 10px',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <LockIcon sx={{ marginRight: '8px' }} />
                                    Unlocked
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    color={colors.textPrimary}
                                    gutterBottom
                                    sx={{
                                        fontSize: '1rem', // Slightly reduced font size
                                        lineHeight: 1.3,
                                        mt:2
                                    }}
                                >
                                    {reward.rewardName}
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={reward.progress} // You might need to calculate progress based on points and reward points
                                    sx={{
                                        marginTop: 1,
                                        height: 8, // Reduced progress bar height
                                        borderRadius: 4,
                                        backgroundColor: theme.palette.grey[300],
                                        '& .MuiLinearProgress-bar': { backgroundColor: colors.secondary },
                                    }}
                                />
                                <Box sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontWeight: 'bold', color: colors.secondary, fontSize: '0.9rem' }}>
                                        {reward.points} Points
                                    </Typography>
                                    {reward.status !== 'locked' && ( // Conditionally render status/redeem text
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem',
                                                color: reward.status === 'redeemed' ? colors.success : colors.secondary
                                            }}
                                        >
                                            {reward.status === 'redeemed' ? 'Earned' : 'Available'}
                                        </Typography>
                                    )}
                                </Box>

                                <StyledRedeemButton
                                    variant="contained"
                                    fullWidth
                                    rewardStatus={reward.status}
                                >
                                    {reward.status === 'redeemed' ? 'Claim Again' : reward.status === 'locked' ? 'Locked' : 'Redeem Now'}
                                </StyledRedeemButton>
                            </CardContent>
                        </StyledRewardCard>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Reward;
