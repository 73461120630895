import React from 'react';
import { Box, Typography, LinearProgress, Paper, IconButton, Divider, List, ListItem, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';

const CertificateCriteria = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 450,
          boxSizing: 'border-box',
          backgroundColor: '#fff',
           borderRadius: 3, boxShadow: 3, p: 2, bgcolor: '#fff', fontFamily: 'Arial, sans-serif'
        },
      }}
    >
      <Box sx={{ }}>
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" fontWeight="bold" sx={{ color: '#000' }}>
            Criteria to earn Emerging Innovator certificate
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon sx={{ color: '#8C30F5' }} />
          </IconButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Participation Certificate Section */}
        <Box>
          <Typography variant="body2" fontWeight="bold" sx={{ color: '#8C30F5', mb: 1 }}>
            Participation Certificate
          </Typography>
          <Paper elevation={0} sx={{ p: 2, borderRadius: 2, backgroundColor: '#f7e8ff' }}>
            <Typography variant="body2" fontWeight="bold" sx={{ color: '#000', mb: 1 }}>
              Complete all the lessons & assignments
            </Typography>
            <List disablePadding>
              {/* Task 1 */}
              <ListItem sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
                <Typography variant="body2" fontWeight="bold" sx={{ color: '#3AB650', width: 120 }}>
                  In Progress
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    bgcolor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': { bgcolor: '#3AB650' },
                    borderRadius: 5,
                    height: 8,
                    width: '100%',
                  }}
                />
              </ListItem>
              <Typography variant="body2" sx={{ mt: 1, ml: 5, color: '#000' }}>
                Exploring PictoBlox
              </Typography>

              {/* Task 2 */}
              <ListItem sx={{ display: 'flex', alignItems: 'center', p: 0, mt: 2 }}>
                <Typography variant="body2" fontWeight="bold" sx={{ color: '#A0A0A0', width: 120 }}>
                  Not Started
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={0}
                  sx={{
                    bgcolor: '#e0e0e0',
                    borderRadius: 5,
                    height: 8,
                    width: '100%',
                  }}
                />
              </ListItem>
              <Typography variant="body2" sx={{ mt: 1, ml: 5, color: '#000' }}>
                Diving into Robotics
              </Typography>

              {/* Task 3 */}
              <ListItem sx={{ display: 'flex', alignItems: 'center', p: 0, mt: 2 }}>
                <Typography variant="body2" fontWeight="bold" sx={{ color: '#A0A0A0', width: 120 }}>
                  Not Started
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={0}
                  sx={{
                    bgcolor: '#e0e0e0',
                    borderRadius: 5,
                    height: 8,
                    width: '100%',
                  }}
                />
              </ListItem>
              <Typography variant="body2" sx={{ mt: 1, ml: 5, color: '#000' }}>
                Code And Play
              </Typography>
            </List>
          </Paper>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Excellence Certificate Section */}
        <Box>
          <Typography variant="body2" fontWeight="bold" sx={{ color: '#FBC02D', mb: 1 }}>
            Excellence Certificate
          </Typography>
          <Paper elevation={0} sx={{ p: 2, borderRadius: 2, backgroundColor: '#fff9e0' }}>
            <Typography variant="body2" fontWeight="bold" sx={{ color: '#000', mb: 1 }}>
              Make sure to get a passing score in assessments.
            </Typography>
            <List disablePadding>
              <ListItem sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
                <LockIcon sx={{ color: '#A0A0A0', mr: 1 }} />
                <Typography variant="body2" sx={{ color: '#A0A0A0' }}>
                  Code And Play Assessment #1
                </Typography>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Achievement Certificate Section */}
        <Box>
          <Typography variant="body2" fontWeight="bold" sx={{ color: '#A0A0A0', mb: 1 }}>
            Achievement Certificate
          </Typography>
          <Paper elevation={0} sx={{ p: 2, borderRadius: 2, backgroundColor: '#f2f2f2' }}>
            <Typography variant="body2" fontWeight="bold" sx={{ color: '#000', mb: 1 }}>
              Be a top scorer in assessments & assignments
            </Typography>
            <Typography variant="caption" sx={{ color: '#8C30F5', display: 'block' }}>
              Only for top 100 students
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CertificateCriteria;
