import React from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const MenuToggle = ({ showMenu, handleMenuToggle }) => {
  return (
    <IconButton
      onClick={handleMenuToggle}
      sx={{
        position: 'absolute',
        top: 16,
        left: 16,
        zIndex: 2000,
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
      }}
    >
      {showMenu ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );
};

export default MenuToggle;
