import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Container, List, ListItem, Button } from '@mui/material';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    axios.get('/api/notifications')
      .then(response => setNotifications(response.data))
      .catch(error => console.error('Error fetching notifications:', error));
  }, []);

  const markAsRead = (id) => {
    axios.post(`/api/notifications/${id}/read`)
      .then(() => {
        setNotifications(notifications.map(notification => 
          notification._id === id ? { ...notification, status: 'read' } : notification
        ));
      })
      .catch(error => console.error('Error marking notification as read:', error));
  };

  return (
    <Container>
      <Typography >no notification</Typography>
      <List>
        {notifications.map(notification => (
          <ListItem key={notification._id}>
            <Typography>{notification.message}</Typography>
            {notification.status === 'unread' && (
              <Button variant="contained" color="primary" onClick={() => markAsRead(notification._id)}>
                Mark as Read
              </Button>
            )}
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Notifications;
