import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, Paper, Avatar, Button, Typography, AppBar, Toolbar } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [activeMessages, setActiveMessages] = useState([]);
  const [previousMessages, setPreviousMessages] = useState([]);
  const [predefinedDisabled, setPredefinedDisabled] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [chatClosed, setChatClosed] = useState(false);

  const predefinedMessages = [
    "I want to reschedule my class",
    "I want to discuss with the teacher",
    "Can I get some help with my homework?",
    "I'd like to know more about the course material",
  ];

  // Handle sending a message
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const newMessage = { text: inputValue, sender: 'user', timestamp: Date.now() };
      setMessages([newMessage, ...messages]);
      setInputValue('');
      setPredefinedDisabled(false);
      setConfirmationMessage("Thank you for your message, the teacher will reply soon.");
    }
  };

  // Handle selecting a predefined message
  const handlePredefinedMessage = (message) => {
    const newMessage = { text: message, sender: 'user', timestamp: Date.now() };
    setMessages([newMessage, ...messages]);
    setSelectedMessage(message);
    setPredefinedDisabled(true);
    setConfirmationMessage("Thank you for your message, the teacher will reply soon.");
  };

  // Close the chat when the teacher resolves the issue
  const handleCloseChat = () => {
    setChatClosed(true);
    setPreviousMessages([...previousMessages, ...activeMessages]); // Move active messages to previous messages
    setActiveMessages([]); // Clear active messages after closing the chat
    setConfirmationMessage("The issue has been resolved. The chat is now closed.");
  };

  // Check message expiry and categorize messages
  const checkMessageExpiry = () => {
    const now = Date.now();
    const activeMsgs = messages.filter((message) => now - message.timestamp < 24 * 60 * 60 * 1000);
    const expiredMsgs = messages.filter((message) => now - message.timestamp >= 24 * 60 * 60 * 1000);
    setActiveMessages(activeMsgs);
    setPreviousMessages(expiredMsgs);
  };

  // Set up the logic to check message expiry every minute
  useEffect(() => {
    const interval = setInterval(() => { checkMessageExpiry(); }, 60000);
    return () => clearInterval(interval);
  }, [messages]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', backgroundColor: '#f5f5f5' }}>
      {/* Chat Header */}
      {/* <AppBar position="sticky" sx={{ backgroundColor: '#1976d2' }}>
        <Toolbar>
          <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>Chat with Support</Typography>
        </Toolbar>
      </AppBar> */}

      {/* Main Content - Split into Active and Previous Chat */}
      <Box sx={{ display: 'flex', flex: 1, padding: '16px', flexDirection: 'row', overflow: 'hidden' }}>
        {/* Previous Chat */}
        <Box sx={{
          width: '35%',
          backgroundColor: '#ffffff',
          boxShadow: '0 0 8px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          padding: '16px',
          height: 'calc(100vh - 180px)', // Adjust height to take up the remaining space, excluding header and input
          overflowY: 'auto',  // Allow scrolling in previous chats section
        }}>
          <Typography variant="h6" sx={{ marginBottom: '16px', color: '#333' }}>Previous Chats</Typography>
          <Paper sx={{
            flex: 1,
            overflow: 'auto',
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '8px',
          }}>
            {previousMessages.length === 0 && <Typography variant="body2" sx={{ color: '#888' }}>No previous conversations.</Typography>}
            {previousMessages.map((message, index) => (
              <Box key={index} sx={{ marginBottom: '12px', display: 'flex', flexDirection: message.sender === 'user' ? 'row-reverse' : 'row' }}>
                <Avatar sx={{
                  bgcolor: message.sender === 'user' ? '#1976d2' : '#e0e0e0',
                  color: '#fff',
                  width: 30,
                  height: 30,
                  marginRight: message.sender === 'user' ? 0 : 2,
                  marginLeft: message.sender === 'user' ? 2 : 0,
                }}>
                  {message.sender === 'user' ? 'U' : 'S'}
                </Avatar>
                <Box sx={{
                  maxWidth: '70%',
                  backgroundColor: message.sender === 'user' ? '#1976d2' : '#e0e0e0',
                  color: message.sender === 'user' ? 'white' : 'black',
                  padding: '10px',
                  borderRadius: '10px',
                  wordWrap: 'break-word',
                }}>
                  <Typography variant="body1">{message.text}</Typography>
                </Box>
              </Box>
            ))}
          </Paper>
        </Box>

        {/* Active Chat */}
        <Box sx={{
          flex: 1,
          backgroundColor: '#ffffff',
          padding: '16px',
          boxShadow: '0 0 8px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 180px)', // Adjust height to take up the remaining space, excluding header and input
        }}>
          {/* Messages Container */}
          <Typography variant="h6" sx={{ marginBottom: '16px', color: '#333' }}>Active Chat</Typography>
          <Paper sx={{
            flex: 1,
            overflowY: 'auto',  // Allow scrolling in the active chat section
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRadius: '8px',
            marginBottom: '16px',
          }}>
            {activeMessages.map((message, index) => (
              <Box key={index} sx={{ marginBottom: '12px', display: 'flex', flexDirection: message.sender === 'user' ? 'row-reverse' : 'row' }}>
                <Avatar sx={{
                  bgcolor: message.sender === 'user' ? '#1976d2' : '#e0e0e0',
                  color: '#fff',
                  width: 30,
                  height: 30,
                  marginRight: message.sender === 'user' ? 0 : 2,
                  marginLeft: message.sender === 'user' ? 2 : 0,
                }}>
                  {message.sender === 'user' ? 'U' : 'S'}
                </Avatar>
                <Box sx={{
                  maxWidth: '70%',
                  backgroundColor: message.sender === 'user' ? '#1976d2' : '#e0e0e0',
                  color: message.sender === 'user' ? 'white' : 'black',
                  padding: '10px',
                  borderRadius: '10px',
                  wordWrap: 'break-word',
                }}>
                  <Typography variant="body1">{message.text}</Typography>
                </Box>
              </Box>
            ))}
            {selectedMessage && (
              <Box sx={{ marginBottom: '12px', display: 'flex', flexDirection: 'row-reverse' }}>
                <Avatar sx={{ bgcolor: '#1976d2', color: '#fff', width: 30, height: 30, marginRight: 2 }}>U</Avatar>
                <Box sx={{
                  maxWidth: '70%',
                  backgroundColor: '#1976d2',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '10px',
                  wordWrap: 'break-word',
                }}>
                  <Typography variant="body1">{selectedMessage}</Typography>
                </Box>
              </Box>
            )}
          </Paper>

          {/* Confirmation Message */}
          {confirmationMessage && (
            <Box sx={{
              backgroundColor: '#e0f7fa',
              padding: '10px',
              borderRadius: '8px',
              marginBottom: '16px',
              textAlign: 'center',
            }}>
              <Typography variant="body2" sx={{ color: '#00796b' }}>{confirmationMessage}</Typography>
            </Box>
          )}

          {/* Predefined Messages */}
          <Box sx={{
            marginTop: 'auto',
            padding: '16px',
            backgroundColor: '#fff',
            boxShadow: '0 0 8px rgba(0,0,0,0.1)',
            borderRadius: '8px',
          }}>
            <Typography variant="subtitle1" sx={{ marginBottom: '8px' }}>Predefined Messages</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {predefinedMessages.map((message, index) => (
                <Button key={index} variant="contained" sx={{
                  marginRight: '8px', marginBottom: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: '#1976d2',
                }} onClick={() => handlePredefinedMessage(message)} disabled={predefinedDisabled}>
                  {message}
                </Button>
              ))}
            </Box>
          </Box>

          {/* Message Input */}
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#fff', borderTop: '1px solid #ddd' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              sx={{
                marginRight: '10px',
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': { borderRadius: '8px' },
              }}
            />
            <IconButton color="primary" onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatComponent;
