import React, { useState, useEffect } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Navbar from "./Components/Navbar";
import MaintenancePopup from "./Components/MaintenancePopup";

// import { useGetUserQuery } from "state/api";

const Layout = () => {
  // Use MediaQuery to check if the screen size is non-mobile (e.g., larger than 600px)
  const isNonMobile = useMediaQuery("(min-width:960px)"); // Use the appropriate breakpoint for non-mobile

  const [isSidebarOpen, setIsSidebarOpen] = useState(); // Set default state based on screen size

  // Ensure that the sidebar is opened/closed when screen size changes
  useEffect(() => {
    setIsSidebarOpen(isNonMobile); // Open sidebar by default on desktop, close on mobile
  }, [isNonMobile]);

  // Uncomment when you have the user data logic in place
  // const { data } = useGetUserQuery(userId);

  // console.log(data);
  const [isPopupOpen, setIsPopupOpen] = useState(true); // Initially, the popup is shown

  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup when button is clicked
  };

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"  // Use 100vh to make sure the full viewport height is used
      sx={{
        overflow: 'hidden',  // Prevent outer scrollbar from showing
      }}
    >
      {/* Sidebar: Only show on non-mobile view */}
      <Sidebar
        user={"user"}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: 'auto',  // Only allow vertical scrolling for the main content area
        }}
      >
        <Navbar
          user={"user"}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Box sx={{ padding: '16px' }}> {/* Add padding for better layout */}
          <Outlet />
    
      {/* Show the popup when isPopupOpen is true */}
      <MaintenancePopup isOpen={isPopupOpen} onClose={closePopup} />

        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
