import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  Avatar,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  ChevronLeft,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { oratricslogo } from "../Assets/index";
import {
  HomeOutlined,
  PersonOutline,
  BookOutlined,
  AssessmentOutlined,
  ClassOutlined,
  CheckCircleOutline,
  StarOutline,
  HistoryOutlined,
  Logout,AssignmentOutlined
} from "@mui/icons-material";
const { styled } = require("@mui/system");

const FlexBetween = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const menuItems = [
  { text: "Dashboard", icon: <HomeOutlined />, path: "/" },
  { text: "Class Summary", icon: <PersonOutline />, path: "/classsummary" },
  { text: "Attendance History", icon: <HistoryOutlined />, path: "/history" },

  { text: "My Curriculum", icon: <BookOutlined />, path: "/curriculum" },
  { text: "Student Daily Performance", icon: <AssessmentOutlined />, path: "/performance" },
  {
    text: "Activity Classes",
    icon: <BookOutlined />,
    submenu: [
      { text: "Activity Class", path: "/activityclass" },
      { text: "Saved Activity Classes", path: "/viewnotes" }
    ]
  },
  { text: "Homework", icon: <AssignmentOutlined />, path: "/homework" },  // Updated Homework icon
  { text: "Achievements", icon: <ClassOutlined />, path: "/achievements" },
  { text: "Assessments", icon: <CheckCircleOutline />, path: "/assessments" },
  { text: "My Rewards", icon: <StarOutline />, path: "/reward" },
];

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSubMenu, setOpenSubMenu] = useState({});
  const [userName, setUserName] = useState("");
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false); // State to control logout dialog visibility

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserName(user.name || "User");
    }
  };

  useEffect(() => {
    fetchUserDetails(); // Fetch user details from local storage
  }, []);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleSubMenuClick = (text) => {
    setOpenSubMenu((prevOpenSubMenu) => ({
      ...prevOpenSubMenu,
      [text]: !prevOpenSubMenu[text],
    }));
  };

  const handleMenuItemClick = (path, hasSubMenu, text) => {
    if (!hasSubMenu) {
      navigate(path);
      if (!isNonMobile) {
        setIsSidebarOpen(false);
      }
    } else {
      handleSubMenuClick(text);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate("/login");
    setOpenLogoutDialog(false); // Close dialog
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "16px",
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
              transition: "width 0.3s ease-in-out",
              paddingTop: 2,
            },
          }}
        >
          <Box width="100%">
            {/* Logo Section */}
            <FlexBetween p={2}>
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <img src={oratricslogo} alt="Oratrics Logo" style={{ height: '55px' }} />
              </Box>
              {!isNonMobile && (
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                  <ChevronLeft />
                </IconButton>
              )}
            </FlexBetween>

            <Divider sx={{ my: 2, borderColor: theme.palette.primary[200] }} />

            <List sx={{ flexGrow: 1 }}>
              {menuItems.map(({ text, icon, path, submenu }) => (
                <Box key={text}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => handleMenuItemClick(path, !!submenu, text)} // Pass `text` for submenu toggle
                      sx={{
                        borderRadius: "8px",
                        mx: 2,
                        my: 1,
                        backgroundColor: pathname === path ? theme.palette.primary.main : "transparent",
                        color: pathname === path ? "#fff" : theme.palette.text.primary,
                        transition: "0.3s",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: pathname === path ? "#fff" : theme.palette.text.secondary }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {submenu ? (openSubMenu[text] ? <ExpandLess /> : <ExpandMore />) : null}
                    </ListItemButton>
                  </ListItem>
                  {submenu && (
                    <Collapse in={openSubMenu[text]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {submenu.map((subItem) => (
                          <ListItemButton
                            key={subItem.text}
                            onClick={() => handleMenuItemClick(subItem.path)}
                            sx={{
                              pl: 4,
                              mx: 2,
                              my: 1,
                              backgroundColor: pathname === subItem.path ? theme.palette.primary.light : "transparent",
                              color: pathname === subItem.path ? "#fff" : theme.palette.text.primary,
                              transition: "0.3s",
                              "&:hover": {
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.dark,
                              },
                            }}
                          >
                            <ListItemText primary={subItem.text} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Box>
              ))}
            </List>

            <Divider />

            {/* User Profile & Logout */}
            <Box sx={{ p: 2, display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar sx={{ bgcolor: theme.palette.primary.main, fontSize: '1.25rem' }}>
                {userName.charAt(0)}
              </Avatar>
              <Box>
                <Typography variant="body1" fontWeight={600}>{userName}</Typography>
                <Typography variant="body2" color="textSecondary">Learner</Typography>
                {new Date().toLocaleDateString()}
              </Box>
              <Tooltip title="Logout">
                <IconButton color="error" onClick={() => setOpenLogoutDialog(true)}>
                  <Logout />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Drawer>
      )}

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={openLogoutDialog}
        onClose={() => setOpenLogoutDialog(false)}
        aria-labelledby="logout-confirmation-dialog"
      >
        <DialogTitle id="logout-confirmation-dialog">Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to log out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLogoutDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleLogout} color="error">Logout</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
