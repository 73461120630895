import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorGif from '../Assets/6339704.jpg';
import { ErrorOutline } from '@mui/icons-material'; // Optional: You can use a material icon for more style.

const NotFound404 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        m: 2,
        p: 2,
        height: '100vh',
        // background: 'linear-gradient(135deg, #ff8a00, #e52e71)', // Gradient background
        color: '#fff', // Text color
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
        //   backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light card background
          maxWidth: '500px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 3, // Margin bottom to space out image from the text
          }}
        >
          <img
            src={ErrorGif}
            alt='error'
            style={{
              width: '60%', // Make the image larger
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Box>

        <Typography variant="h4" color="primary" fontWeight="bold" mb={2}>
          Oops! Something Went Wrong
        </Typography>

        <Typography variant="body1" mb={3}>
          The requested resource does not exist. We couldn't find the page you were looking for.
        </Typography>

        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" size="large" sx={{ borderRadius: 20 }}>
            Back to Home
          </Button>
        </Link>
      </Paper>

      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ErrorOutline sx={{ fontSize: 50, color: 'white', mr: 1 }} />
        <Typography variant="body2" color="white">404 Error - Page Not Found</Typography>
      </Box>
    </Box>
  );
};

export default NotFound404;
