import React from 'react';
import { Box, Card, CardContent, Typography, Grid, Avatar, Button, IconButton, TextField } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { trophy, trophyone } from '../Assets';

const rewards = [
  { title: '5 Free Classes', gems: 5, image: '/path-to-image1', referrals: 0 },
  { title: '5 Maths Classes', gems: 10, image: '/path-to-image2', referrals: 0 },
  { title: '8 Free Classes', gems: 10, image: '/path-to-image1', referrals: 0 },
  { title: '20 % off on Next Course', gems: 20, image: '/path-to-image3', referrals: 1 },
  { title: 'Amazon Voucher worth $200', gems: 30, image: '/path-to-image4', referrals: 2 },
  { title: 'AirPods Pro', gems: 40, image: '/path-to-image5', referrals: 3 },
  { title: 'PlayStation 5', gems: 50, image: '/path-to-image5', referrals: 3 },

];

const Reward = () => {
  return (
    <>
    <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          overflowY: 'auto',
          position: 'relative',
          backgroundColor: '#bac2ff',
          // backgroundImage:`url(${trophyone})`,
          // backgroundSize:'cover',          // Ensure positioning context for sidebar
        }}
      >    {/* Header Section */}
      <Typography variant="h6" sx={{ color: '#6d6d81', fontWeight: 'bold', marginBottom: '20px' }}>
        My Rewards
      </Typography>
      <Typography variant="body2" sx={{ color: '#6d6d81', marginBottom: '20px' }}>
        Refer to earn gems and redeem your rewards
      </Typography>

      {/* Referral Section */}
      <Card sx={{ padding: '20px', marginBottom: '40px', borderRadius: '16px',    width: {
      xs: '100%', // 100% width on extra-small (xs) screens
      sm: '100%', // 100% width on small (sm) screens
      md: '70%',  // 60% width on medium (md) screens
    }}}>
        <Typography variant="h6" sx={{ marginBottom: '10px', color: '#6d6d81' }}>
          You have <span style={{ color: '#050a30' }}>0 successful referral</span>. Refer more to win your dream rewards
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '20px', color: '#6d6d81' }}>
          You have referred 1 friends. Refer again to earn 10 more gems.
        </Typography>

        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              label="Copy and share your referral link with friends"
              value="https://orattrics...Copy Link"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <LinkIcon />
                  </IconButton>
                ),
              }}
              sx={{ marginBottom: '10px' }}
            />
          </Grid>
        
        </Grid>
        <Typography variant="body2" sx={{ marginTop: '20px', color: '#6d6d81' }}>
          rohan from India has won a PlayStation 5 by referring 7 friends.
         
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '20px', color: '#6d6d81' }}>
          <Button variant="outlined" sx={{ marginLeft: '10px', color: '#050a30', borderColor: '#050a30' }}>
            Refer Now
          </Button>
          <Button variant="outlined" sx={{ marginLeft: '10px', color: '#050a30', borderColor: '#050a30' }} startIcon={<ShareIcon />}>
              Share
            </Button>
        </Typography>
     
      </Card>

      {/* Rewards Section */}
      <Typography variant="h6" sx={{ marginBottom: '20px', color: '#6d6d81', fontWeight: 'bold' }}>
        Redeem Your Gems
      </Typography>

      <Grid container spacing={5}>
        {rewards.map((reward, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ padding: '20px', textAlign: 'center', borderRadius: '16px' }}>
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    width: 56,
                    height: 56,
                    backgroundImage: `url(${reward.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
                <Typography variant="body1" sx={{ marginTop: '10px', fontWeight: 'bold', color: '#6d6d81' }}>
                  {reward.title}
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: '10px',
                    borderColor: '#050a30',
                    color: '#050a30',
                  }}
                >
                  Redeem for {reward.gems} <LockIcon sx={{ fontSize: 16, marginLeft: '5px' }} />
                </Button>
                {/* {reward.referrals > 0 && (
                  <Typography variant="body2" sx={{ marginTop: '10px', color: '#6d6d81' }}>
                    {reward.referrals} more referrals required to earn {reward.gems} gems for redeeming this.
                  </Typography>
                )} */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </>
  );
};

export default Reward;
