import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography, Chip, Box, LinearProgress } from '@mui/material';
import { AccessTime, Feedback, CheckCircle, ErrorOutline } from '@mui/icons-material';
import baseUrl from '../config';
import SidebarRight from "./SideScreen";

const DailyPerformance = () => {
  const [performanceData, setPerformanceData] = useState([]);

  // Fetch performance data on page load
  useEffect(() => {
    const newtestperformance = async () => {
      try {
        const response = await fetch(`${baseUrl}api/students/performance`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });
        const data = await response.json();
        console.log(data,"datadatadatadata");
        
        if (data.performances && data.performances.length > 0) {
          setPerformanceData(data.performances);
        } else {
          setPerformanceData([]);
        }
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };

    newtestperformance();
  }, []);

  return (
    <Box 
    display="flex"  
    height={{ md: '100vh' }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
    <Box sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      background: 'linear-gradient(135deg, #e0f7fa, #80deea)',
      borderRadius: 4,
      boxShadow: 3,
      overflowY: 'auto', // Enable scrolling for this Box
    }}>
      {/* Beautiful Headline with styling */}
      <Typography 
        variant="h4" 
        gutterBottom 
        align="center" 
        sx={{ 
          fontWeight: 700, 
          color: '#00796b', 
          textTransform: 'uppercase', 
          letterSpacing: 1, 
          marginBottom: 4 
        }}>
        Daily Student Performance
      </Typography>

      <Grid container spacing={4}>
        {performanceData.length > 0 ? (
          performanceData.map((performance) => (
            <Grid item xs={12} sm={6} md={4} key={performance._id}>
              <Card sx={{ 
                boxShadow: 5, 
                borderRadius: 3, 
                transition: 'transform 0.3s ease, box-shadow 0.3s', 
                '&:hover': { 
                  transform: 'scale(1.05)', 
                  boxShadow: 10 
                } // Card hover effect to make it interactive
              }}>
                <CardHeader
                  title={`Performance for ${performance.student?.name || 'Unknown Student'}`}
                  subheader={new Date(performance.date).toLocaleDateString()}
                  action={<Chip label={performance.status} color="success" />}
                  sx={{ 
                    backgroundColor: '#00796b', 
                    color: 'white', 
                    borderRadius: '8px 8px 0 0', 
                    padding: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}
                />
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <AccessTime sx={{ marginRight: 1 }} />
                    <Typography variant="body2" color="textSecondary">
                      Attended at: {new Date(performance.date).toLocaleTimeString()}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Feedback sx={{ marginRight: 1 }} />
                    <Typography variant="body2">{performance.feedback || 'No feedback provided'}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <CheckCircle sx={{ marginRight: 1, color: performance.score >= 50 ? 'green' : 'red' }} />
                    <Typography variant="body2">
                      Score: {performance.score} / 100
                    </Typography>
                  </Box>

                  {/* Progress bar for performance score */}
                  <LinearProgress
                    variant="determinate"
                    value={performance.score}
                    sx={{
                      marginTop: 2,
                      borderRadius: 2,
                      height: 6,
                      backgroundColor: performance.score >= 50 ? '#81C784' : '#FF8A80',
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No performance data available
          </Typography>
        )}
      </Grid>
    </Box>
    <SidebarRight/>

    </Box>
  );
};

export default DailyPerformance;
