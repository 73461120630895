import Login from "./Login";
import Register from "./Register";
import DashboardPage from "./DashboardPage";
import ContentManagement from "./ContentManagement";
import HomeService from "./HomeService";
import MarketPlace from "./MarketPlcae";
import Settings from "./Settings";
import ShowcaseManagement from "./ShowcaseManagement";

export {
  Login,
  Register,
  DashboardPage,
  ContentManagement,
  HomeService,
  MarketPlace,
  Settings,
  ShowcaseManagement,
};
