import React, { useState, useEffect } from 'react';
    import {
        Container,
        Typography,
        List,
        ListItem,
        ListItemText,
        Button,
        TextField,
        Card,
        CardContent,
        Grid,
        IconButton,
        Tooltip,
        LinearProgress,
        Paper, // Import Paper for "No Homework" message
        Box,

    } from '@mui/material';
    import { styled } from '@mui/material/styles';
    import axios from 'axios';
    import baseUrl from '../config';
    import AssignmentIcon from '@mui/icons-material/Assignment';
    import EventIcon from '@mui/icons-material/Event';
    import CloudUploadIcon from '@mui/icons-material/CloudUpload';
    import SendIcon from '@mui/icons-material/Send';
    import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
    import BookIcon from '@mui/icons-material/Book'; // Icon for "No Homework" message
    import ListItemIcon from '@mui/material/ListItem';

import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
    // Styled Components for visual enhancements
    const HomeworkCard = styled(Card)(({ theme }) => ({
        marginBottom: theme.spacing(2),
        borderRadius: 12,
        boxShadow: '0 3px 7px rgba(0,0,0,0.07)', // Slightly stronger base shadow
        '&:hover': {
            boxShadow: '0 5px 10px rgba(0,0,0,0.1)', // Enhanced hover shadow
            transform: 'translateY(-3px)', // Slightly more pronounced lift on hover
        },
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        borderColor: '#e0e0e0', // Light grey border for subtle definition
        borderWidth: '1px',
        borderStyle: 'solid',
    }));

    const CardHeader = styled(Box)(({ theme }) => ({
        padding: theme.spacing(2),
        backgroundColor: '#f3f7fa', // Softer, very light blue header background
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: 12, // Match card borderRadius
        borderTopRightRadius: 12, // Match card borderRadius
    }));

    const CardContentArea = styled(CardContent)(({ theme }) => ({
        padding: theme.spacing(3), // Slightly increased padding inside card content
    }));

    const SubmitButton = styled(Button)(({ theme }) => ({
        marginTop: theme.spacing(3), // More vertical space above submit button
        padding: theme.spacing(1.5, 3.5), // Slightly wider submit button
        borderRadius: 10, // More rounded submit button
        fontWeight: 600, // Slightly bolder font for submit button
        backgroundColor: '#43a047', // A bit darker, more vibrant green
        color: 'white',
        '&:hover': {
            backgroundColor: '#388e3c',
            boxShadow: theme.shadows[3], // Add subtle shadow on hover for button
        },
    }));

    const FileInput = styled('input')({
        display: 'none', // Hidden input
    });

    const UploadButton = styled(Button)(({ theme }) => ({
        margin: theme.spacing(1, 0, 2, 0), // Added bottom margin for spacing
        backgroundColor: '#64b5f6', // Light blue upload button
        color: 'white',
        padding: theme.spacing(1, 2.5), // Adjusted padding for upload button
        borderRadius: 8,
        '&:hover': {
            backgroundColor: '#42a5f5',
            boxShadow: theme.shadows[2], // Add subtle shadow on hover for button
        },
    }));

    const NoHomeworkPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(4),
        borderRadius: 12,
        boxShadow: theme.shadows[2],
        backgroundColor: '#fafafa', // Very light grey background for no homework message
        textAlign: 'center',
    }));


    const StudentHomeworkList = () => {
        const navigate = useNavigate();
        const [homework, setHomework] = useState([]);
        const [submission, setSubmission] = useState({ homeworkId: '', comments: '', file: null, fileName: '' });
        const [uploadProgress, setUploadProgress] = useState(0);

        useEffect(() => {
            const fetchHomework = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(baseUrl + 'api/students/student', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setHomework(response.data.homework);
                } catch (error) {
                    console.error('Error fetching homework:', error);
                }
            };
            fetchHomework();
        }, []);

        const handleFileChange = (e) => {
            const selectedFile = e.target.files[0];
            console.log(selectedFile);
            setSubmission({ ...submission, file: selectedFile, fileName: selectedFile ? selectedFile.name : '' });
            setUploadProgress(0);
        };


        const handleSubmitHomework = async () => {
            const formData = new FormData();
            formData.append('homeworkId', submission.homeworkId);
            formData.append('comments', submission.comments);
            formData.append('file', submission.file);

            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(baseUrl + 'api/students/submit', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(progress);
                    },
                });
                alert('Homework submitted successfully!');
                setSubmission({ homeworkId: '', comments: '', file: null, fileName: '' });
                setUploadProgress(0);
            } catch (error) {
                console.error('Error submitting homework:', error);
                setUploadProgress(0);
            }
        };

        return (
            <Box sx={{ position: "relative", p: 2 }}>
                         
                         <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333',
          
          fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text






           }}>
          My Assignments
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
          Future Learning Pathways
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>

                {homework.length === 0 ? (
                    <NoHomeworkPaper>
                        <BookIcon color="primary" style={{ fontSize: 60, marginBottom: '20px' }} />
                        <Typography variant="h6"  style={{ fontWeight: 500, color: '#555' }}>
                            No homework assigned yet.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                            Check back later for new assignments.
                        </Typography>
                    </NoHomeworkPaper>
                ) : (
                    <Grid container spacing={3} style={{ marginTop: '20px' }}>
                        {homework.map((hw) => (
                            <Grid item xs={12} md={6} lg={4} key={hw._id}>
                                <HomeworkCard>
                                    <CardHeader>
                                        <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center', color: '#1a237e', fontWeight: 500 }}>
                                            <AssignmentIcon style={{ marginRight: '8px', fontSize: '28px' }} color="primary" /> {hw.title}
                                        </Typography>
                                    </CardHeader>
                                    <CardContentArea>
                                        <List dense>
                                            <ListItem disableGutters>
                                                <ListItemIcon><EventIcon color="action" /></ListItemIcon>
                                                <ListItemText primary={`Due Date: ${new Date(hw.dueDate).toLocaleDateString()}`} />
                                            </ListItem>
                                        </List>

                                        <TextField
                                            label="Comments"
                                            multiline
                                            rows={2}
                                            placeholder="Add your comments or questions here" // Improved placeholder
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={hw._id === submission.homeworkId ? submission.comments : ''}
                                            onChange={(e) =>
                                                setSubmission({ ...submission, homeworkId: hw._id, comments: e.target.value })
                                            }
                                        />

                                        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                                            <label htmlFor={`file-upload-${hw._id}`}>
                                                <FileInput
                                                    id={`file-upload-${hw._id}`}
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                                <UploadButton
                                                    component="span"
                                                    variant="contained"
                                                    startIcon={<CloudUploadIcon />}
                                                >
                                                    Upload File
                                                </UploadButton>
                                            </label>
                                            {submission.fileName && (
                                                <Box display="flex" alignItems="center" mt={1}>
                                                    <InsertDriveFileIcon color="secondary" style={{ marginRight: '5px' }} />
                                                    <Typography variant="body2" style={{ color: '#444' }}>{submission.fileName}</Typography>
                                                </Box>
                                            )}
                                            {uploadProgress > 0 && uploadProgress < 100 && (
                                                <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '100%', marginTop: '8px' }} />
                                            )}
                                            <SubmitButton
                                                variant="contained"
                                                endIcon={<SendIcon />}
                                                onClick={handleSubmitHomework}
                                                disabled={hw._id !== submission.homeworkId}
                                            >
                                                Submit Homework
                                            </SubmitButton>
                                        </Box>
                                    </CardContentArea>
                                </HomeworkCard>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        );
    };

    export default StudentHomeworkList;