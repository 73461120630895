import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import ClassSummery from "../Components/ClassSummery";
import SideScreen from "../Components/SideScreen";

const ClassSummeryPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
   
   <ClassSummery/>   
   {/* <SideScreen/> */}

    {/* <Box
      component={SideScreen}
      open={sidebarOpen}
      onClose={handleSidebarToggle}
     
    /> */}
  </Box>
  );
};

export default ClassSummeryPage;
