import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  border: '2px solid #d3d3d3',
  color: 'black',
  padding: '8px 32px',
  textTransform: 'none',
  position: 'relative',
  '&:before, &:after': {
    content: '"•"',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    color: 'black',
  },
  '&:before': {
    left: '10px',
  },
  '&:after': {
    right: '10px',
  },
  '& .MuiButton-label': {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CardComponent = ({ 
  imageUrl, 
  title, 
  description, 
  duration, 
  enrollments, 
  teacher, 
  pdfUrl, 
  startDate, 
  bookedClasses, 
  progress, 
  completed,
  joinUrl // Added joinUrl prop
}) => {
  const navigate = useNavigate();

  const handleViewPdf = () => {
    navigate('/view-pdf', { state: { fileUrl: pdfUrl } });
  };

  const progressPercentage = bookedClasses > 0
    ? (progress / bookedClasses) * 100
    : 0;

  const isJoinDisabled = bookedClasses === progress; // Check if join should be disabled

  return (
    <Card
      sx={{
        borderRadius: '16px',
        minHeight: '350px',
        '&:hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        },
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      }}
    >
      <CardContent>
        <img
          src={imageUrl}
          alt={title}
          style={{ width: '100%', height: '180px', objectFit: 'cover', borderRadius: '16px' }}
        />
        <Typography variant="subtitle1" sx={{ mt: 2 }}>{title}</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>{description}</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          <AccessTimeIcon sx={{ fontSize: 16, verticalAlign: 'middle', mr: 1, color: 'black' }} />
          Duration: {duration} minutes
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Total Classes: {bookedClasses}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Teacher: {teacher || 'Not Assigned'}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Start Date: {startDate ? new Date(startDate).toLocaleDateString() : 'N/A'}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Progress: {progressPercentage.toFixed(2)}%
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Completed: {completed ? 'Yes' : 'No'}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {pdfUrl && (
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={handleViewPdf}
              sx={{ mt: 2, mb: 2 }}
            >
              View Course Material
            </StyledButton>
          )}
          {joinUrl && ( // Render Join Class button if joinUrl exists
            <StyledButton
              variant="contained"
              color="secondary"
              href={!isJoinDisabled ? joinUrl : undefined} // Link to join class or undefined if disabled
              target="_blank"
              sx={{ mt: 2, opacity: isJoinDisabled ? 0.5 : 1, pointerEvents: isJoinDisabled ? 'none' : 'auto' }} // Disable interaction if needed
            >
              Join Class
            </StyledButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
