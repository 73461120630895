import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Divider,
  IconButton,
  Chip,
  Tooltip,
  Modal,
  TextField,
  Stack,
  Snackbar,
  Alert,Skeleton
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { studentong } from '../Assets';
import baseUrl from '../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const ClassCard = ({
  classData,
  classNumber,
  isPastClass,
  isNextClass,
  handleRescheduleClick,
  toggleExpand,
  expandedScheduleId,
}) => {
  const isClassOver = isPastClass;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 2000); // Adjust the time as needed
    return () => clearTimeout(timer);
  }, []);

  const isLoading = !classData || !loaded; // Check if data is loading

  if (isLoading) {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Card
          sx={{
            borderRadius: 5,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            overflow: 'hidden',
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={240} />
          <CardContent>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="40%" height={24} />
            <Skeleton variant="text" width="80%" height={24} />
            <Skeleton variant="text" width="50%" height={24} />
            <Skeleton variant="circular" width={40} height={40} />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        sx={{
          borderRadius: 5,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-5px)',
          },
          position: 'relative',
          pointerEvents: isClassOver ? 'none' : 'auto',
          background: 'linear-gradient(135deg, #ffffff 0%, #f9fafb 100%)',
          overflow: 'hidden',
        }}
      >
        {/* Chip Section */}
        <Box sx={{ position: 'absolute', top: 12, right: 12, zIndex: 1 }}>
          <Chip
            label={isNextClass ? 'Next Class' : isClassOver ? 'Completed' : 'Upcoming'}
            color={isNextClass ? 'success' : isClassOver ? 'default' : 'info'}
            sx={{
              fontWeight: 600,
              fontSize: '0.75rem',
              padding: '4px 12px',
              borderRadius: 10,
              backgroundColor: isNextClass ? '#10b981' : isClassOver ? '#ac2727' : '#3b82f6',
              color: '#fff',
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
            }}
          />
        </Box>

        {/* Image Section */}
        <Box
          component="img"
          src={classData.image || studentong} // Placeholder image if loading
          alt={classData.title}
          sx={{
            width: '100%',
            height: '240px',
            objectFit: 'contain',
            borderRadius: '16px 16px 0 0',
            backgroundColor: '#f1f5f9',
          }}
        />

        {/* Content Section */}
        <CardContent sx={{ padding: '24px', pb: '16px !important' }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: '#1f2937',
              marginBottom: 1,
              fontFamily: '"Roboto", "Helvetica", sans-serif',
              fontSize: '1.125rem',
            }}
          >
            <strong>{classData.topics?.[0]?.title || 'Class Title'}</strong>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: '#1f2937',
              marginBottom: 3,
              fontFamily: '"Roboto", "Helvetica", sans-serif',
              fontSize: '1.125rem',
              letterSpacing: '0.5px',
              lineHeight: 1.5,
              textTransform: 'capitalize',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <strong
              style={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: '#3b82f6',
                letterSpacing: '0.1px',
              }}
            >
              {new Date(classData.classDate).toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </strong>
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={7}>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 1.5,
                  color: '#4b5563',
                  fontSize: '0.875rem',
                }}
              >
                <PersonIcon sx={{ marginRight: 1, color: '#6b7280', fontSize: 18 }} />
                Class No: {classNumber}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 2,
                  color: '#4b5563',
                  fontSize: '0.875rem',
                }}
              >
                <AccessTimeIcon sx={{ marginRight: 1, color: '#6b7280', fontSize: 18 }} />
                Time: {classData.time}
              </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        src={classData.teacherAvatar}
        sx={{
          width: 40,
          height: 40,
          marginRight: 2,
          border: '2px solid #e5e7eb',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        }}
      />
      
      <Tooltip title={classData.teacherName ? `Teacher: ${classData.teacherName}` : 'Unknown Teacher'}>
  <div>
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 600,
        color: '#1f2937',
        textTransform: 'capitalize',
        fontSize: '1rem',
        cursor: 'pointer', // Optional: to indicate it's interactable
        marginBottom: '4px', // Adds space between the two Typography components
      }}
    >
      {classData.teacherName || 'Unknown Teacher'}
    </Typography>
    <Typography
      variant="subtitle2"
      sx={{
        color: '#aaa',
        textTransform: 'uppercase',
        fontSize: '.8rem',
        cursor: 'pointer',
        marginTop: '0', // Removes any default top margin
      }}
    >
      teacher
    </Typography>
  </div>
</Tooltip>

    </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 1.5,
                flexWrap: 'wrap',
              }}
            >
              <a href={classData.joinUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="contained"
                  sx={{
                    // backgroundColor: '#1d4ed8',
                    color: '#fff',
                    textTransform: 'none',
                    padding: '8px 20px',
                    fontWeight: 600,
                    fontSize: isNextClass ? '0.875rem' : '0.75rem',
                    borderRadius: 12,
                    minWidth: '120px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    cursor: isClassOver ? 'not-allowed' : 'pointer',
                    '&:hover': {
                      backgroundColor: isClassOver ? '#1d4ed8' : '#1e40af',
                      boxShadow: isClassOver ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                  disabled={isClassOver}
                >
                  {isNextClass
                    ? 'Join'
                    : isClassOver
                    ? 'Class Completed'
                    : 'Want to Join Early?'}
                  {(isNextClass || !isClassOver) && <LinkIcon sx={{ marginLeft: 1, fontSize: 18 }} />}
                </Button>
              </a>

              {classData.isRescheduleRequested ? (
                <IconButton
                  onClick={() => toggleExpand(classData._id)}
                  sx={{
                    color: '#1d4ed8',
                    padding: 1,
                    border: '1px solid #e5e7eb',
                    borderRadius: 12,
                    '&:hover': {
                      backgroundColor: '#f1f5f9',
                    },
                  }}
                >
                  {expandedScheduleId === classData._id ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleRescheduleClick(classData)}
                  disabled={isClassOver}
                  sx={{
                    backgroundColor: '#ffffff',
                    // color: '#1e3a8a',
                    // borderColor: '#1e3a8a',
                    textTransform: 'none',
                    padding: '8px 20px',
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    borderRadius: 12,
                    minWidth: '100px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: '#dbeafe',
                      color: '#fff',
                      borderColor: '#1d4ed8',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  Reschedule
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 18 }} />
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};


const ClassSummary = () => {
  const [classesData, setClassesData] = useState({
    upcomingClasses: [],
    pastClasses: [],
    nextClass: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [newDate, setNewDate] = useState(new Date());
  const [expandedScheduleId, setExpandedScheduleId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchClassSchedules = async () => {
      try {
        const response = await fetch(`${baseUrl}api/students/enrolled-courses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        
        const { upcomingClasses = [], pastClasses = [], nextClass = null } = data[0]?.course || {};
        console.log(pastClasses,"upcomingClassesupcomingClasses");
        
        setClassesData({
          upcomingClasses,
          pastClasses,
          nextClass,
        });
      } catch (err) {
        setError(err?.message || 'An error occurred while fetching schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchClassSchedules();
  }, []);

  const toggleExpand = (scheduleId) => {
    setExpandedScheduleId(expandedScheduleId === scheduleId ? null : scheduleId);
  };

  const handleRescheduleClick = (classInfo) => {
    setSelectedSchedule(classInfo);
    setOpenModal(true);
  };

  const handleRescheduleSubmit = async () => {
    if (!selectedSchedule) return;

    try {
      const { enrollmentId, _id: classScheduleId } = selectedSchedule;
      const response = await axios.post(
        `${baseUrl}api/students/reschedule-request`,
        { enrollmentId, classScheduleId, newDate },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      console.log('Reschedule successful:', response.data);
      setOpenSnackbar(true);
      setOpenModal(false);
    } catch (error) {
      console.error('Error submitting reschedule request:', error);
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => setOpenSnackbar(false);

  if (loading) return (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );

  if (error) return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  const { upcomingClasses, pastClasses, nextClass } = classesData;

  // Calculate class numbers
  const nextClassNumber = pastClasses.length + 1;
  const upcomingClassNumbers = Array.from({ length: upcomingClasses.length }, (_, i) => nextClassNumber + i + 1);

  return (
    <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      background: 'linear-gradient(135deg, #F8F8F8, #ffffff)',
      borderRadius: 4,
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)', // Softer shadow for professionalism
      overflowY: 'auto',
      position: 'relative',
      minHeight: '100vh',
    }}
  >

   
<Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333',
          
          fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text






           }}>
Class Summary          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
          Summary of Key Topics          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
      sx={{
        backgroundColor: '#e0f2fe',
        padding: 2,
        borderRadius: 2,
        textAlign: 'center',
        mb: 4,
        fontSize: '1.1rem',
        fontWeight: 500,
        color: '#0c4a6e',
      }}
    >
      Pending Classes: {upcomingClasses.length +  pastClasses.length} 
    </Box>
      </Box>
    {/* Total Classes Box */}
  

  

    {/* Next Class Section */}
    <Typography
      variant="h5"
      sx={{ fontWeight: 500, mb: 3,ml:2

, fontSize: {
  xs: "1.5rem", // Smaller font size for mobile
},
lineHeight: {
  xs: 1.2, // Tighter line height for mobile
  md: 1.3, // Slightly looser for desktop
},
fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
textTransform: "uppercase", // Uppercase text for a bold look
letterSpacing: {
  xs: "0.05em", // Slightly tighter letter spacing for mobile
  md: "0.1em", // Wider letter spacing for desktop
},
background: "linear-gradient(45deg, #111,rgb(101, 128, 246))", // Gradient background
WebkitBackgroundClip: "text", // Clip background to text
WebkitTextFillColor: "transparent", // Make text transparent to show gradient
display: "inline-block", // Ensure gradient applies only to text
mb: 2, // Margin bottom

       }} // Softer color for professionalism
    >
      Next Class (No: {nextClassNumber})
    </Typography>
    <Grid container spacing={4} sx={{ mb: 6 }}>
      {nextClass ? (
        <ClassCard
          classData={nextClass}
          classNumber={nextClassNumber}
          isPastClass={false}
          isNextClass={true}
          handleRescheduleClick={handleRescheduleClick}
          toggleExpand={toggleExpand}
          expandedScheduleId={expandedScheduleId}
        />
      ) : (
        <Typography
          variant="h6"
          color="text.secondary"
          align="center"
          sx={{ width: '100%', py: 2 }}
        >
          No Upcoming Classes
        </Typography>
      )}
    </Grid>

    {/* Upcoming Classes Section */}
    <Typography
      variant="h5"
      sx={{ fontWeight: 500, mb: 3 ,ml:2

        , fontSize: {
          xs: "1.5rem", // Smaller font size for mobile
        },
        lineHeight: {
          xs: 1.2, // Tighter line height for mobile
          md: 1.3, // Slightly looser for desktop
        },
        fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
        textTransform: "uppercase", // Uppercase text for a bold look
        letterSpacing: {
          xs: "0.05em", // Slightly tighter letter spacing for mobile
          md: "0.1em", // Wider letter spacing for desktop
        },
        background: "linear-gradient(45deg, #111,rgb(101, 128, 246))", // Gradient background
        WebkitBackgroundClip: "text", // Clip background to text
        WebkitTextFillColor: "transparent", // Make text transparent to show gradient
        display: "inline-block", // Ensure gradient applies only to text
        mb: 2, // Margin bottom
         }}
    >
      Upcoming Classes
    </Typography>
    <Grid container spacing={4} sx={{ mb: 6 }}>
      {upcomingClasses.length > 0 ? (
        upcomingClasses.map((classData, index) => (
          <ClassCard
            key={index}
            classData={classData}
            classNumber={upcomingClassNumbers[index]}
            isPastClass={false}
            handleRescheduleClick={handleRescheduleClick}
            toggleExpand={toggleExpand}
            expandedScheduleId={expandedScheduleId}
          />
        ))
      ) : (
        <Typography
          variant="h6"
          color="text.secondary"
          align="center"
          sx={{ width: '100%', py: 2 }}
        >
          No Upcoming Classes
        </Typography>
      )}
    </Grid>

    {/* Past Classes Section */}
    <Typography
      variant="h5"
      sx={{ fontWeight: 500, mb: 3,ml:2

, fontSize: {
  xs: "1.5rem", // Smaller font size for mobile
},
lineHeight: {
  xs: 1.2, // Tighter line height for mobile
  md: 1.3, // Slightly looser for desktop
},
fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
textTransform: "uppercase", // Uppercase text for a bold look
letterSpacing: {
  xs: "0.05em", // Slightly tighter letter spacing for mobile
  md: "0.1em", // Wider letter spacing for desktop
},
background: "linear-gradient(45deg, #111,rgb(101, 128, 246))", // Gradient background
WebkitBackgroundClip: "text", // Clip background to text
WebkitTextFillColor: "transparent", // Make text transparent to show gradient
display: "inline-block", // Ensure gradient applies only to text
mb: 2, // Margin bottom
 }}
    >
      Past Classes
    </Typography>
    <Grid container spacing={4}>
      {pastClasses.length > 0 ? (
        pastClasses.map((classData, index) => (
          <ClassCard
            key={index}
            classData={classData}
            classNumber={index + 1}
            isPastClass={true}
            handleRescheduleClick={handleRescheduleClick}
            toggleExpand={toggleExpand}
            expandedScheduleId={expandedScheduleId}
          />
        ))
      ) : (
        <Typography
          variant="h6"
          color="text.secondary"
          align="center"
          sx={{ width: '100%', py: 2 }}
        >
          No Past Classes
        </Typography>
      )}
    </Grid>

    {/* Reschedule Modal */}
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          bgcolor: '#ffffff',
          boxShadow: '0 12px 40px rgba(0, 0, 0, 0.15)',
          p: 4,
          borderRadius: 3,
          border: '1px solid #e5e7eb',
        }}
      >
        <Typography
          variant="h6"
          sx={{ mb: 3, fontWeight: 600, color: '#1e3a8a', textAlign: 'center' }}
        >
          Reschedule Class
        </Typography>
        <TextField
          label="New Date & Time"
          type="datetime-local"
          fullWidth
          value={newDate.toISOString().slice(0, 16)}
          onChange={(e) => setNewDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 4 }}
        />
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            onClick={handleRescheduleSubmit}
            sx={{
              bgcolor: '#1e3a8a',
              borderRadius: 2,
              px: 4,
              py: 1.5,
              fontWeight: 500,
              textTransform: 'none', // Avoid uppercase for a modern look
              '&:hover': { bgcolor: '#1e40af' },
            }}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenModal(false)}
            sx={{
              borderColor: '#1e3a8a',
              color: '#1e3a8a',
              borderRadius: 2,
              px: 4,
              py: 1.5,
              fontWeight: 500,
              textTransform: 'none',
              '&:hover': { bgcolor: '#dbeafe', borderColor: '#1e3a8a' },
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>

    {/* Snackbar for Reschedule Success */}
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity="success"
        sx={{
          width: '100%',
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          fontWeight: 500,
        }}
      >
        Reschedule request submitted successfully!
      </Alert>
    </Snackbar>
  </Box>
  );
};

export default ClassSummary;