// ClassSummary.js

import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Alert, Button } from '@mui/material';
import axios from 'axios';
import baseUrl from '../config';
import {
  Card, CardContent, CardHeader, Typography, Chip, Box, Avatar, IconButton, LinearProgress
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';
import { AccessTime } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import DiamondIcon from '@mui/icons-material/Diamond';
import PersonIcon from '@mui/icons-material/Person';
import { studentong } from '../Assets';

const DayOfWeekStyled = styled(Typography)({
  fontWeight: 'bold',
  color: '#ffffff',
  backgroundColor: '#004d40',
  borderRadius: '12px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  display: 'inline-block',
  fontSize: '14px',
  letterSpacing: '0.5px',
});
const TotalClassesBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  padding: '10px 20px',
  backgroundColor: '#004d40',
  color: 'white',
  borderRadius: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  fontWeight: 700,
  fontSize: '18px',
  textAlign: 'center',
}));

const TeacherName = styled(Typography)({
  fontWeight: 'bold',
  color: '#004d40',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
});
const ClassCard = ({ classData, classNumber, isPastClass }) => {
  const isClassOver = new Date(classData.startDate) < new Date();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        sx={{
          borderRadius: 12,
          boxShadow: 8,
          // background: 'linear-gradient(145deg, #f0f0f0, #e0e0e0)',
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          '&:hover': {
            boxShadow: 12,
            transform: 'scale(1.05)',
          },
          opacity: isClassOver ? 0.7 : 1,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 1,
          }}
        >
          <Chip
            label={isClassOver ? 'Class Over' : 'Upcoming'}
            color={isClassOver ? 'error' : 'primary'}
            sx={{
              fontWeight: 'bold',
              textTransform: 'capitalize',
              padding: '6px 14px',
              borderRadius: 24,
            }}
          />
        </Box>

        <Box
       component="img"
       src={classData.image || studentong}
       alt={classData.title}
       sx={{
         width: '100%',
         height: '240px',
         objectFit: 'contain', // Ensures the entire image is visible without cutting off
         borderRadius: '8px 8px 0 0',
         // marginBottom: 2,
       }}
        />

        <CardContent sx={{ padding: 3 }}>
          {classData.chapters && classData.chapters.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              {classData.chapters.map((chapter, idx) => (
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: '#333',
                    marginBottom: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  key={idx}
                >
                  {chapter.title}
                </Typography>
              ))}
            </Box>
          )}

          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#444',
              marginBottom: 1,
            }}
          >
            {isPastClass ? 'Class Date' : 'Next Class'}: <strong>{new Date(classData.startDate).toLocaleDateString()}</strong>
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <PersonIcon sx={{ marginRight: 1, color: '#388E3C' }} />
                <Typography variant="body2" color="#111">
                  Class No: {classNumber}
                </Typography>
              </Typography>

              <Typography
                variant="body2"
                sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}
              >
                <AccessTimeIcon sx={{ marginRight: 1, color: '#388E3C' }} />
                <Typography variant="body2" color="#111">
                  Class Time: {classData.time}
                </Typography>
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <Avatar
                  src={classData.teacherAvatar}
                  sx={{
                    width: 44,
                    height: 44,
                    marginRight: 2,
                    border: '2px solid #388E3C',
                    boxShadow: 2,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#388E3C',
                    textTransform: 'capitalize',
                  }}
                >
                  Teacher: {classData.teacherName}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <a href={classData.joinUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#388E3C',
                    color: '#fff',
                    textTransform: 'none',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                    borderRadius: 20,
                    '&:hover': {
                      backgroundColor: '#2c6c2d',
                    },
                  }}
                >
                  Join Class
                  <LinkIcon sx={{ marginLeft: 1 }} />
                </Button>
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

  
const ClassSummary = () => {
  const [classesData, setClassesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClassSchedules = async () => {
      try {
        const response = await fetch(`${baseUrl}api/students/enrolled-courses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });
        const data = await response.json();
        setClassesData(data[0].course.schedules || []);
      } catch (err) {
        setError(err?.response?.data?.message || 'An error occurred while fetching schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchClassSchedules();
  }, []);

  if (loading) return (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  if (error) return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  const upcomingClasses = [];
  const pastClasses = [];
  let nextClass = null;

  // Separate classes into upcoming and past
  classesData.forEach((classData) => {
    if (new Date(classData.startDate) > new Date()) {
      upcomingClasses.push(classData);
    } else {
      pastClasses.push(classData);
    }
  });

  // Sort upcoming classes by start date to ensure next class is the earliest upcoming class
  if (upcomingClasses.length > 0) {
    nextClass = upcomingClasses.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0];
    const nextClassIndex = upcomingClasses.findIndex(c => c.classNumber === nextClass.classNumber);
    if (nextClassIndex !== -1) {
      upcomingClasses.splice(nextClassIndex, 1);
    }
  }

  const pastClassesCount = pastClasses.length;
  const nextClassNumber = pastClassesCount > 0 ? pastClassesCount + 1 : 1;

  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      background: 'linear-gradient(135deg, #F8F8F8, #ffffff)',
      borderRadius: 4,
      boxShadow: 3,
      overflowY: 'auto',
      position: 'relative',
    }}>
      {/* Total Classes Box */}
      <TotalClassesBox>
        Total Classes: {classesData.length}
      </TotalClassesBox>

      <Typography variant="h4" gutterBottom align="center" sx={{
        fontWeight: 700,
        color: '#004d40',
        textTransform: 'uppercase',
        letterSpacing: 1,
        marginBottom: 4
      }}>
        Class Summary
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: 3, color: '#FF5733' }}>
        Next Class (No: {nextClassNumber})
      </Typography>

      <Grid container spacing={4}>
        {nextClass ? (
          <ClassCard classData={nextClass} classNumber={nextClassNumber} isPastClass={false} />
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Upcoming Classes
      </Typography>
      <Grid container spacing={4}>
        {upcomingClasses.length > 0 ? (
          upcomingClasses.map((classData, index) => (
            <ClassCard key={classData.classNumber} classData={classData} classNumber={nextClassNumber + index + 1} isPastClass={false} />
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Past Classes
      </Typography>
      <Grid container spacing={4}>
        {pastClasses.length > 0 ? (
          pastClasses.map((classData, index) => (
            <ClassCard key={classData.classNumber} classData={classData} classNumber={index + 1} isPastClass={true} />
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No past classes
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default ClassSummary;
