import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box } from "@mui/material";
import { keyframes } from "@emotion/react";
// import { ReactComponent as MaintenanceIcon } from './MaintenanceIcon.svg'; // Import your SVG

// Keyframes for a smooth, elegant animation
const slideFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const MaintenancePopup = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px", // More rounded corners for a softer feel
          boxShadow: "0 12px 35px rgba(0, 0, 0, 0.18)", // Enhanced shadow
          overflow: "hidden",
          animation: `${slideFadeIn} 0.4s ease-out`,
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "linear-gradient(135deg, #64b5f6 0%, #2196f3 100%)", // Softer blue gradient
          color: "white",
          textAlign: "center",
          padding: "32px",
          fontSize: "2rem", // Slightly larger title
          fontWeight: "600",
          letterSpacing: "0.8px",
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            width: "70px",
            height: "4px",
            backgroundColor: "#bbdefb", // Lighter underline
            borderRadius: "3px",
          },
        }}
      >
        Dashboard Under Maintenance
      </DialogTitle>
      <DialogContent
        sx={{
          background: "linear-gradient(180deg, #f8f9fa 0%, #e9ecef 100%)", // Very light, modern gradient
          padding: "48px 36px",
          textAlign: "center",
          borderTop: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        {/* <Box sx={{ marginBottom: "32px" }}>
          <MaintenanceIcon style={{ width: '80px', height: '80px', color: '#2196f3' }} />
        </Box> */}
        <Typography
          variant="body1"
          sx={{
            color: "#495057", // Professional dark grey
            fontSize: "18px",
            lineHeight: "1.8",
            marginBottom: "24px",
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Clean, readable font
          }}
        >
          We are currently performing essential maintenance to enhance your dashboard experience. During this time, you might encounter minor data discrepancies, such as attendance or other details.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#777",
            fontSize: "15px",
            fontStyle: "italic",
            fontWeight: "400",
            opacity: 0.9,
          }}
        >
          Our team is diligently working to restore everything to normal. We appreciate your understanding and patience.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#e9ecef",
          justifyContent: "center",
          padding: "24px 36px",
          borderTop: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            background: "linear-gradient(90deg, #2196f3 0%, #64b5f6 100%)",
            color: "white",
            fontWeight: "500",
            textTransform: "none",
            padding: "14px 36px",
            borderRadius: "14px",
            boxShadow: "0 5px 18px rgba(33, 150, 243, 0.35)",
            transition: "all 0.3s ease",
            "&:hover": {
              background: "linear-gradient(90deg, #1976d2 0%, #42a5f5 100%)",
              boxShadow: "0 7px 22px rgba(33, 150, 243, 0.5)",
              transform: "translateY(-3px)",
            },
          }}
        >
          Understood
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaintenancePopup;