import React from "react";
import { Box,} from '@mui/material';
import MyCartificate from "../Components/MyCartificate";
import SidebarRight from "../Components/SideScreen";

const CartificatePage = () => {

  return (
    <Box 
    display="flex"  height={{ md: '100vh' }}
    // flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
  >
  {/* Grey Strip for CountryTimeSelector */}

<MyCartificate/>   
    

  {/* Sidebar for larger screens (3rd screen) */}
  {/* <SidebarRight/> */}
  </Box>

  );
};

export default CartificatePage;
