import React, { useState } from "react";
import { Box, Modal, IconButton, Button } from '@mui/material'; // Import Modal and IconButton
import SidebarRight from "../Components/SideScreen";
import Dashboards from "../Components/Dashboard";
import { diwali ,halloween, newimage} from '../Assets';
import RatingPopup from "./RatingPlateform";
import RateTeacherPopup from "./RateTeacherPopup";

const DashboardPage = () => {
  const [displayPopUp, setDisplayPopUp] = useState(true);

  // Function to close the pop-up and set localStorage
  const closePopUp = () => {
    localStorage.setItem("seenPopUp", true);
    setDisplayPopUp(false);
  };
  const [open, setOpen] = useState(false);
  const [teacherId, setTeacherId] = useState('12345'); // Example teacher ID

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box 
      display="flex"  
      height={{ md: '100vh' }}
      flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
    >
      {/* Grey Strip for CountryTimeSelector */}
      <Dashboards/>   
      
      {/* Sidebar for larger screens (3rd screen) */}
      <SidebarRight/>
    {/* Conditional rendering for the Modal */}
    <Modal
        open={displayPopUp}
        onClose={closePopUp}
        closeAfterTransition
      >
        <Box 
          sx={{ 
            position: 'absolute',
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', // Center the modal
            width: '40%', // Set width
            bgcolor: 'white', 
            boxShadow: 24, 
            borderRadius: 2, // Optional: rounded corners
            // p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
        <IconButton
  onClick={closePopUp}
  sx={{ 
    position: 'absolute', 
    top: 10, 
    right: 10, 
    width: 40, // Adjust the width as needed
    height: 40, // Adjust the height as needed
    borderRadius: '50%', // Optional: make it circular
    bgcolor: 'rgba(248, 246, 246, 0.356)', // Optional: background color
    '&:hover': {
      bgcolor: 'rgba(209, 202, 202, 0.2)', // Optional: hover effect
    },
  }}
>
  <span className="close" aria-hidden="true" style={{ fontSize: '24px' }}>&times;</span>
</IconButton>

          
          <img src={newimage} alt="Popup" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
      </Modal>
     
 
    </Box>
  );
};

export default DashboardPage;
