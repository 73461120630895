import React, { useState,useEffect } from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, Menu, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import { gamer } from '../Assets';

const UserProfileCard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [grade, setGrade] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user,"user");

    if (user) {
      setUserName(user.name || 'User');
      setGrade(user.grade) // Set the user's name from local storage
    }
  };

  const handleLogout = () => {
    // Handle logout logic here
    console.log('Logging out...');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleClose(); 
    navigate("/login");// Close the menu after logout
  };

  useEffect(() => {
    fetchUserDetails(); // Fetch user details from local storage
  }, []);

  return (
    <Card
      sx={{
        borderRadius: 3,
        overflow: 'visible',
        position: 'relative',
        boxShadow: 3,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '60px',
          position: 'relative',
          zIndex: 2, // Ensure the content is above the image
        }}
      >
        {/* <Avatar
          sx={{
            width: 56,
            height: 56,
            marginBottom: '8px',
            backgroundColor: '#e0e0e0',
            zIndex: 3, // Ensure the avatar is above the image
          }}
        /> */}
      
<img
        src={`${gamer}?w=164&h=164&fit=crop&auto=format`}
        // alt={item.title}
        loading="lazy"
        style={{
          width: 56,
          height: 56,
          marginBottom: '8px',
          // backgroundColor: '#e0e0e0',
          zIndex: 3, // Ensure the avatar is above the image
        }}
      />
        <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'center' }}>
        {userName}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
          Grade {grade}
        </Typography>
      </CardContent>
      <IconButton
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 3, // Ensure the icon button is above the image
        }}
        onClick={handleClick}
      >
        <SettingsIcon fontSize="small" />
      </IconButton>
      
      {/* Background Image */}
      <Box
        // component="img"
        // src="http://cdn.sstatic.net/Sites/stackoverflow/company/img/logos/so/so-icon.png?v=c78bd457575a"
        alt="Background"
        sx={{
          backgroundColor: "rgb(198, 197, 252)",
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '50%',
          zIndex: 1, // Ensure the image is behind the content and avatar
          transform: 'translateY(0  px)', // Adjust the overlap with the avatar
        }}
      />

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            // width: '90px',
            borderRadius: 3,
            // overflow: 'visible',
            // position: 'relative',
            boxShadow: 5,
          },
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Card>
  );
};

export default UserProfileCard;
