import React from "react";
import { Box, useMediaQuery } from '@mui/material'; 

import TestDashboard from "../Components/TestDashboard";

const DashboardPage = () => {

  return (
    <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      background: 'linear-gradient(135deg, #F8F8F8, #ffffff)',
      borderRadius: 4,
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)', // Softer shadow for professionalism
      overflowY: 'auto',
      position: 'relative',
      minHeight: '100vh',
    }}
      flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} // Column for smaller screens and row for larger screens
    >

      <TestDashboard/>
      
    </Box>
  );
};

export default DashboardPage;
