import React from 'react';
import { demouser } from '../Assets/index';
import MenuToggle from './MenuToggle'; // Import MenuToggle component

const Navbar = ({ pagename, showMenu, handleMenuToggle }) => {
  return (
    <nav className="bg-gradient-to-r from-blue-800 to-blue-500 flex items-center justify-between h-20 px-8 shadow-sm">
      <div className="flex items-center w-full">
        {/* MenuToggle for mobile view */}
        <div className="lg:hidden">
          <MenuToggle showMenu={showMenu} handleMenuToggle={handleMenuToggle} />
        </div>
        {/* Page title */}
        <div className="flex-grow text-center">
          <h1 className="text-lg font-semibold text-gray-500">{pagename}</h1>
        </div>
        {/* User profile for desktop view */}
        <div className="hidden lg:flex items-center">
          <div className="rounded-full h-10 w-10 bg-gray-300 flex items-center justify-center mr-4">
            <img src={demouser} alt="avatar" className="rounded-full h-8 w-8" />
          </div>
          <div className="flex flex-col text-sm">
            <span className="font-medium">testing</span>
            <span>admin</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
