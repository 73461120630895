import React from 'react';
import { Outlet } from 'react-router-dom';
import {useAuth} from './useAuth';

const ProtectedRoute = () => {
  useAuth(); // Redirects to login if not authenticated

  return <Outlet />; // Render nested routes
};

export default ProtectedRoute;
