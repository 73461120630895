import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  TextField,
  IconButton,
  Grid,
  Chip,
  Divider,
  Autocomplete,
} from '@mui/material';
import { PhotoCamera, Info, FamilyRestroom, Language, Work, Save, Cancel } from '@mui/icons-material'; // Import Save and Cancel icons
import axios from 'axios';
import baseUrl from '../config'; // your API base URL
import countries from '../utlis'; // List of countries
import { CalendarToday, Person } from '@mui/icons-material';

const StudentProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // State to manage edit mode
  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    bio: '',
    skills: [],
    languages: [],
    dob: '',
    gender: '',
    parentName: '',
    nationality: null,
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [tempAvatarUrl, setTempAvatarUrl] = useState(null); // For previewing avatar during edit

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}api/students/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setProfile(data);
        setFormData({
          name: data.name,
          avatar: data.avatar,
          bio: data.bio,
          skills: data.skills || [],
          languages: data.languages || [],
          dob: data.dob || '',
          gender: data.gender || '',
          nationality: data.nationality || null,
          parentName: data.parentName || '',
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    fetchProfile();
  }, []);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatarFile(file);
    setTempAvatarUrl(URL.createObjectURL(file)); // Create temporary URL for preview
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();
    updatedFormData.append('name', formData.name);
    updatedFormData.append('bio', formData.bio);
    updatedFormData.append('languages', formData.languages.join(', '));
    updatedFormData.append('skills', formData.skills.join(', '));
    updatedFormData.append('dob', formData.dob);
    updatedFormData.append('gender', formData.gender);
    updatedFormData.append('parentName', formData.parentName);
    updatedFormData.append('nationality', formData.nationality?.name || '');
    if (avatarFile) updatedFormData.append('avatar', avatarFile);

    try {
      const { data } = await axios.put(`${baseUrl}api/students/profile`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setProfile(data);
      setAvatarFile(null); // Reset avatar file after successful update
      setTempAvatarUrl(null); // Reset temp avatar URL
      setIsEditMode(false); // Exit edit mode after saving
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setAvatarFile(null); // Clear selected avatar file on cancel
    setTempAvatarUrl(null); // Clear temp avatar URL
    // Reset form data to the initially fetched profile data to discard changes
    setFormData({
      name: profile.name,
      avatar: profile.avatar,
      bio: profile.bio,
      skills: profile.skills || [],
      languages: profile.languages || [],
      dob: profile.dob || '',
      gender: profile.gender || '',
      nationality: profile.nationality || null,
      parentName: profile.parentName || '',
    });
  };


  if (!profile) {
    return <Typography variant="h6" align="center" color="primary">Loading...</Typography>;
  }

  return (
    <Box sx={{ backgroundColor: '#fff', minHeight: '100vh', py: 4 }}>
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'center' }}>
            <label htmlFor="avatar-upload">
              <Box sx={{ position: 'relative', display: 'inline-block', cursor: isEditMode ? 'pointer' : 'default' }}>
                <Avatar
                  src={tempAvatarUrl || `${baseUrl}${profile.avatar || ''}`}
                  alt={formData.name}
                  sx={{ width: 150, height: 150, border: '4px solid #e0f2f1', boxShadow: 3 }}
                >
                  {!tempAvatarUrl && !profile.avatar && formData.name[0]}
                </Avatar>
                {isEditMode && (
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 'white' }}
                  >
                    <PhotoCamera />
                  </IconButton>
                )}
              </Box>
              <input type="file" id="avatar-upload" accept="image/*" hidden onChange={handleAvatarChange} />
            </label>
            {isEditMode ? (
              <TextField label="Name" name="name" value={formData.name} onChange={handleFormChange} fullWidth margin="normal" />
            ) : (
              <Typography variant="h5" sx={{ fontWeight: 600, mt: 2 }}>{formData.name}</Typography>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>Basic Information</Typography>
            {/* Date of Birth */}
            <Box sx={{ mb: 2 }}>
              <CalendarToday sx={{ verticalAlign: 'middle', mr: 1 }} />
              {isEditMode ? (
                <TextField name="dob" type="date" value={formData.dob} onChange={handleFormChange} fullWidth />
              ) : (
                <Typography variant="body2">Date of Birth: {formData.dob || 'Not set'}</Typography>
              )}
            </Box>
            {/* Gender */}
            <Box sx={{ mb: 2 }}>
              <Person sx={{ verticalAlign: 'middle', mr: 1 }} />
              {isEditMode ? (
                <TextField name="gender" value={formData.gender} onChange={handleFormChange} fullWidth />
              ) : (
                <Typography variant="body2">Gender: {formData.gender || 'Not set'}</Typography>
              )}
            </Box>
            {/* Nationality */}
            <Box sx={{ mb: 2 }}>
              <Language sx={{ verticalAlign: 'middle', mr: 1 }} />
              {isEditMode ? (
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  value={formData.nationality || null}
                  onChange={(event, newValue) => setFormData({ ...formData, nationality: newValue || null })}
                  renderInput={(params) => <TextField {...params} label="Nationality" />}
                  fullWidth
                />
              ) : (
                <Typography variant="body2">Nationality: {formData.nationality?.name || 'Not selected'}</Typography>
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>Skills & Languages</Typography>
            <Typography variant="subtitle2">Skills:</Typography>
            {isEditMode ? (
              <TextField
                name="skills"
                value={formData.skills.join(', ')}
                onChange={(e) => setFormData({ ...formData, skills: e.target.value.split(',').map((s) => s.trim()) })}
                fullWidth
              />
            ) : (
              formData.skills.map((skill) => <Chip key={skill} label={skill} sx={{ mr: 1, mt: 1 }} />)
            )}

            <Typography variant="subtitle2" sx={{ mt: 2 }}>Languages:</Typography>
            {isEditMode ? (
              <TextField
                name="languages"
                value={formData.languages.join(', ')}
                onChange={(e) => setFormData({ ...formData, languages: e.target.value.split(',').map((l) => l.trim()) })}
                fullWidth
              />
            ) : (
              formData.languages.map((lang) => <Chip key={lang} label={lang} sx={{ mr: 1, mt: 1 }} />)
            )}
          </Box>

          {isEditMode && (
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<Save />} sx={{ mr: 2 }}>Save</Button>
              <Button variant="outlined" color="secondary" onClick={handleCancelEdit} startIcon={<Cancel />}>Cancel</Button>
            </Box>
          )}
      </Grid>

      <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>About Me</Typography>
          {isEditMode ? (
            <TextField
              name="bio"
              value={formData.bio}
              onChange={handleFormChange}
              fullWidth
              multiline
              rows={4}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">{formData.bio || 'No bio available.'}</Typography>
          )}

          {!isEditMode && (
            <Button variant="contained" sx={{ mt: 3 }} onClick={() => setIsEditMode(true)}>Edit Profile</Button>
          )}
      </Grid>
    </Grid>
  </Box>
  );
};

export default StudentProfilePage;