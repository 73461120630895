import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Paper, Rating, Divider, Avatar, Grid, Button, TextField, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'; // Added List, ListItem, ListItemIcon, ListItemText
import baseUrl from '../config';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import LanguageIcon from '@mui/icons-material/Language'; // New Icons
import SkillIcon from '@mui/icons-material/FitnessCenter';
import CodeIcon from '@mui/icons-material/Code';
import FaceIcon from '@mui/icons-material/Face';
import DescriptionIcon from '@mui/icons-material/Description';


// TeacherProfile component (Student View - Redesigned for Profile Look)
const TeacherProfile = () => {
  const { teacherId } = useParams();
  const [teacher, setTeacher] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [newRating, setNewRating] = useState(0);
  const [newReview, setNewReview] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);
  const token = localStorage.getItem('token');
console.log(teacher);

  useEffect(() => {
    const fetchTeacherProfile = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/teacherprofile?teacherId=${teacherId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.teacher) {
          setTeacher(response.data.teacher);
        }

        const fetchedReviews = response.data.teacher.reviews || [];
        setReviews(fetchedReviews);

        const avgRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0) / fetchedReviews.length;
        setRating(avgRating || 0);

        // Check if the student has already submitted a review
        const hasReviewed = fetchedReviews.some((review) => review.student === localStorage.getItem('userId'));
        setHasReviewed(hasReviewed);
      } catch (error) {
        console.error('Error fetching teacher profile:', error);
      }
    };

    fetchTeacherProfile();
  }, [teacherId]);

  const handleSubmitReview = async () => {
    if (newRating < 1 || newRating > 5) {
      alert('Rating must be between 1 and 5');
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}api/students/rate-teacher/${teacherId}`,
        { rating: newRating, review: newReview },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(response.data.message);
      setReviews([...reviews, response.data.review]);
      setHasReviewed(true);
      setNewRating(0);
      setNewReview('');
      const avgRating = [...reviews, response.data.review].reduce((acc, review) => acc + review.rating, 0) / (reviews.length + 1);
      setRating(avgRating || 0);

    } catch (error) {
      console.error('Error submitting review:', error);
      alert('Error submitting review');
    }
  };

  if (!teacher) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor: '#f4f6f8', // Light background for body
        padding: '30px 0',
      }}
    >
      <Card
        sx={{
          borderRadius: '12px', // Rounded card corners
          boxShadow: '0 4px 15px rgba(0,0,0,0.1)', // Softer card shadow
          // width: '90%',
          display: 'flex', // Flex container for sidebar layout
          flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, row on medium+
          overflow: 'hidden', // Ensure rounded corners clip content
        }}
      >
        {/* Left Sidebar - Profile Summary */}
        <Box
          sx={{
            width: { xs: '100%', md: '30%' }, // Full width on small screens, fixed width on medium+
            backgroundColor: '#eef2f5', // Very light sidebar background
            padding: '30px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start', // Align content to the top
          }}
        >
          <Avatar
            alt="Teacher Avatar"
            src={`${baseUrl}${teacher.avatar}`}
            sx={{
              width: 140,
              height: 140,
              borderRadius: '50%',
              border: '4px solid white',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              marginBottom: '20px',
            }}
          />
          <Typography variant="h5" sx={{ fontWeight: '600', color: '#333', mb: 1 }}>
            {teacher?.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Rating
              value={rating}
              precision={0.1}
              readOnly
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              sx={{
                fontSize: '24px',
                color: '#ffca28',
                marginRight: '6px',
              }}
            />
            <Typography variant="body2" sx={{ fontSize: '15px', color: '#555', fontWeight: '500' }}>
              {rating.toFixed(1)} ({reviews.length} reviews)
            </Typography>
          </Box>

          <Divider sx={{ width: '80%', margin: '20px auto', borderColor: '#d4dce0' }} />

          <List sx={{ width: '100%', maxWidth: 360 }}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: '35px', color: '#555' }}>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2" sx={{ fontWeight: '500', color: '#333' }}>Teacher ID</Typography>} secondary={<Typography variant="caption" color="textSecondary">{teacher.uniqueId || 'N/A'}</Typography>} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: '35px', color: '#555' }}>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2" sx={{ fontWeight: '500', color: '#333' }}>Referral Code</Typography>} secondary={<Typography variant="caption" color="textSecondary">{teacher.referralCode || 'N/A'}</Typography>} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: '35px', color: '#555' }}>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2" sx={{ fontWeight: '500', color: '#333' }}>Languages</Typography>} secondary={<Typography variant="caption" color="textSecondary">{teacher.languages.join(', ') || 'N/A'}</Typography>} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: '35px', color: '#555' }}>
                <SkillIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2" sx={{ fontWeight: '500', color: '#333' }}>Skills</Typography>} secondary={<Typography variant="caption" color="textSecondary">{teacher.skills.join(', ') || 'N/A'}</Typography>} />
            </ListItem>
          </List>
        </Box>

        {/* Right Main Content - Bio and Reviews */}
        <Box sx={{ padding: '30px', width: { xs: '100%', md: '70%' } }}>
          <Typography variant="h4" sx={{ fontWeight: '600', color: '#333', mb: 3, display: 'flex', alignItems: 'center' }}>
            <DescriptionIcon sx={{ marginRight: 1, fontSize: '1.6rem', color: '#333' }} /> About Teacher
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px', color: '#555', lineHeight: '1.7', mb: 4 }}>
            {teacher.bio || 'No bio available.'}
          </Typography>

          <Divider sx={{ margin: '30px 0', borderColor: '#d4dce0' }} />

          {/* Review Submission Section */}
          {!hasReviewed && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '600', color: '#333', mb: 2, display: 'flex', alignItems: 'center' }}>
                <ChatBubbleIcon sx={{ color: '#1976d2', marginRight: 1, fontSize: '1.4rem' }} /> Submit Your Review
              </Typography>
              <Rating
                value={newRating}
                onChange={(event, newValue) => setNewRating(newValue)}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                sx={{ marginTop: '10px', fontSize: '26px', color: '#ffca28' }}
              />
              <TextField
                fullWidth
                label="Share your experience with this teacher..."
                placeholder="Write your review here..."
                multiline
                rows={3}
                value={newReview}
                onChange={(e) => setNewReview(e.target.value)}
                sx={{ marginTop: '20px' }}
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '20px', fontWeight: '600', padding: '10px 25px', borderRadius: '8px' }}
                onClick={handleSubmitReview}
              >
                Submit Review
              </Button>
            </Box>
          )}

          {/* Reviews Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" sx={{ fontSize: '22px', color: '#333', fontWeight: '600', mb: 3 }}>
              Student Reviews
            </Typography>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <Paper
                  key={review._id}
                  elevation={0} // Removed elevation for a flatter look
                  sx={{
                    backgroundColor: '#f9f9f9', // Very light grey background for reviews
                    padding: '20px',
                    marginBottom: '15px',
                    borderRadius: '8px',
                    // boxShadow: '0 1px 4px rgba(0,0,0,0.1)', // Removed boxShadow
                    // border: '1px solid #e0e0e0' // Removed border
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontSize: '17px', fontWeight: '600', color: '#444', mb: 1 }}>
                    {review.author || 'Anonymous Student'}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '15px', color: '#666', lineHeight: '1.6' }}>
                    {review.review}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
                    <Rating
                      value={review.rating}
                      precision={0.1}
                      readOnly
                      icon={<StarIcon fontSize="small" />}
                      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="small" />}
                      sx={{
                        fontSize: '20px',
                        color: '#ffca28',
                      }}
                    />
                  </Box>
                </Paper>
              ))
            ) : (
              <Typography variant="body2" sx={{ fontSize: '16px', color: '#777' }}>
                No reviews yet. Be the first to review this teacher!
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TeacherProfile;