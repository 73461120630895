import React from 'react'
import {
    Box,
    Card,
    CardContent,
    Typography,
   
  } from '@mui/material';
  import {Assignment, } from '@mui/icons-material';

function NoticeBoard() {
    const notices = [
        { title: "Coming Soon", date: "soon", icon: <Assignment color="primary" /> },
      ];
  return (
    <Card
    sx={{
      borderRadius: 8,
      boxShadow: 4,
      backgroundColor: '#ffffff',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
      },
    }}
  >
    <CardContent sx={{ p: 4 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 700,
          color: '#34495e',
          letterSpacing: 1.2,
          textTransform: 'uppercase',
          mb: 3,
          textAlign: 'center',
        }}
      >
        Notice Board
      </Typography>
      {notices.map((notice, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Box
            sx={{
              backgroundColor: '#ecf0f1',
              p: 1.5,
              borderRadius: '50%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {notice.icon}
          </Box>
          <Box sx={{ ml: 3 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: '#2c3e50',
                letterSpacing: 0.5,
              }}
            >
              {notice.title}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Added on: {notice.date}
            </Typography>
          </Box>
        </Box>
      ))}
    </CardContent>
  </Card>  )
}

export default NoticeBoard