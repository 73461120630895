// src/App.js

import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Card, CardContent, Typography, Grid, Avatar ,IconButton,Dialog, DialogActions, DialogContent, DialogTitle, Button} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
// const classesData = [
//   {
//     id: 1,
//     image: background,
//     title: 'Robotics Class #1',
//     time: '06:30 PM, 31 Jul 2024',
//     instructor: 'Rayan Kiwan',
//     avatar: '/images/rayan_avatar.png',
//     concepts: 'Introduction to robotics, Installing PictoBlox.',
//     rating: 4,
//   },
//   {
//     id: 2,
//     image: background,
//     title: 'Robotics Class #2',
//     time: '09:00 AM, 02 Aug 2024',
//     instructor: 'Tulasi',
//     avatar: '/images/tulasi_avatar.png',
//     concepts: 'Movements & Responses. The learning outcomes & skills.',
//     rating: 5,
//   },
//   {
//     id: 3,
//     image: background,
//     title: 'Robotics Class #3',
//     time: '01:30 PM, 05 Aug 2024',
//     instructor: 'Tulasi',
//     avatar: '/images/tulasi_avatar.png',
//     concepts: 'Loops, conditionals, broadcast messages, customization.',
//     rating: 4,
//   },
//   {
//     id: 4,
//     image: background,
//     title: 'Robotics Class #4',
//     time: '07:00 PM, 07 Aug 2024',
//     instructor: 'Tulasi',
//     avatar: '/images/tulasi_avatar.png',
//     concepts: 'Understanding Artificial Intelligence, uses of AI.',
//     rating: 0,
//   },
//   {
//     id: 5,
//     image: background,
//     title: 'Robotics Class #4.a',
//     time: '09:00 AM, 09 Aug 2024',
//     instructor: 'Tulasi',
//     avatar: '/images/tulasi_avatar.png',
//     concepts: '',
//     rating: 0,
//   }
// ];

// function ClassCard({ classData }) {
//   return (
//     <Card sx={{ maxWidth: 345, mb: 4,borderRadius: 4, boxShadow: 3 }}>
//       <CardMedia
//         component="img"
//         height="140"
//         image={classData.image}
//         alt={classData.title}
//       />
//       <CardContent>
//         <Typography variant="body2" color="textSecondary" component="div">
//           {classData.rating > 0 && (
//             <Box mb={1}>
//               Rated {classData.rating} out of 5 <span role="img" aria-label="star">⭐</span>
//             </Box>
//           )}
//           <Typography variant="caption" display="block" gutterBottom>
//             Exploring PictoBlox
//           </Typography>
//         </Typography>
//         <Typography variant="h6" component="div">
//           {classData.title}
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           {classData.time}
//         </Typography>
//         <Box display="flex" alignItems="center" mt={1}>
//           <Avatar alt={classData.instructor} src={classData.avatar} sx={{ width: 32, height: 32, mr: 1 }} />
//           <Typography variant="body2">{classData.instructor}</Typography>
//         </Box>
//         <Typography variant="caption" color="textSecondary" component="div" mt={1}>
//           Concepts Learnt
//         </Typography>
//         <Typography variant="body2" color="textSecondary">
//           {classData.concepts} <span style={{ color: '#3f51b5', cursor: 'pointer' }}>Read More</span>
//         </Typography>
//       </CardContent>
//       <Box textAlign="center" mb={2}>
//         <Button variant="contained" color="primary" sx={{ textTransform: 'none' ,borderRadius:5}}>
//           Take Assignment
//         </Button>
//       </Box>
//     </Card>
//   );
// }

function Assessments() {
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleMenuClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      backgroundColor: '#bac2ff',
    }}
  >      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box>
          <Typography variant="h5" component="div">
          Your Assessments
          </Typography>
          <Typography variant="body2" color="textSecondary">
          Let’s gear up to be future ready
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <FilterListIcon />
        </IconButton> */}
     
      </Box>
      <Grid container spacing={2}>
        {/* {certificates.map((cert, index) => ( */}
        {Array.from({ length: 12 }).map((_, index) => (

          <Grid item xs={12} sm={6} md={3}>
            <Card
              // onClick={() => handleClickOpen(cert)}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                borderRadius: '12px',
                textAlign: 'center',
                padding: '20px',
                paddingTop:"100px",
                paddingBottom:'100px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              <CardContent>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    width: 56,
                    height: 56,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>
                <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {/* {cert.title} */}
Quiz {index + 1}              </Typography>
              </CardContent>
            </Card>
            
          </Grid>
       
         ))} 
      </Grid>
    </Box>
  );
}

export default Assessments;

