import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Rating } from '@mui/material';
import { styled } from '@mui/system';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '2rem',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  maxWidth: '400px',
  width: '90%',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '0.5rem',
  color: '#6200ea',
  fontWeight: 'bold',
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: '1rem',
  color: '#666',
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#6200ea',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#3700b3',
  },
  marginRight: theme.spacing(1),
}));

const CancelButton = styled(Button)(({ theme }) => ({
  borderColor: '#6200ea',
  color: '#6200ea',
}));

const RatingPopup = ({ open, handleClose }) => {
  const [rating, setRating] = useState(null);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (rating < 1 || rating > 5) {
      setError('Rating must be between 1 and 5.');
      return;
    }
    setError('');

    try {
      const response = await fetch('/api/rate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit rating');
      }

      const data = await response.json();
      alert(data.message);
      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox>
        <Title variant="h6">We Value Your Feedback!</Title>
        <Description variant="body1">
          Please rate your experience with us. Your feedback helps us improve!
        </Description>
        <Rating
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          max={5}
          sx={{ marginBottom: '1rem', color: '#ff9800' }}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Box>
          <SubmitButton variant="contained" onClick={handleSubmit}>
            Submit Rating
          </SubmitButton>
          <CancelButton variant="outlined" onClick={handleClose}>
            Cancel
          </CancelButton>
        </Box>
      </StyledBox>
    </StyledModal>
  );
};

export default RatingPopup;
