import React from "react";
import { Box,} from '@mui/material';
// import { styled } from '@mui/material/styles';
import Reward from "../Components/Reward";
import SideScreen from "../Components/SideScreen";
// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
const RewardPage = () => {
  // const [showMenu, setShowMenu] = useState(false);

  // const handleMenuToggle = () => {
  //   setShowMenu(!showMenu);
  // };

  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };
  return (
    <Box 
      display="flex" 
      flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
    >
     
     <Reward/>   
     <SideScreen/>

      {/* <Box
        component={SideScreen}
        open={sidebarOpen}
        onClose={handleSidebarToggle}
       
      /> */}
    </Box>
  );
};

export default RewardPage;
