import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, LinearProgress, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import baseUrl from '../config';
import axios from 'axios';

// Function to calculate the number of days between two dates
const calculateDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInTime = end.getTime() - start.getTime();
  return Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
};

const LearningProgress = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [userName, setUserName] = useState('');

  // Function to fetch upcoming courses
  const fetchUpcomingCourses = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      

      if (response.status === 200) {
        const data = response.data;

        // Restructure data to include fields inside 'course' and relevant teacher names
        const transformedData = data.map(item => ({
          ...item,
          course: {
            ...item.course,
            title: item.course.title,
            description: item.course.description,
            startDate: item.course.startDate,
            endDate: item.course.schedules[0]?.date, // Use the first schedule's date as end date
            bookedClasses: item.course.bookedClasses,
            completedClasses: item.course.completedClasses,
            progress: item.course.progress,
            studentName: item.studentName, // Assuming studentName is passed as well
            teacherName: item.enrollmentTeacherName // Use the teacher name from the response
          }
        }));
        
        setCoursesData(transformedData);
      } else {
        console.error('Failed to fetch courses:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Function to fetch user details
  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.name || 'User');
    }
  };

  useEffect(() => {
    fetchUpcomingCourses();
    fetchUserDetails();
  }, []);

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto',  }}>
      <Typography variant="h5" fontWeight="bold" mb={3} color="#050a30" align="center">
        My Learning Progress
      </Typography>

      {coursesData.map(course => {
        const { startDate, endDate, progress, bookedClasses, enrollmentTeacherName, title } = course.course || {};
        const daysRemaining = endDate ? calculateDaysBetweenDates(new Date(), endDate) : null;

        return (
          <Card key={course._id} sx={{ borderRadius: 8, boxShadow: 3, mb: 3 }}>
            <CardContent>
              <Typography variant="subtitle1" color="primary" mb={2}>
                1 on 1 Online Classes
              </Typography>

              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center">
                  <Avatar src="/path/to/robotics-icon.png" sx={{ width: 40, height: 40, mr: 2 }} />
                  <Typography variant="h6" fontWeight="bold">{title}</Typography>
                </Box>
                <LockIcon color="action" />
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <LinearProgress 
                  variant="determinate" 
                  value={bookedClasses > 0 ? (progress / bookedClasses) * 100 : 0} 
                  sx={{ 
                    flexGrow: 1, 
                    mr: 1, 
                    height: '10px', 
                    borderRadius: '5px', 
                    backgroundColor: '#dbd9e7', 
                    '& .MuiLinearProgress-bar': { 
                      backgroundColor: '#050a30' 
                    },
                  }} 
                />
                <Typography variant="caption" sx={{ 
                    color: '#050a30', 
                    fontWeight: 'bold', 
                    textAlign: 'center', 
                    ml: 1 
                  }}>
                  {progress || 0}/{bookedClasses || 0} Completed ({bookedClasses > 0 ? ((progress / bookedClasses) * 100).toFixed(0) : 0}%)
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" textAlign="center" mb={2}>
                <Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                  <Typography variant="h5" sx={{ color: '#050a30', fontWeight: 'bold' }}>{bookedClasses || 0}</Typography>
                  <Typography variant="caption" color="textSecondary">Booked Classes</Typography>
                </Box>
                <Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                  <Typography variant="h5" sx={{ color: '#050a30', fontWeight: 'bold' }}>{progress || 0}</Typography>
                  <Typography variant="caption" color="textSecondary">Completed Classes</Typography>
                </Box>
                <Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
                  <Typography variant="h5" sx={{ color: '#d32f2f', fontWeight: 'bold' }}>{0}</Typography>
                  <Typography variant="caption" color="textSecondary">Lost Classes</Typography>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <Avatar 
                  src="/path/to/student-avatar.png" 
                  sx={{ 
                    width: 40, 
                    height: 40, 
                    mr: 2, 
                    border: '2px solid #050a30' 
                  }} 
                />
                <Box>
                  <Typography sx={{ fontWeight: 'bold', color: '#050a30' }}>{enrollmentTeacherName}</Typography>
                  <Typography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic' }}>Teacher</Typography>
                </Box>
              </Box>

              {daysRemaining !== null && (
                <Typography variant="body2" sx={{ color: '#757575', fontStyle: 'italic', textAlign: 'center' }}>
                  Days Remaining: <strong>{daysRemaining}</strong> days
                </Typography>
              )}
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default LearningProgress;
