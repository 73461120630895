import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Rating, TextField, FormControl, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

// Styled components
const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  padding: '2.5rem',
  boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  maxWidth: '600px',
  width: '90%',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '1rem',
  color: '#333',
  fontWeight: '600',
  fontSize: '1.75rem',
  textTransform: 'uppercase',
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: '2rem',
  color: '#555',
  fontSize: '1rem',
  lineHeight: '1.5',
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-icon': {
    color: '#ff9800',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3f51b5',
  color: '#ffffff',
  padding: '10px 20px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
  marginRight: theme.spacing(1),
}));

const CancelButton = styled(Button)(({ theme }) => ({
  borderColor: '#3f51b5',
  color: '#3f51b5',
  padding: '10px 20px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(63, 81, 181, 0.1)',
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: '#d32f2f',
  marginTop: '1rem',
  fontWeight: 'bold',
}));

const quotes = [
  "Excellent",
  "Very Good",
  "Good",
  "Fair",
  "Needs Improvement"
];

const RateTeacherPopup = ({ open, handleClose, teacherId }) => {
  const [rating, setRating] = useState(null);
  const [review, setReview] = useState('');
  const [selectedQuote, setSelectedQuote] = useState('');
  const [error, setError] = useState('');

  const handleQuoteChange = (event) => {
    const quote = event.target.value;
    setSelectedQuote(quote);
    setReview(quote); // Automatically set the review to the selected quote
  };

  const handleSubmit = async () => {
    if (rating < 1 || rating > 5) {
      setError('Please select a rating between 1 and 5.');
      return;
    }
    setError('');

    try {
      const response = await fetch(`/api/rate-teacher/${teacherId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating, review }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit rating');
      }

      const data = await response.json();
      alert(data.message);
      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox>
        <Title variant="h6">Rate Your Teacher</Title>
        <Description variant="body1">
          Your feedback is crucial in helping us maintain high standards. Please provide a rating and an optional review for your teacher.
        </Description>
        <StyledRating
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          max={5}
        />
        <Typography variant="body1" color="textSecondary" sx={{ marginTop: '0.5rem', fontWeight: '400' }}>
          {rating !== null ? ` ${rating} star${rating > 1 ? 's' : ''}` : ''}
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <Select
            value={selectedQuote}
            onChange={handleQuoteChange}
            displayEmpty
            sx={{ borderRadius: '8px' }}
          >
            <MenuItem value="" disabled>Select a predefined feedback</MenuItem>
            {quotes.map((quote, index) => (
              <MenuItem key={index} value={quote}>{quote}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Write a review (optional)"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={review}
          onChange={(e) => setReview(e.target.value)}
          sx={{ marginBottom: '1rem' }}
          InputProps={{
            style: {
              borderRadius: '8px',
            },
          }}
        />
        {error && <ErrorText>{error}</ErrorText>}
        <Box display="flex" justifyContent="space-between" marginTop="1.5rem">
          <SubmitButton variant="contained" onClick={handleSubmit}>
            Submit Rating
          </SubmitButton>
          <CancelButton variant="outlined" onClick={handleClose}>
            Cancel
          </CancelButton>
        </Box>
      </StyledBox>
    </StyledModal>
  );
};

export default RateTeacherPopup;
