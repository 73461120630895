import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Button, Grid, IconButton, Pagination, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DiamondIcon from '@mui/icons-material/Diamond';
import LockIcon from '@mui/icons-material/Lock';
import CertificateCriteria from './CertificateCriteria';
import axios from 'axios';
import baseUrl from '../config'; // Adjust based on your config
import { micbackground } from '../Assets';
import { useNavigate } from 'react-router-dom';

const EnrichmentClasses = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClickOpen = () => setDrawerOpen(true);

  const handleClickClose = () => setDrawerOpen(false);

  const navigate = useNavigate();

  const handleCreateNotes = () => {
    navigate('/createNotes'); // Navigate to the CreateNotes page
  };


  useEffect(() => {
    const fetchEnrichmentClasses = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${baseUrl}api/students/enrichment-classes`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
console.log(response);

        // Sort classes to show unlocked classes first
        const sortedClasses = response.data.sort((a, b) => (a.locked === b.locked ? 0 : a.locked ? 1 : -1));
        setClasses(sortedClasses);
        setTotalPages(1);
      } catch (err) {
        console.error('Error fetching enrichment classes:', err);
        setError('Failed to load enrichment classes.');
      } finally {
        setLoading(false);
      }
    };

    fetchEnrichmentClasses();
  }, []);

  console.log(classes,"responseresponse");
  

  return (
              <Box sx={{ position: "relative", p: 2 ,minHeight:"100vh"}}>
  
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333',
          
          fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text






           }}>
Activity Classes          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
          Take control of your learning journey
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Grid container spacing={4}>
        {classes.map((classItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ borderRadius: 4, boxShadow: 3, height: '100%', opacity: classItem.locked ? 0.5 : 1 }}>
              <Box
                component="img"
                src={`${baseUrl}${classItem.image}`}
                alt={classItem.title}
                sx={{
                  width: '100%',
                  height: '240px',
                  objectFit: 'fit',
                  borderRadius: '4px 4px 0 0',
                  overflow: 'hidden',
                  marginBottom: '1px',
                }}
              />
              <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Typography variant="h6" fontWeight="bold" mb={1}>
                  {classItem.title} {classItem.locked && <LockIcon color="action" />}
                </Typography>
                {classItem.locked ? (
                  <Typography variant="body1" color="textSecondary">
                    Locked (Minimum classes needed: {classItem.minCompletedClasses})
                  </Typography>
                ) : (
                  <>
                    <Typography variant="body2" color="textSecondary">{classItem.time}</Typography>
                    <Typography variant="body2" color="textSecondary">{classItem.description}</Typography>
                    {!classItem.writing?<Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'darkblue',
                        color: 'white',
                        borderRadius: '20px',
                        '&:hover': {
                          backgroundColor: 'darkslateblue',
                        },
                      }}
                      href={classItem.link}
                      target="_blank"
                    >
                      {classItem.link} <DiamondIcon sx={{ ml: 1 }} />
                    </Button>: <Button
      variant="contained"
      sx={{
        backgroundColor: 'darkblue',
        color: 'white',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: 'darkslateblue',
        },
      }}
        onClick={handleCreateNotes}
      >
        Create {classItem.title}
      </Button>}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      )}

      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          variant="outlined"
          shape="rounded"
        />
      </Box>

      <CertificateCriteria open={drawerOpen} onClose={handleClickClose} />
    </Box>
  );
};

export default EnrichmentClasses;
