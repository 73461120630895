
const baseUrl =


    //test server ------------
    // "http://localhost:4000/"
   

    //live server -----------------------------------------------------------------------

"https://backend.oratrics.in/"



     //https://backend.oratrics.in/
    //-------------------------------
export default baseUrl;
