import React from "react";
import { Box, useMediaQuery } from '@mui/material'; 

import TestDashboard from "../Components/TestDashboard";

const DashboardPage = () => {

  return (
    <Box
    
      flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} // Column for smaller screens and row for larger screens
    >

      <TestDashboard/>
    </Box>
  );
};

export default DashboardPage;
