import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import CountryTimeSelector from './TimeZone';
import CardComponent from './CardComponent';
import { newbg, portrait, studentong } from '../Assets';
import baseUrl from '../config';

const Dashboards = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [userName, setUserName] = useState('');



  const newtestperformance = async () => {
    try {
      const response = await fetch(`${baseUrl}api/students/performance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      console.log(data,"datadata");
      
    
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };


  const fetchUpcomingCourses = async () => {
    try {
      const response = await fetch(`${baseUrl}api/students/enrolled-courses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      console.log(data,"datadata");
      
      if (response.ok) {
        // Restructure data to include new fields
        const transformedData = data.map(item => ({
          ...item,
          course: {
            ...item.course,
            title: item.course.title,
            description: item.course.description,
            startDate: item.course.startDate,
            endDate: item.course.endDate, // Use endDate directly from the response
            bookedClasses: item.course.bookedClasses,
            completedClasses: item.course.completedClasses,
            progress: item.course.progress,
            // Note: studentName is undefined in your response; handle it accordingly
            teacherName: item.enrollmentTeacherName || item.teacherName || 'Unknown Teacher', // Use teacher name from the response
          }
        }));
        setCoursesData(transformedData);
      } else {
        console.error('Failed to fetch courses:', data.message);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.name || 'User');
    }
  };

  useEffect(() => {
    fetchUpcomingCourses();
    fetchUserDetails();
    newtestperformance()
  }, []);

  return (
    <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      backgroundImage: `url(${newbg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // backgroundAttachment: 'fixed',
      overflowY: 'auto', // Enable scrolling for this Box
    }}
  >
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: 2,
        borderRadius: 1,
        mb: 3,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <CountryTimeSelector />
    </Box>

    <Box sx={{ mb: 3 }}>
      <Typography variant="h5">👋 Hello {userName}</Typography>
      <Typography variant="subtitle1">Unleash your true potential with Oratrics: The Personality School.</Typography>
    </Box>

    {/* <Grid container spacing={4} sx={{ mb: 3 }}>
      <Grid item xs={12} md={6}>
        <Card sx={{ padding: '20px', borderRadius: '16px' }}>
          <CardContent>
            <Typography variant="h6">Hey👋</Typography>
            <Typography variant="body2">Please take a few minutes to rate your experience with us.</Typography>
            <Button variant="contained" color="secondary" sx={{ mt: 2 }}>
              Rate your Experience
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid> */}

    <Typography variant="h6" sx={{ mb: 2, color: '#6d6d81', fontWeight: 'bold' }}>
      Upcoming Classes
    </Typography>
    <Grid container spacing={2}>
      {coursesData.map(course => (
        <Grid item xs={12} sm={6} md={3} key={course.courseId}>
          {course.course ? (
            <CardComponent
              imageUrl={course.course?.imageUrl || studentong}
              title={course.course?.title}
              description={course.course?.description}
              pdfUrl={course.course?.pdfUrl}
              bookedClasses={course.course?.bookedClasses}
              teacher={course.course.enrollmentTeacherName}
              startDate={course.course.startDate}
              progress={course.course.progress}
              completed={course.course.completed}
              joinUrl={course.course.schedules[0]?.joinUrl}
            />
          ) : (
            <Card sx={{ padding: '20px', borderRadius: '16px' }}>
              <CardContent>
                <Typography variant="body2">No course information available.</Typography>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Class Schedule:</Typography>
                  {course.course.schedules.map(schedule => (
                    <Box key={schedule._id} sx={{ mb: 1 }}>
                      <Typography variant="body2">{`Date: ${new Date(schedule.startDate).toLocaleDateString()} Time: ${schedule.time}`}</Typography>
                      <Button   variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ textTransform: 'none', mt: 5 }} href={schedule.joinUrl} target="_blank">
                        Join Class
                      </Button>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
  );
};

export default Dashboards;
