import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, Menu, MenuItem, Chip, Button, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import GradeIcon from '@mui/icons-material/Grade';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useNavigate } from "react-router-dom";
import baseUrl from '../config';
import axios from 'axios';
import StarIcon from '@mui/icons-material/Star'; // Import the star icon

const UserProfileCard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [grade, setGrade] = useState('');
  const [coursesData, setCoursesData] = useState([]);

  const [student, setStudent] = useState({ name: '', id: '', avatar: '' });

  // Function to fetch upcoming courses
  const fetchUpcomingCourses = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.status === 200) {
        const data = response.data;

        // Restructure data to include fields inside 'course' and relevant teacher names
        const transformedData = data.map(item => ({
          ...item,
          course: {
            ...item.course,
            title: item.course.title,
            description: item.course.description,
            startDate: item.course.startDate,
            endDate: item.course.schedules[0]?.date, // Use the first schedule's date as end date
            bookedClasses: item.course.bookedClasses,
            completedClasses: item.course.completedClasses,
            progress: item.course.progress,
            studentName: item.studentName, // Assuming studentName is passed as well
            teacherName: item.enrollmentTeacherName, // Use the teacher name from the response
            teacherid :item.enrollmentTeacherId // Add teacher ID to the transformed data
          }
        }));
        
        setCoursesData(transformedData);
      } else {
        console.error('Failed to fetch courses:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      setUserName(user.name || 'User');
      setGrade(user.grade);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(baseUrl + 'api/students/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setStudent({
          name: data.name,
          id: data.uniqueId,
          avatar: data.avatar,
          grade:data.grade
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    fetchUpcomingCourses()
    fetchProfile();
    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    handleClose();
    navigate("/login");
  };

  const handleEditProfile = () => {
    navigate("/profile");
    handleClose();
  };
  const navigateToTeacherProfile = (teacherId) => {
    navigate(`/teacher/profile/${teacherId}`); // Navigate to teacher's profile page
  };
  return (
    <>
         {coursesData.map(course => {
        const { startDate, endDate, progress, bookedClasses, enrollmentTeacherName, enrollmentTeacherId, title } = course.course || {};

        return (
    <Card sx={{
      borderRadius: 3,
      overflow: "hidden",
      boxShadow: 6,
      backgroundColor: "#1e293b",
      position: 'relative',
      margin: "auto",
    }}>
     
      <CardContent sx={{
        color: "#fff",
        p: 2,
        backgroundImage: 'url("https://example.com/your-background-image.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}>
        <Box display="flex" alignItems="center" gap={3}>
          <Avatar src={`${baseUrl}${student.avatar}`} sx={{ width: 80, height: 80, border: '3px solid #fff' }} />
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.3rem', letterSpacing: 1 , textTransform: 'uppercase' }}>{student.name}</Typography>
            <Typography variant="body3" sx={{ opacity: 0.9, fontSize: '1.0rem', letterSpacing: 1,color: '#B0BEC5' }}>{title}</Typography>

            <Typography variant="body2" sx={{ opacity: 0.9, fontSize: '0.95rem', color: '#B0BEC5' }}>Grade: {grade}</Typography>
            <Typography variant="body2" sx={{ opacity: 0.9, fontSize: '0.95rem', color: '#B0BEC5' }}>Student ID: {student.id}</Typography>
          </Box>
          <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', gap: 1 }}>
  <Box sx={{ display: 'flex', gap: 2 }}>
    <IconButton aria-label="message" color="inherit">
      <MessageIcon sx={{ fontSize: 22, color: '#F8F8F8' }} />
    </IconButton>

    <IconButton aria-label="rate-your-teacher" color="inherit" onClick={() => navigateToTeacherProfile(enrollmentTeacherId)}>
      <Tooltip title="Rate your teacher" arrow>
        <StarIcon sx={{ fontSize: 22, color: '#F8F8F8' }} />
      </Tooltip>
    </IconButton>

    <IconButton aria-label="grades" color="inherit" onClick={handleClick}>
      <SettingsIcon sx={{ fontSize: 22, color: '#F8F8F8' }} />
    </IconButton>
  </Box>

  {/* Teacher's Name in second line */}
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  <Button 
      onClick={() => navigateToTeacherProfile(enrollmentTeacherId)} 
      sx={{
        width: '100%',
        color: '#050a30',
        fontWeight: 'bold',
        textTransform: 'none',
        // padding: '10px 15px',
        borderRadius: '8px',
        '&:hover': { 
          backgroundColor: '#e8f5e9', 
          transform: 'scale(1.03)',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
 
      <Box>
        <Typography variant="body3" sx={{ fontWeight: 'bold', color: '#fff',textTransform: 'uppercase'  }}>
        Teacher: {enrollmentTeacherName}
        </Typography>
    
      </Box>
    </Button>
  </Box>
</Box>
        </Box>
      </CardContent>


      <CardContent sx={{ backgroundColor: "#F8F8F8", borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
    

      <Box display="flex" justifyContent="space-between" textAlign="center" mt={1} mb={1}
         sx={{
          textAlign: 'center',
          background: '#f8f8f8',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.04)',
          borderRadius: 2,
          p: 1,
          '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' }
        }}
       
      >
<Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
  <Typography variant="h5" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
    {bookedClasses || 0}
  </Typography>
  <Typography variant="caption" color="textSecondary">Booked Classes</Typography>
</Box>
<Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
  <Typography variant="h5" sx={{ color: progress > 0 ? '#388e3c' : '#d32f2f', fontWeight: 'bold' }}>
    {progress || 0}
  </Typography>
  <Typography variant="caption" color="textSecondary">Completed Classes</Typography>
</Box>
<Box sx={{ textAlign: 'center', '&:hover': { transform: 'scale(1.05)', transition: 'transform 0.3s' } }}>
  <Typography variant="h5" sx={{ color: 0 === 0 ? '#d32f2f' : '#050a30', fontWeight: 'bold' }}>
    {0}
  </Typography>
  <Typography variant="caption" color="textSecondary">Lost Classes</Typography>
</Box>

        
              </Box>
    
            
  
  


        {/* <Typography variant="body2" sx={{ opacity: 0.85, fontSize: '0.8rem', color: '#111' }}>
          Recent Grades: <span style={{ fontWeight: 'bold' }}>A, B+</span>
        </Typography> */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 1,
            borderRadius: 2,
            background: 'linear-gradient(135deg, #7776D2 30%, #3365C0 90%)',
            color: '#fff',
            fontWeight: 'bold',
            textTransform: 'none',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: '#1565C0',
              boxShadow: 6,
              transform: 'translateY(-2px)',
            },
            '&:active': {
              backgroundColor: '#0d47a1',
              boxShadow: 3,
              transform: 'translateY(0)',
            },
            transition: 'all 0.3s ease',
          }}
          onClick={handleEditProfile}
        >
          Edit Profile
        </Button>
      </CardContent>
       

      {/* <IconButton
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 3,
        }}
        onClick={handleClick}
      >
        <SettingsIcon fontSize="small" />
      </IconButton> */}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            boxShadow: 5,
            borderRadius: 3,
          },
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Card>
       )})}
    </>
  );
};

export default UserProfileCard;