import React, { useState } from "react";
import {
  oratricslogo,
  dashboard,
  user,
 
  role,
 
  customer,
} from "../Assets/index";

const Menubar = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const menuItems = [
    { name: "Dashboard", icon: dashboard, link: "/dashboard" },
    { name: "Class Summary", icon: user, link: "/classsumery" },
    { name: "Enrichment Classes", icon: customer, link: "/Enrichmentclass" },
    { name: "Certificates", icon: customer, link: "/Cartificate" },
    { name: "Assessments", icon: customer, link: "/Assessments" },
    { name: "My Rewards", icon: role, link: "/reward" },
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div  className="flex flex-col h-full w-full lg:w-64 bg-white">
      
      {/* Logo Section */}
      <div className="flex items-center justify-center h-24">
        <img src={oratricslogo} alt="Company Logo" className="h-16 w-auto" />
      </div>

      {/* Menu Items */}
      <div className="flex-1 flex flex-col">
        {menuItems.map((item, index) => (
          <a
            key={index}
            href={item.link}
            className={`px-4 py-3 flex items-center cursor-pointer w-full ${
              selectedItem === item ? "text-white bg-purple-600" : "text-purple-700"
            } ${
              selectedItem === item ? "rounded-lg mb-2" : "hover:bg-purple-100 hover:rounded-lg mb-2"
            }`}
            onClick={() => handleItemClick(item)}
          >
            <img src={item.icon} alt={item.name} className="h-6 w-6 mr-4" />
            <span className="text-base font-medium">{item.name}</span>
          </a>
        ))}
      </div>

      {/* Footer Section */}
      <div className="px-4 py-3 mt-auto">
        <div className="flex items-center">
          <img src={customer} alt="BrightBuddy" className="h-10 w-10 mr-3" />
          <span className="text-purple-700 font-semibold">Oratrics</span>
        </div>
        <div className="mt-4 text-gray-600 text-sm">
          <p>Need Help</p>
          <a href="#" className="text-purple-600">
            Ask Oratrics
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menubar;