import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Divider,
  IconButton,
  Chip,
  Tooltip,
  Modal,
  TextField,
  Stack,
  Snackbar,
  Alert,
} from '@mui/material';
import baseUrl from '../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import SyncIcon from '@mui/icons-material/Sync';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function UpcomingClass() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [newDate, setNewDate] = useState(new Date());
  const [expandedScheduleId, setExpandedScheduleId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchUpcomingClasses = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/enrolled-courses`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const classesData = response.data[0]?.course.upcomingClasses || [];
        const sortedClasses = classesData
          .sort((a, b) => new Date(a.classDate) - new Date(b.classDate))
          .slice(0, 4);
        setUpcomingClasses(sortedClasses);
      } catch (error) {
        console.error('Error fetching upcoming classes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpcomingClasses();
  }, []);

  const toggleExpand = (scheduleId) => {
    setExpandedScheduleId(expandedScheduleId === scheduleId ? null : scheduleId);
  };

  const handleRescheduleClick = (classInfo) => {
    setSelectedSchedule(classInfo);
    setOpenModal(true);
  };

  const handleRescheduleSubmit = async () => {
    if (!selectedSchedule) return;

    try {
      const { enrollmentId, _id: classScheduleId } = selectedSchedule;
      const response = await axios.post(
        `${baseUrl}api/students/reschedule-request`,
        { enrollmentId, classScheduleId, newDate },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      console.log('Reschedule successful:', response.data);
      setOpenSnackbar(true);
      setOpenModal(false);
    } catch (error) {
      console.error('Error submitting reschedule request:', error);
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => setOpenSnackbar(false);
  const handleAddScheduleClick = () => navigate('/classsummary');

  const classesToShow = upcomingClasses.length > 0
    ? upcomingClasses
    : new Array(4).fill({
        classDate: 'Loading...',
        time: 'Loading...',
        joinUrl: '#',
        teacherName: 'Loading...',
        teacherAvatar: 'https://via.placeholder.com/100',
        dayOfWeek: 'Loading...',
        status: 'Loading...',
        topics: [],
        isRescheduleRequested: false,
        rescheduleApprovalStatus: 'Loading...',
      });

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed': return '#2e7d32';
      case 'pending': return '#0288d1';
      default: return '#757575';
    }
  };

  return (
    <>
        <Card
      sx={{
        borderRadius: 3,
        boxShadow: 5,
        background: 'linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%)',
        maxWidth: 500,
        mx: 'auto',
        p: 1.5,
        // pb: 5,
        transition: 'transform 0.3s ease-in-out',
        '&:hover': { transform: 'scale(1.05)' },
      }}
    >
  <CardContent sx={{ p: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h6" sx={{ fontWeight: 700, color: '#1e293b' }}>
        Upcoming Classes
      </Typography>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleAddScheduleClick}
        sx={{
          textTransform: 'none',
          fontWeight: 600,
          color: '#1e3a8a',
          borderColor: '#1e3a8a',
          borderRadius: '12px',
          px: 2,
          py: 0.5,
          '&:hover': {
            backgroundColor: '#dbeafe',
            borderColor: '#1e3a8a',
          },
        }}
      >
        View All Classes
      </Button>
    </Box>
        <Divider sx={{ my: 2 }} />

    <Box sx={{ overflowY: 'auto', flex: 1 }}> {/* Allow scrolling if content overflows */}
      {classesToShow.map((classInfo, index) => (
     <Box
     key={index}
     sx={{
       mb: 1, // Reduced margin
       p: 1, // Reduced padding
       borderRadius: '12px',
       backgroundColor: '#ffffff',
       boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
       transition: 'all 0.2s ease',
       '&:hover': {
         boxShadow: '0 6px 18px rgba(0,0,0,0.1)',
         transform: 'translateY(-2px)',
       },
       display: 'flex',
       flexDirection: { xs: 'column', sm: 'row' },
       gap: 1,
       alignItems: { xs: 'flex-start', sm: 'center' },
     }}
   >
    {/* Avatar Section */}
    <Avatar
      src={classInfo.teacherAvatar || 'https://via.placeholder.com/100'}
      sx={{
        width: { xs: 48, sm: 56 }, // Slightly larger avatar for better visibility
        height: { xs: 48, sm: 56 },
        border: '2px solid #3b82f6',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
      }}
    />
  
    {/* Text Section */}
    <Box sx={{ flex: 1 }}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: '#1e293b',
          fontSize: { xs: '.9rem', sm: '1rem' }, // Increased font size for better readability
          mb: 1, // Increased bottom margin for spacing
        }}
      >
        {classInfo.topics?.[0]?.title || 'Class Title'}
      </Typography>
  
      {/* Time and Date Information */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0 }}>
        <Typography
          variant="body2"
          sx={{ color: '#64748b', display: 'flex', alignItems: 'center' }}
        >
          <AccessTimeIcon sx={{ fontSize: 18, mr: 0.75, color: '#3b82f6' }} />
          {classInfo.time} • {classInfo.dayOfWeek}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: '#64748b', display: 'flex', alignItems: 'center' }}
        >
          <EventIcon sx={{ fontSize: 18, mr: 0.75, color: '#3b82f6' }} />
          {new Date(classInfo.classDate).toLocaleDateString()}
        </Typography>
      </Box>
  
      {/* Status & Reschedule Chips */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
        <Chip
          label={`Status: ${classInfo.status}`}
          size="small"
          sx={{
            bgcolor: getStatusColor(classInfo.status),
            color: '#fff',
            fontWeight: 500,
            borderRadius: '8px',
          }}
        />
        {classInfo.isRescheduleRequested && (
          <Tooltip title={`Reschedule Status: ${classInfo.rescheduleApprovalStatus}`}>
            <Chip
              icon={<SyncIcon sx={{ fontSize: 14 }} />}
              label="Reschedule Requested"
              size="small"
              sx={{ bgcolor: '#fef3c7', color: '#d97706', borderRadius: '8px' }}
            />
          </Tooltip>
        )}
      </Box>
  
   
    </Box>
  
    {/* Button and Action Section */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        size="small"
        href={classInfo.joinUrl}
        target="_blank"
        rel="noopener noreferrer"
        disabled={classInfo.status === 'completed'}
        sx={{
          borderColor: '#1e3a8a',
          color: '#fff',
          borderRadius: '20px',
          px: 2,
          py: 1,
          textTransform: 'none',
          '&:hover': { bgcolor: '#dbeafe', borderColor: '#1e3a8a' },
        }}
      >
        Join Class
        <LinkIcon sx={{ ml: 0.5, fontSize: 18 }} />
      </Button>
  
      {classInfo.isRescheduleRequested ? (
        <IconButton
          onClick={() => toggleExpand(classInfo._id)}
          sx={{ color: '#3b82f6' }}
        >
          {expandedScheduleId === classInfo._id ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          onClick={() => handleRescheduleClick(classInfo)}
          sx={{
            borderColor: '#1e3a8a',
            color: '#1e3a8a',
            borderRadius: '20px',
            // px: 2,
            // py: 1,
            textTransform: 'none',
            '&:hover': { bgcolor: '#dbeafe', borderColor: '#1e3a8a' },
          }}
        >
          <CalendarTodayIcon sx={{ mr: 0.2, fontSize: 10 }} />
          Reschedule
        </Button>
      )}
    </Box>
  </Box>
  
      ))}
    </Box>
  </CardContent>
</Card>
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 400 },
            bgcolor: '#ffffff',
            boxShadow: '0 12px 40px rgba(0,0,0,0.2)',
            p: 3,
            borderRadius: '16px',
            border: '1px solid #e5e7eb',
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: 600, color: '#1e3a8a', textAlign: 'center' }}
          >
            Reschedule Class
          </Typography>
          <TextField
            label="New Date & Time"
            type="datetime-local"
            fullWidth
            value={newDate.toISOString().slice(0, 16)}
            onChange={(e) => setNewDate(new Date(e.target.value))}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleRescheduleSubmit}
              sx={{
                bgcolor: '#1e3a8a',
                borderRadius: '12px',
                px: 3,
                '&:hover': { bgcolor: '#1e40af' },
              }}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenModal(false)}
              sx={{
                borderColor: '#1e3a8a',
                color: '#1e3a8a',
                borderRadius: '12px',
                px: 3,
                '&:hover': { bgcolor: '#dbeafe', borderColor: '#1e3a8a' },
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}
        >
          Reschedule request submitted successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default UpcomingClass;