import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  } from '@mui/material';
  import baseUrl from "../config";
  import LockIcon from '@mui/icons-material/Lock'; // Import Lock Icon from Material UI

import axios from "axios";
function BadgesSection() {
    const [unlockedBatches, setUnlockedBatches] = useState([]);
    const [lockedBatches, setLockedBatches] = useState([]);
    const [celebrationVisible, setCelebrationVisible] = useState(false);

  const getBatchImage = (batchId) => {
    try {
      return require(`../Assets/batch/${batchId}.png`);
    } catch (error) {
      return '';
    }
  };

    const user = localStorage.getItem('user');
    const parsedUser = JSON.parse(user);
  
    
  
    useEffect(() => {
      const fetchStudentAchievements = async () => {
        try {
          const response = await axios.get(`${baseUrl}api/students/achievment/${parsedUser.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (response.data) {
            const unlocked = response.data.unlockedBatches || [];
            const locked = response.data.lockedBatches || [];
            setUnlockedBatches(unlocked);
            setLockedBatches(locked);
          }
        } catch (error) {
          console.error('Error fetching student achievements:', error);
        }
      };
    
      fetchStudentAchievements();
    }, []);
    const handleBatchClick = () => {
        setCelebrationVisible(true);
        setTimeout(() => setCelebrationVisible(false), 5000);
      };
    
  return (
    <Card
      sx={{
        borderRadius: 6,
        boxShadow: 4,
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        backgroundColor: '#f9fafb',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: '#2c3e50',
            textTransform: 'uppercase',
            letterSpacing: 1.2,
            mb: 3,
            textAlign: 'center',
          }}
        >
          Achievement Badges
        </Typography>

        {/* Badges Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            overflowX: 'auto',
            pb: 2,
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {/* Display Unlocked Batches */}
          {unlockedBatches.map((batch, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: { xs: 100, sm: 150, md: 170, lg: 220 },
                height: { xs: 100, sm: 150, md: 170, lg: 220 },
                flex: '0 0 auto',
                mr: 2,
              }}
            >
              <img
                src={getBatchImage(batch.batchId)}
                alt={batch.title}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
                onClick={handleBatchClick}
              />
            </Box>
          ))}

          {/* Display Locked Batches */}
          {lockedBatches.map((batch, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: { xs: 100, sm: 150, md: 170, lg: 220 },
                height: { xs: 100, sm: 150, md: 170, lg: 220 },
                flex: '0 0 auto',
                mr: 2,
              }}
            >
              <img
                src={getBatchImage(batch.batchId)}
                alt={batch.title}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
                onClick={handleBatchClick}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '70%',
                  height: '70%',
                }}
              >
                <LockIcon sx={{ color: '#fff', fontSize: { xs: 30, md: 60 } }} />
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>  )
}

export default BadgesSection