// src/components/PptViewer.js
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PptViewer = ({ fileUrl }) => (
  <div style={{ height: '100vh' }}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
      <Viewer fileUrl={fileUrl} />
    </Worker>
  </div>
);

export default PptViewer;
