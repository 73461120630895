import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress
} from '@mui/material';

function Syllabus({ courseData }) {
  // Extract the first 5 chapters from the courseData
  const chapters = courseData.chapters.slice(0, 5);

  return (
    <Card
      sx={{
        borderRadius: 8,
        boxShadow: 4,
        backgroundColor: '#ffffff',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: '#34495e',
            letterSpacing: 1.2,
            textTransform: 'uppercase',
            mb: 3,
            textAlign: 'center',
          }}
        >
          Syllabus
        </Typography>
        <Typography variant="body2" color="success.main" sx={{ mb: 3, textAlign: 'center' }}>
          Results derived from syllabus completion in the respective classes.
        </Typography>
        {chapters.map((chapter, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                color: '#34495e',
                mb: 1,
                textTransform: 'capitalize',
              }}
            >
              {chapter.title} {/* Display the chapter title */}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={chapter.topics.length > 0 ? 100 : 0} // Progress based on whether topics exist
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: '#ecf0f1',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  background: `linear-gradient(90deg, #ff7e5f ${chapter.topics.length > 0 ? 100 : 0}%, #feb47b ${chapter.topics.length > 0 ? 100 : 0}%)`, // gradient effect
                },
              }}
            />
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}

export default Syllabus;