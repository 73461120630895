import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, portrait } from "../Assets/index";
import "../index.css"; // Adjust if needed
import { Button, CircularProgress } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import baseUrl from "../config";
import axios from "axios";
import {useAuth} from "../useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  useAuth(); // Check for authentication and redirect if needed

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return !/\s/.test(password); // Check for spaces
  };

  const loginUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    // Validate email and password
    if (!validateEmail(email)) {
      showSnackbar("Please enter a valid email address.", "error");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      showSnackbar("Password should not contain spaces.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}api/students/login`, {
        email,
        password,
      }, {
        headers: {
          "Content-type": "application/json",
        },
      });

      const data = response;

      if (data.status === 200) {
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem("user", JSON.stringify({
          email: data.data.email,
          name: data.data.name,
          role: data.data.role,
          grade: data.data.grade,
          uniqueId: data.data.uniqueId,
          timezone: data.data.timezone,
          referralCode: data.data.referralCode
        }));
        navigate("/");
      } else {
        showSnackbar("Invalid email or password", "error");
      }
    } catch (error) {
      showSnackbar(error?.response?.data?.message || "An error occurred while trying to log in. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="app-container">
      <div className="image-section">
        <img className="background-img" src={portrait} alt="Background" />
        <img className="logo" src={oratricslogo} alt="Oratrics Logo" />
      </div>
      <div className="login-section">
        <h1 className="welcome-text" style={{ color: '#050a30' }}>
          Welcome to <span style={{ color: '#8b0000' }}>Oratrics</span>
        </h1>
        <form className="mx-auto mt-4 text-left" onSubmit={loginUser}>
          <div className="mb-8">
            <h2 className="text-3xl font-bold mb-2 text-gray-600 text-left" style={{ color: '#050a30' }}>
              Login
            </h2>
          </div>
          <div className="text-left">
            <label className="block mb-1">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              autoComplete="email"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left mb-4">
            <label className="block mb-1">Password</label>
            <div className="relative">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                className="w-full px-4 py-2 border rounded-lg pr-10"
              />
              <div
                onClick={handleClickShowPassword}
                className="absolute inset-y-0 right-0 flex items-center pr-5 pb-4 cursor-pointer"
              >
                {showPassword ? <VisibilityOff className="text-gray-500" /> : <Visibility className="text-gray-500" />}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <Button
              type="submit"
              className="bg-lime-500 text-white py-2 px-4 rounded-sm"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
            </Button>
          </div>
          <span className="text-sm text-gray-500 cursor-pointer">
            Forget password?
          </span>
        </form>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
