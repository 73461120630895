import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, InputBase, Avatar, Popover, Badge, Tooltip, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, SearchOutlined, NotificationsOutlined, MessageOutlined, LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [chatAnchorEl, setChatAnchorEl] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect mobile screen sizes (up to medium screens)

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleNotificationClick = (event) => setNotificationAnchorEl(event.currentTarget);
  const handleNotificationClose = () => setNotificationAnchorEl(null);
  const handleChatClick = (event) => setChatAnchorEl(event.currentTarget);
  const handleChatClose = () => setChatAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate("/login");
  };

  return (
    <AppBar position="sticky" sx={{
      background: 'linear-gradient(90deg, rgba(32, 45, 54, 0.8), rgba(0, 0, 0, 0.7))',
      boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
    }}>
      <Toolbar sx={{ justifyContent: 'space-between', padding: '12px 24px', alignItems: 'center' }}>
        
        {/* Mobile: Sidebar Toggle Button (Hamburger) */}
        <Tooltip title="Toggle Sidebar" arrow>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon sx={{ fontSize: '2rem', color: theme.palette.primary.light }} />
          </IconButton>
        </Tooltip>
     

        {/* Search Bar */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffffff90',
          borderRadius: '30px',
          padding: '8px 16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: isMobile ? '60%' : '220px',
          transition: 'width 0.3s ease'
        }}>
          <SearchOutlined sx={{ color: theme.palette.text.primary }} />
          <InputBase placeholder="Search..." sx={{
            marginLeft: '8px',
            width: '100%',
            color: theme.palette.text.primary,
            fontSize: '14px'
          }} />
        </Box>

        {/* Icons Section */}
        <Box sx={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          flexDirection: isMobile ? 'row' : 'row',
          justifyContent: isMobile ? 'space-around' : 'flex-end',
          width: isMobile ? '50%' : 'auto',
        }}>
          {/* Notifications Icon */}
          <Tooltip title="Notifications" arrow>
            <IconButton onClick={handleNotificationClick}>
              <Badge badgeContent={0} color="error">
                <NotificationsOutlined sx={{ fontSize: '1.8rem', color: theme.palette.primary.light }} />
              </Badge>
            </IconButton>
          </Tooltip>

          {/* Chats Icon */}
          <Tooltip title="Chats" arrow>
            <IconButton onClick={handleChatClick}>
              <Badge badgeContent={0} color="error">
                <MessageOutlined sx={{ fontSize: '1.8rem', color: theme.palette.primary.light }} />
              </Badge>
            </IconButton>
          </Tooltip>

          {/* User Avatar */}
          <Tooltip title={user?.name || 'User'} arrow>
            <IconButton onClick={handleClick}>
              <Avatar alt={user?.name || 'User'} src={user?.avatar || ''} sx={{ width: 36, height: 36 }} />
            </IconButton>
          </Tooltip>

          {/* Menu for Logout */}
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleLogout} sx={{ color: '#ff5c5c' }}>
              <LogoutOutlined sx={{ marginRight: '8px', fontSize: '1.3rem' }} /> Log Out
            </MenuItem>
          </Menu>

          {/* Notifications Popover */}
          <Popover
            open={Boolean(notificationAnchorEl)}
            anchorEl={notificationAnchorEl}
            onClose={handleNotificationClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ padding: '0px' }}
          >
            <Box sx={{
              width: '320px', padding: '1.5rem', backgroundColor: '#fff', borderRadius: '12px',
              boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)', animation: 'fadeIn 0.3s ease-out'
            }}>
              <Typography variant="h6" sx={{
                fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center', fontFamily: '"Roboto", sans-serif',
                color: theme.palette.text.primary
              }}>
                Notifications
              </Typography>
              <Box sx={{ padding: '0.8rem', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '1rem' }}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  No Notifications
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  onClick={handleNotificationClose}
                  style={{
                    padding: '0.5rem 1rem', backgroundColor: '#ff5c5c', color: '#fff', border: 'none',
                    borderRadius: '6px', cursor: 'pointer', fontWeight: '600', fontSize: '14px'
                  }}
                >
                  Close
                </button>
              </Box>
            </Box>
          </Popover>

          {/* Chats Popover */}
          <Popover
            open={Boolean(chatAnchorEl)}
            anchorEl={chatAnchorEl}
            onClose={handleChatClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box sx={{
              width: '320px', padding: '1.5rem', backgroundColor: '#fff', borderRadius: '12px',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)', animation: 'fadeIn 0.3s ease-out'
            }}>
              <Typography variant="h6" sx={{
                fontWeight: '600', marginBottom: '1rem', textAlign: 'center', fontFamily: '"Roboto", sans-serif',
                color: theme.palette.text.primary
              }}>
                Chats
              </Typography>
              <Box sx={{
                padding: '0.8rem', backgroundColor: '#fafafa', borderRadius: '8px', marginBottom: '1rem'
              }}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  No Messages
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
