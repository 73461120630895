import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Chip,
  LinearProgress
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import baseUrl from '../config';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  border: '2px solid #1a73e8',
  color: '#1a73e8',
  padding: '12px 30px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#e3f2fd',
    borderColor: '#1565c0',
  },
}));

const ClassCard = ({ classData, isUpcoming, isNextClass }) => {
  const { classNumber, dayOfWeek, startDate, joinUrl } = classData || {};
  const isClassOver = new Date(startDate) < new Date();

  return (
    <Card sx={{
      boxShadow: 5,
      borderRadius: 3,
      transition: 'transform 0.3s ease, box-shadow 0.3s',
      backgroundColor: isClassOver ? '#e0f7fa' : '#ffffff',
      '&:hover': !isClassOver && {
        transform: 'scale(1.05)',
        boxShadow: 10,
      },
    }}>
      <CardHeader
        title={`Class #${classNumber}`}
        subheader={`Day: ${dayOfWeek}`}
        action={<Chip label={isClassOver ? 'Class Over' : 'Upcoming'} color={isClassOver ? 'error' : 'success'} />}
        sx={{
          backgroundColor: '#00796b',
          color: 'white',
          borderRadius: '8px 8px 0 0',
          padding: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      />
      <CardContent sx={{ padding: 2 }}>
        {/* Next Class Highlighting */}
        <Typography 
          variant="h6" 
          sx={{
            fontWeight: isNextClass ? 'bold' : 'normal',
            color: isNextClass ? '#FF5733' : 'textSecondary',
            marginBottom: 2
          }}
        >
          {isNextClass ? 'Next Class' : 'Class Date'}: 
          <strong> {new Date(startDate).toLocaleDateString()}</strong>
        </Typography>

        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <AccessTimeIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2" color="textSecondary">
            Class Time: {new Date(startDate).toLocaleTimeString()}
          </Typography>
        </Typography>

        {/* Progress bar for class start time */}
        {!isClassOver && (
          <LinearProgress
            variant="determinate"
            value={((new Date() - new Date(startDate)) / (1000 * 60 * 60)) * 100}
            sx={{
              marginTop: 2,
              borderRadius: 2,
              height: 6,
              backgroundColor: '#81C784',
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

const ClassSummary = () => {
  const [classesData, setClassesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClassSchedules = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/totalclass`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setClassesData(response.data.classSchedules || []);
      } catch (err) {
        setError(err?.response?.data?.message || 'An error occurred while fetching schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchClassSchedules();
  }, []);

  if (loading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  if (error) return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  // Sort the classes to display upcoming ones first
  const upcomingClasses = [];
  const pastClasses = [];
  let nextClass = null;

  classesData[0]?.classOccurrences?.forEach((classData) => {
    if (new Date(classData.startDate) > new Date()) {
      upcomingClasses.push(classData);
    } else {
      pastClasses.push(classData);
    }
  });

  // Determine which class is the next one
  if (upcomingClasses.length > 0) {
    nextClass = upcomingClasses.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0];
  }

  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      background: 'linear-gradient(135deg, #e0f7fa, #80deea)',
      borderRadius: 4,
      boxShadow: 3,
      overflowY: 'auto',
    }}>
      <Typography variant="h4" gutterBottom align="center" sx={{
        fontWeight: 700,
        color: '#00796b',
        textTransform: 'uppercase',
        letterSpacing: 1,
        marginBottom: 4
      }}>
        Class Summary
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: 3, color: '#FF5733' }}>
        Next Class
      </Typography>

      <Grid container spacing={4}>
        {nextClass ? (
          <Grid item xs={12} sm={6} md={4} key={nextClass.classNumber}>
            <ClassCard classData={nextClass} isUpcoming={true} isNextClass={true} />
          </Grid>
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Upcoming Classes
      </Typography>
      <Grid container spacing={4}>
        {upcomingClasses.length > 0 ? (
          upcomingClasses.map((classData) => (
            <Grid item xs={12} sm={6} md={4} key={classData.classNumber}>
              <ClassCard classData={classData} isUpcoming={true} isNextClass={false} />
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Past Classes
      </Typography>
      <Grid container spacing={4}>
        {pastClasses.length > 0 ? (
          pastClasses.map((classData) => (
            <Grid item xs={12} sm={6} md={4} key={classData.classNumber}>
              <ClassCard classData={classData} isUpcoming={false} isNextClass={false} />
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No past classes
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default ClassSummary;
