import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ManubarPage from './Pages/ManubarPage';

const Layout = () => {
  // State to hold if the view is mobile or not
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update state based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <ManubarPage />
      <main
        className={`flex-1 overflow-auto ${isMobile ? 'pt-20' : ''}`} // Apply padding only in mobile view
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;




// import React, { useState, useEffect } from 'react';
// import { Outlet } from 'react-router-dom';
// import ManubarPage from './Pages/ManubarPage';
// import SideScreen from './Components/SideScreen';

// const Layout = () => {
//   // State to hold if the view is mobile or not
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   // Update state based on window width
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     // Add event listener for resize
//     window.addEventListener('resize', handleResize);

//     // Cleanup event listener on component unmount
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
// <>
//     {!isMobile ?<div className="flex flex-col lg:flex-row h-screen overflow-hidden">
//       <ManubarPage />
//       <main
//         className={`flex-1 overflow-auto ${isMobile ? 'p-4' : 'lg:pl-50'} transition-all duration-300`} // Adjust padding for larger screens
//       >
//         <Outlet />
//       </main>
//         <aside
//           className="hidden lg:block fixed top-0 right-0 h-full w-100 bg-gray-200 shadow-lg z-10" // Fixed position for larger screens
//         >
//           <SideScreen />
//         </aside>
    
//     </div>
//     :


// <div className="flex flex-col lg:flex-row h-screen">
// <ManubarPage />
// <main
//   className={`flex-1 overflow-auto ${isMobile ? 'pt-20' : 'lg:pl-80'}`} // Apply padding only in mobile view, margin for larger screens
// >
//   <Outlet />
//   <aside
//   >
//     <SideScreen />
//   </aside>

//   </main>


// </div>}
// </>
//   );
// };

// export default Layout;
