// src/components/PdfViewer.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'pdfjs-dist';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IconButton, Box, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';

const PdfViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fileUrl = location.state?.fileUrl;
  const [scale, setScale] = useState(1.0); // Initial zoom scale
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const zoomIn = () => {
    setScale(prevScale => Math.min(prevScale * 1.2, 3.0)); // Increase zoom, limit to 3x
  };

  const zoomOut = () => {
    setScale(prevScale => Math.max(prevScale / 1.2, 0.5)); // Decrease zoom, limit to 0.5x
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box 
      sx={{ 
        height: '100vh', 
        position: 'relative', 
        display: 'flex', 
        flexDirection: 'column'
      }}
    >
      {/* Back Button with Icon */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: '20px', 
          left: '20px',
          display: 'flex',
          alignItems: 'center',
          zIndex: 1
        }}
      >
        <IconButton onClick={handleBackClick} color="primary" aria-label="go back">
          <ArrowBackIcon />
        </IconButton>
        <Typography 
          sx={{ 
            ml: 1, 
            color: 'text.primary', 
            fontSize: '16px' 
          }}
        >
          Back
        </Typography>
      </Box>

      {/* Zoom Controls */}
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          zIndex: 1
        }}
      >
        <IconButton onClick={zoomIn} color="primary" aria-label="zoom in">
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={zoomOut} color="primary" aria-label="zoom out">
          <ZoomOutIcon />
        </IconButton>
      </Box>

      {/* PDF Viewer */}
      <Box 
        sx={{ 
          flex: 1,
          p: 2,
          overflow: 'auto' // Enable scrolling if the PDF is too large
        }}
      >
        {fileUrl ? (
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{ workerSrc: `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js` }}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        ) : (
          <Typography variant="h6" align="center">
            No PDF selected.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PdfViewer;
