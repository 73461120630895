import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, newlogin } from "../Assets/index";
import "../index.css"; // Adjust if needed
import { Button, CircularProgress, TextField, Grid, Box, Typography, FormControlLabel, Checkbox, Link, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import baseUrl from "../config";
import axios from "axios";
import { useAuth } from "../useAuth";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  useAuth(); // Check for authentication and redirect if needed

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return !/\s/.test(password); // Check for spaces
  };

  const loginUser = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Validate email and password
    if (!validateEmail(email)) {
      showSnackbar("Please enter a valid email address.", "error");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      showSnackbar("Password should not contain spaces.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}api/students/login`, {
        email,
        password,
      }, {
        headers: {
          "Content-type": "application/json",
        },
      });

      const data = response;

      if (data.status === 200) {
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem("user", JSON.stringify({
          email: data.data.email,
          name: data.data.name,
          role: data.data.role,
          grade: data.data.grade,
          uniqueId: data.data.uniqueId,
          timezone: data.data.timezone,
          referralCode: data.data.referralCode
        }));
        navigate("/");
      } else {
        showSnackbar("Invalid email or password", "error");
      }
    } catch (error) {
      showSnackbar(error?.response?.data?.message || "An error occurred while trying to log in. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
    <Grid container sx={{ height: '100vh' }}>
      {/* Left Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#f8f8f8',
          padding: 4,
        }}
      >
  <Box 
  sx={{ 
    textAlign: 'center', 
    mb: 3, 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '100%' 
  }}
>
  {/* Logo */}
  <img src={oratricslogo} alt="Oratrics Logo" width="250" />
  <Typography
    variant="h5"
    sx={{ fontWeight: 'bold', mt: 8 }}  // Increased margin-top (mt: 4)
    gutterBottom
  >
    Welcome Back
  </Typography>
  <Typography variant="body2" color="text.secondary">
    Glad to see you again! Please log in to continue.
  </Typography>
</Box>


        {/* Form */}
        <Box
          component="form"
          sx={{
            width: '100%',
            maxWidth: 400,
          }}
          onSubmit={loginUser}
        >
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: <EmailIcon color="action" />,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            InputProps={{
              startAdornment: <LockOutlinedIcon color="action" />,

              endAdornment: (
                <VisibilityOffOutlinedIcon
                  color="action"
                  onClick={handleClickShowPassword}
                  style={{ cursor: 'pointer' }}
                />
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* Remember me & Forgot Password */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="Remember me"
            />
            <Link href="#" variant="body2" underline="hover">
              Forgot password?
            </Link>
          </Box>

          {/* Log In Button */}
          <Button
            variant="contained"
            fullWidth
            color="primary"
            sx={{
              mt: 3,
              bgcolor: '#050A30',
              ':hover': { bgcolor: '#7b85ce' },
            }}
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
          </Button>
        </Box>

        {/* Footer */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 3, textAlign: 'center' }}
        >
          © 2024 Oratrics. All rights reserved
        </Typography>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundImage: `url(${newlogin})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Grid>

    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </Snackbar></>
  );
};

export default Login;
