import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Divider,
  Checkbox,
} from '@mui/material';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import UserProfileCard from "./UserProfileCard";
import baseUrl from "../config";
import axios from "axios";

import UpcomingClass from './UpcomingClass';
import BadgesSection from './BadgesSection';
import NoticeBoard from './NoticeBoard';
import Syllabus from './Syllabus';
import SchedulesClass from './SchedulesClass';
import { useNavigate } from 'react-router-dom';
ChartJS.register(ArcElement, Tooltip, Legend);


const activities = [
  { task: "Extra Class Info to Students", time: "04:55 PM", status: "Yet To Start" },
];


const TestDashboard = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Snackbar state
  
const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState({
    attendanceSummary: {
      present: 0,
      absent: 0,
      rescheduled: 0,
      pending: 0,
    },
    attendanceDays: [],
  });

  const { present, absent, rescheduled, pending } = attendanceData.attendanceSummary;

  const doughnutData = {
    labels: ['Present', 'Absent', 'Rescheduled', 'PendingClass'],
    datasets: [
      {
        data: [present, absent, rescheduled, pending],
        backgroundColor: ['#4caf50', '#f44336', '#ff9800', '#9e9e9e'],
        borderWidth: 3,
        borderColor: '#ffffff',
      },
    ],
  };
  const [courseData, setCourseData] = useState(null);

  // Fetch course data from the API
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await fetch(baseUrl + 'api/students/new/my-course', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourseData();
  }, []);

  const fetchAttendance = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/students/attendance`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setAttendanceData(response.data);
    } catch (err) {
      console.error('Failed to fetch attendance data:', err);
    }
  };


  useEffect(() => {
    fetchAttendance();
  }, []);


  const handleAddScheduleClick = () => navigate('/history');

  return (

    <Box sx={{   }}> {/* Flex container with wrap */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>

          < >
            <UserProfileCard />
          </>

       
        </Grid>

        {/* Attendance Card */}
        <Grid item xs={12} md={6} lg={4}>
     <Card
         sx={{
           borderRadius: 3,
           boxShadow: 5,
           background: 'linear-gradient(135deg, #f7f9fc 0%, #ffffff 100%)',
           maxWidth: 500,
           mx: 'auto',
           p: 1.5,
           // pb: 5,
           transition: 'transform 0.3s ease-in-out',
           '&:hover': { transform: 'scale(1.05)' },
         }}
       >
      <CardContent sx={{ p: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 700, color: '#1e293b' }}>
            Attendance Overview
          </Typography>
          <Button
            size="small"
            variant="outlined"

            onClick={handleAddScheduleClick}

            sx={{
              textTransform: 'none',
              fontWeight: 600,
              color: '#1e3a8a',
              borderColor: '#1e3a8a',
              borderRadius: '12px',
              px: 2,
              py: 0.5,
              '&:hover': {
                backgroundColor: '#dbeafe',
                borderColor: '#1e3a8a',
              },
            }}
          >
            All Attendance
          </Button>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ mt: 3, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 2 }}
        >
          <Box textAlign="center">
            <Typography variant="body2" sx={{ fontWeight: 500, color: '#4caf50' }}>
              Present
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 700, color: '#4caf50' }}>
              {present}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="body2" sx={{ fontWeight: 500, color: '#f44336' }}>
              Absent
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 700, color: '#f44336' }}>
              {absent}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="body2" sx={{ fontWeight: 500, color: '#ff9800' }}>
              Rescheduled
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 700, color: '#ff9800' }}>
              {rescheduled}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="body2" sx={{ fontWeight: 500, color: '#9e9e9e' }}>
            Pending Class
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 700, color: '#9e9e9e' }}>
              {pending}
            </Typography>
          </Box>
        </Box>

        <Box mt={3} display="flex" justifyContent="center">
          <Box sx={{ width: 240, height: 240 }}>
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      font: {
                        weight: 'bold',
                        size: 14,
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.label || '';
                        if (context.parsed !== null) {
                          label += `: ${context.parsed}`;
                        }
                        return label;
                      },
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 4, backgroundColor: '#f5f5f5', border: '1px solid #e0e0e0', borderRadius: 2, p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#1e293b' }}>
            Last 7 Days Report
          </Typography>

          <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
          {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => {
  const dayStatus = attendanceData.attendanceDays?.[index] || 'noData'; // Fallback if undefined
  let bgColor = '#e0e0e0'; // Default: No Data
  let text = day;

  if (dayStatus === 'completed') bgColor = '#4caf50';
  if (dayStatus === 'absent') bgColor = '#f44336';
  if (dayStatus === 'rescheduled') bgColor = '#ff9800';
  if (dayStatus === 'pending') bgColor = '#9e9e9e';

  return (
    <Box key={index} textAlign="center">
      <Box
        sx={{
          display: 'inline-block',
          width: 28,
          height: 28,
          backgroundColor: bgColor,
          color: '#fff',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 600,
          marginBottom: 1,
        }}
      >
        {text}
      </Box>
    </Box>
  );
})}

          </Box>
        </Box>
      </CardContent>
    </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
        <UpcomingClass />

        </Grid>
      </Grid>



      {/* Badges Section */}
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <BadgesSection />
        </Grid>
      </Grid>


        {/* Notice Board */}

      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6} lg={4}>
        <NoticeBoard/>
        </Grid>

        {/* Syllabus */}
        <Grid item xs={12} md={6} lg={4}>
        {courseData && <Syllabus courseData={courseData} />}
        </Grid>


        {/* Activity Classes */}
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              borderRadius: 8,
              boxShadow: 4,
              backgroundColor: '#ffffff',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <CardContent sx={{ p: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  color: '#34495e',
                  letterSpacing: 1.2,
                  textTransform: 'uppercase',
                  mb: 3,
                  textAlign: 'center',
                }}
              >
                Activity Classes
              </Typography>
              {activities.map((activity, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                  <Checkbox
                    checked={activity.status === 'Completed'}
                    sx={{
                      color: activity.status === 'Completed' ? '#27ae60' : '#f39c12',
                      '&.Mui-checked': {
                        color: activity.status === 'Completed' ? '#27ae60' : '#f39c12',
                      },
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        color: '#34495e',
                        letterSpacing: 0.5,
                      }}
                    >
                      {activity.task}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {activity.time}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color={
                      activity.status === 'Completed'
                        ? 'success'
                        : activity.status === 'Inprogress'
                          ? 'info'
                          : 'warning'
                    }
                    size="small"
                    sx={{
                      textTransform: 'none',
                      ml: 2,
                      borderRadius: 2,
                      fontWeight: 600,
                      '&:hover': { transform: 'scale(1.05)' },
                    }}
                  >
                    {activity.status}
                  </Button>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
   
    </Box>

  );
};

export default TestDashboard;




