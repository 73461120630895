import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography, Chip, Box, CircularProgress, Fade } from '@mui/material';
import { AccessTime, CheckCircle, ErrorOutline, Event } from '@mui/icons-material';
import baseUrl from '../config';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
const StudentHistory = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [completedCount, setCompletedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
const navigate = useNavigate();
  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const response = await fetch(`${baseUrl}api/students/performance`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const data = await response.json();
        if (data.attendance) {
          setAttendanceData(data.attendance);
          // Calculate completed and pending counts
          let completed = 0;
          let pending = 0;
          data.attendance.forEach(attendance => {
            if (attendance.status === 'completed') {
              completed++;
            } else if (attendance.status === 'pending') {
              pending++;
            }
          });
          setCompletedCount(completed);
          setPendingCount(pending);
        }
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={60} sx={{ color: '#1e88e5' }} />
      </Box>
    );
  }

  return (
    <Fade in={!loading}>
          <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            background: 'linear-gradient(135deg, #F8F8F8, #ffffff)',
            borderRadius: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)', // Softer shadow for professionalism
            overflowY: 'auto',
            position: 'relative',
            minHeight: '100vh',
          }}
        >
      
           {/* <Typography
               variant="h1" // Use a larger variant for desktop
               sx={{
                 fontWeight: "bold",
                 color: "primary.main", // Use theme primary color or any custom color
                 fontSize: {
                   xs: "2.5rem", // Smaller font size for mobile
                 },
                 lineHeight: {
                   xs: 1.2, // Tighter line height for mobile
                   md: 1.3, // Slightly looser for desktop
                 },
                 fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
                 textTransform: "uppercase", // Uppercase text for a bold look
                 letterSpacing: {
                   xs: "0.05em", // Slightly tighter letter spacing for mobile
                   md: "0.1em", // Wider letter spacing for desktop
                 },
                 background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
                 WebkitBackgroundClip: "text", // Clip background to text
                 WebkitTextFillColor: "transparent", // Make text transparent to show gradient
                 display: "inline-block", // Ensure gradient applies only to text
                 textAlign: "center", // Center align text
                 mb: 2, // Margin bottom
               }}
             >
          Your Attendance Journey
          </Typography> */}
<Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333',
          
          fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text






           }}>
      Your Attendance Journey
    </Typography>
    <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
      Next Steps in Your Attendance Journey
    </Typography>
  </Box>
  <Box sx={{ flexGrow: 1 }} />

</Box>


<Box
  sx={{
    backgroundColor: '#e0f2fe',
    padding: 2,
    borderRadius: 2,
    textAlign: 'center',
    mb: 4,
    fontSize: '1.1rem',
    fontWeight: 500,
    color: '#0c4a6e',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' }, // Stack items vertically on small screens (mobile)
    gap: { xs: 3, sm: 2 }, // Add spacing between items for smaller screens
  }}
>
  <Box sx={{ textAlign: 'center', flex: 1 }}>
    <Typography sx={{ fontWeight: '600', color: 'success.main' }}>
      Completed Attendance
    </Typography>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'success.dark' }}>
      {completedCount}
    </Typography>
  </Box>
  <Box sx={{ textAlign: 'center', flex: 1 }}>
    <Typography sx={{ fontWeight: '600', color: 'warning.main' }}>
      Pending Attendance
    </Typography>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'warning.dark' }}>
      {pendingCount}
    </Typography>
  </Box>
</Box>




        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          {attendanceData.length > 0 ? (
            attendanceData.map((attendance) => (
              <Grid item xs={12} sm={6} md={4} key={attendance._id}>
                <Card
                  sx={{
                    borderRadius: 4,
                    backgroundColor: '#ffffff',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 12px 30px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  <CardHeader
                    title={moment(attendance.classSchedule.classDate).format('MMMM D, YYYY')}
                    titleTypographyProps={{
                      variant: 'h6',
                      fontWeight: '600',
                      color: '#ffffff',
                    }}
                    action={
                      <Chip
                        label={attendance.status}
                        color={
                          attendance.status === 'completed'
                            ? 'success'
                            : attendance.status === 'pending'
                            ? 'warning'
                            : 'error'
                        }
                        size="small"
                        sx={{ fontWeight: 'bold', color: '#fff' }}
                      />
                    }
                    sx={{
                      background: 'linear-gradient(90deg, #1976d2, #42a5f5)',
                      padding: 2,
                      borderRadius: '12px 12px 0 0',
                    }}
                  />
                  <CardContent sx={{ padding: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <AccessTime sx={{ mr: 1, color: '#1976d2' }} />
                      <Typography variant="body2" sx={{ color: '#455a64' }}>
                        Attended:{' '}
                        <span style={{ fontWeight: '600', color: attendance.attendedAt ? '#d81b60' : '#ef5350' }}>
                          {attendance.attendedAt
                            ? moment(attendance.attendedAt).format('MMMM D, YYYY [at] h:mm A')
                            : 'Not yet'}
                        </span>
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {attendance.status === 'completed' ? (
                        <CheckCircle sx={{ mr: 1, color: '#2e7d32' }} />
                      ) : (
                        <ErrorOutline sx={{ mr: 1, color: '#f44336' }} />
                      )}
                      <Typography variant="body2" sx={{ color: '#455a64' }}>
                        Status:{' '}
                        <span
                          style={{
                            fontWeight: '600',
                            color:
                              attendance.status === 'completed'
                                ? '#2e7d32'
                                : attendance.status === 'pending'
                                ? '#ff9800'
                                : '#f44336',
                          }}
                        >
                          {attendance.status.charAt(0).toUpperCase() + attendance.status.slice(1)}
                        </span>
                      </Typography>
                    </Box>
                    {attendance.classSchedule && (
                      <Box sx={{ backgroundColor: '#f5f6fa', borderRadius: 2, p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Event sx={{ mr: 1, color: '#1976d2' }} />
                          <Typography variant="body2" sx={{ color: '#455a64' }}>
                            <strong>Class:</strong>{' '}
                            {moment(attendance.classSchedule.classDate).format('MMMM D, YYYY')}
                          </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ color: '#455a64', mt: 1 }}>
                          <strong>Day:</strong> {attendance.classSchedule.dayOfWeek}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#455a64', mt: 1 }}>
                          <strong>Time:</strong> {attendance.classSchedule.time}
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Box sx={{ textAlign: 'center', width: '100%', py: 6 }}>
              <Typography
                variant="h5"
                sx={{ color: '#546e7a', fontStyle: 'italic', fontWeight: '500' }}
              >
                No attendance records yet. Start your journey today!
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Fade>
  );
};

export default StudentHistory;