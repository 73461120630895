import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar } from '@mui/material';
import moment from 'moment-timezone'; // For timezone handling
import { Public } from '@mui/icons-material'; // Earth icon

const CountryTimeSelector = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [userTimezone, setUserTimezone] = useState('');

  // Function to get the user's timezone from localStorage
  const getUserTimezone = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.timezone || 'UTC'; // Default to UTC if not found
  };

  // Set timezone and current time on component mount
  useEffect(() => {
    const timezone = getUserTimezone();
    setUserTimezone(timezone);
    
    const updateTime = () => {
      // Check if the timezone is valid
      if (moment.tz.zone(timezone)) {
        const time = moment().tz(timezone).format('hh:mm:ss A');
        setCurrentTime(time);
      } else {
        setCurrentTime('Invalid timezone'); // Fallback for invalid timezone
        setShowWarning(true); // Show warning if the timezone is invalid
      }
    };

    // Initial time set
    updateTime();

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', justifyContent: 'space-between' }}>
        <Typography variant="h6">{currentTime}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Public sx={{ fontSize: 22 }} /> {/* Earth icon */}
            <Typography variant="subtitle1">{userTimezone}</Typography>
          </Box>
        </Box>
      </Box>
      
      {/* Snackbar for warning message */}
      <Snackbar
        open={showWarning}
        autoHideDuration={6000}
        onClose={() => setShowWarning(false)}
        message="Time Discrepancy Detected! Please change your timezone to correct the class timings."
      />
    </Box>
  );
};

export default CountryTimeSelector;
