import React from 'react';
import { Box, Typography, Radio, RadioGroup, FormControl, FormControlLabel, TextField, Button, useMediaQuery, useTheme } from '@mui/material';

const SalesProfileForm = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust the breakpoint as needed
  
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#F9E2B8"
      height="100vh"
    >
      <Box
        bgcolor="white"
        p={4}
        borderRadius={2}
        boxShadow={3}
        width="100%"
        maxWidth="500px"
        overflow="auto"
        sx={{
          height: '80vh',  // Adjust height to fit screen while enabling scrolling
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#e23b57',
            borderRadius: '10px',
          },
        }}
      >
        <Typography variant="h5" mb={2}>Tell Us About Yourself</Typography>

        <TextField label="Mobile Number" placeholder="Enter Mobile Number" fullWidth margin="normal" />
        <Typography variant="body2" color="textSecondary" mb={2}>
          Please share the number that you use for WhatsApp
        </Typography>

        <TextField label="Your First Name" placeholder="Enter your first name" fullWidth margin="normal" />
        <TextField label="Your Last Name" placeholder="Enter your last name" fullWidth margin="normal" />
        <TextField label="What's your email?" placeholder="Enter your email Id" fullWidth margin="normal" />
        <TextField label="City" placeholder="Enter your city name" fullWidth margin="normal" />
        <TextField label="State" placeholder="Enter your state name" fullWidth margin="normal" />
        <TextField label="Highest educational qualification" placeholder="Enter your qualification" fullWidth margin="normal" />

        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography variant="h6" mb={2}>Stream of education</Typography>
          <RadioGroup>
            {['Arts', 'Commerce', 'Management', 'Science', 'Engineering', 'Media', 'Other'].map((stream) => (
              <FormControlLabel value={stream} control={<Radio />} label={stream} key={stream} />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography variant="h6" mb={2}>Languages Known</Typography>
          <TextField variant="outlined" placeholder="Enter comma separated values" fullWidth />
        </FormControl>

        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography variant="h6" mb={2}>On a scale of 1 to 4, rate yourself on Spoken English Fluency</Typography>
          <RadioGroup>
            {['Very Weak', 'Weak', 'Strong', 'Very Strong'].map((level) => (
              <FormControlLabel value={level} control={<Radio />} label={level} key={level} />
            ))}
          </RadioGroup>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, backgroundColor: '#6559d6' }}
        >
          Complete Your Sales Profile
        </Button>
      </Box>
     {!isMobile&& <Box
          width="50%"
          display="flex"
          marginLeft={5}
          justifyContent="center"
          alignItems="center"
          bgcolor="#F9E2B8"
        >
          <img
            src="https://learn.brightchamps.com/images/form_image.svg"
            alt="BrightChamps Animation"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>}
    </Box>
  );
};

export default SalesProfileForm;
