import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Button, Avatar, Box ,IconButton} from "@mui/material";
import Xarrow from "react-xarrows";
import baseUrl from "../config";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const cardsPerRow = 4; // Define cards per row based on md={3}
const rowColors = ["#FFC107", "#90CAF9", "#AED581", "#FFAB91"]; // Four colors for each row

const CustomCard = ({ id, backgroundColor, title, description, imageUrl }) => {
  return (
<Card
  id={id} // Unique ID for Xarrow
  sx={{
    borderRadius: 0,
    overflow: "hidden",
    textAlign: "center",
    background: backgroundColor, // Use the backgroundColor prop here
    boxShadow: 3,
    maxWidth: 250,
    minWidth: 250, // Fixed width for the card
    minHeight: 350, // Fixed height for the card
    maxHeight: 350, // Ensure all cards have the same height
    position: "relative",
    borderRadius: 9,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    zIndex: 1,
    transition: 'transform 0.2s, boxShadow 0.2s', // Smooth transition for hover effect
    '&:hover': {
      transform: 'scale(1.15)', // Slightly scale up on hover
      boxShadow: 7, // Increase shadow intensity on hover
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Distribute space evenly
  }}
>
  {/* Top Section with Fixed Height */}
  <Box
    sx={{
      background: "#E0E0E0",
      padding: "16px", // Consistent padding
      position: "relative",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      minHeight: 120, // Fixed height for the top section
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {/* Title with Text Overflow Handling */}
    <Typography
      fontWeight="bold"
      sx={{
        wordWrap: "break-word", // Ensure long titles wrap to the next line
        whiteSpace: "normal", // Allow text to wrap
        overflow: "hidden", // Prevent overflow
        textOverflow: "ellipsis", // Add ellipsis for overflow
        display: "-webkit-box",
        WebkitLineClamp: 2, // Limit to 2 lines
        WebkitBoxOrient: "vertical",
        maxWidth: "100%", // Ensure title doesn't overflow the container
      }}
    >
      {title}
    </Typography>

    {/* Avatar with Fixed Position */}
    <Avatar
      src={title}
      alt="Module"
      sx={{
        width: 80,
        height: 80,
        position: "absolute",
        left: "50%",
        bottom: "-40px", // Position the avatar halfway outside the Box
        transform: "translateX(-50%)",
        border: "4px solid white",
        zIndex: 1,
      }}
    />
  </Box>

  {/* Card Content */}
  <CardContent
    sx={{
      paddingTop: 6,
      marginTop: 4,
      flex: 1, // Take up remaining space
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between", // Distribute space evenly
    }}
  >
    <Typography
      variant="body2"
      sx={{
        px: 2,
        wordWrap: "break-word", // Ensure long descriptions wrap
        whiteSpace: "normal", // Allow text to wrap
        overflow: "hidden", // Prevent overflow
        textOverflow: "ellipsis", // Add ellipsis for overflow
        display: "-webkit-box",
        WebkitLineClamp: 3, // Limit to 3 lines
        WebkitBoxOrient: "vertical",
      }}
    >
      {description}
    </Typography>
    <Button
      id={`${id}-button`} // Unique ID for the button
      variant="text"
      sx={{ fontWeight: "bold", mt: 1, color: "black" }}
    >
      Learn More
    </Button>
  </CardContent>
</Card>
  
  );
};

const PublicSpeakingDiagram = () => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const [courseData, setCourseData] = useState(null);
  const navigate = useNavigate(); // Initialize navigate
console.log(courseData,"courseData");

  // Fetch course data from the API
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await fetch(baseUrl+'api/students/new/my-course', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }); // Replace with your API endpoint
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourseData();
  }, []);

  // Force re-render arrows after layout stabilizes
  useEffect(() => {
    const handleResize = () => {
      setForceUpdate((prev) => prev + 1); // Force re-render on resize
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Re-render arrows after initial render
  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceUpdate((prev) => prev + 1); // Force re-render after a short delay
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  if (!courseData) {
    return <Typography>Loading...</Typography>; // Show loading state
  }

  return (
    <Box sx={{ position: "relative", p: 2 }}>
   <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333',
          
          fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(45deg, #111, #FF9800)", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text






           }}>
          {courseData.title}
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
          Future Learning Pathways
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>

    <Box mb={2} display="flex" flexDirection="column" alignItems="center">



  {/* Description */}
  <Typography
    variant="subtitle1"
    sx={{
      color: "text.secondary", // Use a secondary color for less emphasis
      fontSize: {
        xs: "1rem", // Smaller for mobile
        sm: "1.1rem", // Slightly larger for small screens
        md: "1.2rem", // Larger for medium screens
      },
      fontFamily: "'Poppins', sans-serif", // Use the same custom font
      lineHeight: 1.6, // Slightly looser line height for readability
      textAlign: "center", // Center align text
      maxWidth: {
        xs: "90%", // Narrower width for mobile
        md: "80%", // Wider width for desktop
      },
      mx: "auto", // Center the description horizontally
    }}
  >
    {courseData.description}
  </Typography>
</Box>
      <Grid container spacing={3} justifyContent="center">
        {courseData.chapters.map((chapter, index) => (
          <React.Fragment key={chapter._id}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2, width: "100%", textAlign: "center",   fontFamily: "'Poppins', sans-serif", // Use a custom font (ensure it's loaded)
          textTransform: "uppercase", // Uppercase text for a bold look
          letterSpacing: {
            xs: "0.05em", // Slightly tighter letter spacing for mobile
            md: "0.1em", // Wider letter spacing for desktop
          },
          background: "linear-gradient(-45deg, #111,rgb(123, 0, 255))", // Gradient background
          WebkitBackgroundClip: "text", // Clip background to text
          WebkitTextFillColor: "transparent", // Make text transparent to show gradient
          display: "inline-block", // Ensure gradient applies only to text
          textAlign: "center", // Center align text



 }}>
              Module {chapter.id}: {chapter.title}
            </Typography>
            {chapter.subtitle && (
              <Typography variant="subtitle1" sx={{ mb: 4, width: "100%", textAlign: "center" }}>
                {chapter.subtitle}
              </Typography>
            )}
            {chapter.topics.map((topic, topicIndex) => {
                 const colorIndex = topicIndex % rowColors.length;
                 const backgroundColor = rowColors[colorIndex];
       
            console.log("Background Color:", colorIndex);

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={topic._id}
                  display="flex"
                  justifyContent="center"
                  sx={{ marginBottom: 10 }}
                >
                  <CustomCard
                       id={`card-${index}-${topicIndex}`}
                       backgroundColor={backgroundColor}
                    title={` ${topic.title}`}
                    description={topic.description}
                    imageUrl="https://images.unsplash.com/photo-1603386781831-5f520a8a4963?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                </Grid>
              );
            })}
          </React.Fragment>
        ))}
      </Grid>

      {/* Arrows between cards WITHIN rows and connecting rows */}
      {courseData.chapters.map((chapter, index) => (
        <React.Fragment key={chapter._id}>
          {chapter.topics.map((topic, topicIndex) => {
            const isEndOfRow = (topicIndex + 1) % cardsPerRow === 0; // Check if it's the last card in a row

            if (topicIndex < chapter.topics.length - 1) {
              if (!isEndOfRow) {
                // Arrow within the same row (not the end of the row)
                return (
                  <Xarrow
                    key={`row-arrow-${index}-${topicIndex}-${forceUpdate}`} // Force re-render with forceUpdate
                    start={`card-${index}-${topicIndex}`}
                    end={`card-${index}-${topicIndex + 1}`}
                    color="black"
                    strokeWidth={5.5}
                    curveness={-0.3} // Slightly straighter for in-row arrows
                    zIndex={-2}
                  />
                );
              } else {
                // Arrow from the end of the row to the start of the NEXT row
                return (
                  <Xarrow
                    key={`row-jump-arrow-${index}-${topicIndex}-${forceUpdate}`} // Force re-render with forceUpdate
                    start={`card-${index}-${topicIndex}`}
                    end={`card-${index}-${topicIndex + 1}`} // Connect to the first card of the next row
                    color="black"
                    strokeWidth={5.5}
                    curveness={1.8} // Curve to visually separate row jumps
                    zIndex={-2}
                    startAnchor="bottom" // Start from the bottom of the last card in the row
                    endAnchor="top" // End at the top of the first card in the next row
                  />
                );
              }
            }
            return null; // No arrow for the very last card in the entire diagram
          })}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default PublicSpeakingDiagram;