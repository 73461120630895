import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  Typography,
  Button,
  Box,
  IconButton,
  Modal,
  Paper
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import { header, footer } from '../Assets';
import baseUrl from '../config';
import { Document, Page } from 'react-pdf';
import { GlobalWorkerOptions } from 'pdfjs-dist';

// Set the workerSrc globally for pdf.js
GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${require('pdfjs-dist/package.json').version}/build/pdf.worker.min.js`;

const ViewNotes = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState('');
  const [previewPdf, setPreviewPdf] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/students/getnotes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNotes(response.data);
      } catch (error) {
        setError('Failed to fetch notes. Please try again.');
        console.error('Error fetching notes:', error);
      }
    };
    fetchNotes();
  }, []);

  const downloadPDF = async (note) => {
    const doc = new jsPDF();
    
    // Set up font size for the date
    doc.setFontSize(12);
    const dateText = `Generated on: ${new Date().toLocaleDateString()}`;
  
    // Add the generated date before the header image (above the header)
    doc.text(dateText, 210 - doc.getStringUnitWidth(dateText) * doc.internal.getFontSize() - 10, 15); // Right-aligned at the top
    
    // Load the logo image as a Base64 string for header
    const logoBase64 = await getBase64Image(header);
    
    // Add the logo to the PDF (header) - full width
    doc.addImage(logoBase64, 'PNG', 0, 20, 210, 50); // Full-width header
    
    // Add the title
    doc.setFontSize(22);
    doc.text(note.title, 10, 80); // Title at Y=80
  
    // Draw a line after the title
    doc.line(10, 82, 200, 82);
  
    // Add some vertical space after the title line to prevent overlap with content
    const contentStartY = 90;
  
    // Add note content
    doc.setFontSize(12);
    doc.text(note.content, 10, contentStartY, { maxWidth: 190 });
  
    // Add footer image (full width) at the bottom of the PDF
    const footerBase64 = await getBase64Image(footer);
    doc.addImage(footerBase64, 'PNG', 0, doc.internal.pageSize.height - 40, 210, 20); // Full-width footer
  
    // Save the PDF
    doc.save(`${note.title}.pdf`);
  };
  
  

  const getBase64Image = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch image');
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error loading image:', error);
      throw error;
    }
  };

  const handlePreviewPdf = async (note) => {
    const doc = new jsPDF();
  
    // Add logo image for header
    const logoBase64 = await getBase64Image(header);
    doc.addImage(logoBase64, 'PNG', 0, 10, 210, 50); // Full-width header
  
    // Title
    doc.setFontSize(22);
    doc.text('Note: ' + note.title, 10, 70);
  
    // Line after the title
    doc.line(10, 82, 200, 82);
  
    // Add some space after the title line for better layout
    const contentStartY = 85;
  
    // Generated date (on the right side)
    doc.setFontSize(12);
    const dateText = `Generated on: ${new Date().toLocaleDateString()}`;
    doc.text(dateText, 210 - doc.getStringUnitWidth(dateText) * doc.internal.getFontSize() - 10, 80); // Position it on the right side
  
    // Add note content
    doc.setFontSize(12);
    doc.text(note.content, 10, contentStartY, { maxWidth: 190 });
  
    // Add footer image
    const footerBase64 = await getBase64Image(footer);
    doc.addImage(footerBase64, 'PNG', 0, doc.internal.pageSize.height - 40, 210, 20); // Full-width footer
  
    // Convert the PDF to Base64 for preview
    const pdfDataUrl = doc.output('datauristring');
    setPreviewPdf(pdfDataUrl);
    setOpenPreviewModal(true);
  };
  

  return (
    <Container style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px', width: '100%' }}>My Notes</Typography>
      {error && <Typography color="error">{error}</Typography>}
      {notes.map((note, index) => (
        <Card 
          key={index} 
          style={{ 
            width: '250px', 
            height: '250px', 
            padding: '20px', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '10px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Box>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
              {note.title}
            </Typography>
            <Typography variant="body2" style={{ color: '#555', marginTop: '10px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
              {note.content}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {/* <IconButton 
              color="primary" 
              onClick={() => handlePreviewPdf(note)} 
              aria-label="preview"
            >
              <DownloadIcon />
            </IconButton> */}
               <Typography>
              Download Pdf
            </Typography>
            <IconButton 
              color="primary" 
              onClick={() => downloadPDF(note)} 
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        </Card>
      ))}

      {/* PDF Preview Modal */}
      <Modal
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="preview-pdf-modal"
        aria-describedby="preview-pdf-modal-description"
      >
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Paper style={{ padding: '20px', maxWidth: '90%', maxHeight: '90%' }}>
            <Document file={previewPdf}>
              <Page pageNumber={1} />
            </Document>
          </Paper>
        </Box>
      </Modal>
    </Container>
  );
};

export default ViewNotes;
