import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { oratricslogo, portrait } from "../Assets/index";
import "../index.css"; // Ensure you have the CSS imported
import baseUrl from "../config";
import axios from "axios";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [grade, setGrade] = useState(""); // Changed from role to grade
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState(""); // Added referral code field

  const navigate = useNavigate();

 
const registerUser = async (event) => {
  event.preventDefault();
  
  try {
    const response = await axios.post(baseUrl + "api/students/register", {
      name,
      email,
      grade,
      password,
      referralCode, // Include referral code in the request body
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    });

    // Handle successful response
    const data = response.data;

    // Save the token and user data to local storage
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify({
      id: data._id,
      name: data.name,
      email: data.email,
      grade: data.grade,
      referralCode: data.referralCode,
      referredBy: data.referredBy,
      uniqueId: data.uniqueId,
    }));

    navigate("/"); // Redirect to the home page or login page
  } catch (error) {
    // Handle errors
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Registration failed:", error.response.data.message || "Unknown error");
      alert(error.response.data.message || "Registration failed. Please try again.");
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response received:", error.request);
      alert("No response received. Please try again.");
    } else {
      // Something else caused the error
      console.error("Error during registration:", error.message);
      alert("An error occurred. Please try again.");
    }
  }
};

  return (
    <div className="app-container">
      <div className="image-section">
        <img className="background-img" src={portrait} alt="Background" />
        <img className="logo" src={oratricslogo} alt="Oratrics Logo" />
      </div>
      <div className="login-section">
        <h1 className="welcome-text" style={{ color: '#050a30' }}>
          Welcome to <span style={{ color: '#8b0000' }}>Oratrics</span>
        </h1>
        <form onSubmit={registerUser} className="mx-auto mt-4 text-left">
          <div className="mb-8">
            <h2 className="text-3xl font-bold mb-2" style={{ color: '#050a30' }}>
              Register
            </h2>
            <p className="text-gray-500 text-left" style={{ color: '#050a30' }}>
              Already have an account?{" "}
              <span
                onClick={() => navigate("/")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign In
              </span>
            </p>
          </div>
          <div className="text-left">
            <label className="block mb-1">Full Name</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              type="text"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Grade</label>
            <input
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              placeholder="Grade"
              type="text"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Referral Code (optional)</label>
            <input
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Referral Code"
              type="text"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="text-left">
            <label className="block mb-1">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
              className="w-full px-4 py-2 border rounded-lg mb-4"
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-lime-500 text-white py-2 px-4 rounded-sm"
            >
              Register
            </button>
           
          </div>
          <span className="text-sm text-gray-500 cursor-pointer">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/")}
                className="text-lime-500 cursor-pointer"
                style={{ color: '#8b0000' }}
              >
                Sign In
              </span>
            </span>
        </form>
      </div>
    </div>
  );
};

export default Register;
